import React from 'react';
import { Layout } from '@vwfs-bronson/bronson-react';
import { Skeleton } from '../Skeleton';

export type LandingPageMarketingCardLoadingPlaceholderProps = {
    cardStyles?: Array<'normal' | 'reversed'>;
};

export const LandingPageMarketingCardLoadingPlaceholder: React.FC<LandingPageMarketingCardLoadingPlaceholderProps> = ({
    cardStyles = ['normal'],
}) => {
    const image = <Skeleton height={205} />;
    const content = (
        <div>
            <Skeleton height={30} />
            <Skeleton height={50} />
            <Skeleton height={20} count={3} />
            <Skeleton height={30} />
        </div>
    );
    return (
        <>
            {cardStyles.map((style, index) => (
                <Layout key={index} className="u-mb">
                    <Layout.Item default="1/2">{style === 'normal' ? image : content}</Layout.Item>
                    <Layout.Item default="1/2">{style === 'normal' ? content : image}</Layout.Item>
                </Layout>
            ))}
        </>
    );
};
