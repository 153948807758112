import React from 'react';
import classNames from 'classnames';
import { Icon } from '../Icon/Icon';
/**
 * Bronson NotificationsGroup component.
 * Groups all related {@link Notification}.
 * @constructor
 */
export function NotificationsGroup({ children, testId, ...otherProps }) {
    return (React.createElement("section", { id: "js-notifications-group", className: "c-notifications-group js-panelgroup js-is-ready js-is-fx is-stacked", "data-testid": testId, ...otherProps }, children));
}
/**
 * Bronson Notification component (nested).
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-notification.html
 *
 * @param {Button[]} [buttons=null] - A collection of {@link Button} to render after the text content.
 * @param {string|React.ReactNode} [children] - The Notification content.
 * @param {string} [className] - Additional CSS classes on the component element.
 * @param {string} [closeLabel='Close Notification'] - The label of the close button.
 *                                                     Only applicable when {@link showCloseButton} is set to `true`.
 * @param {boolean} [context] - Render the context variant.
 *                              Should only be used with {@link ContextNotification}.
 * @param {function} [onCloseClick] - Callback when the optional close button (x) is clicked.
 *                                    Only applicable when {@link showCloseButton} is set to `true`.
 * @param {boolean} [showCloseButton=true] - Show/hide the Notification close button (x).
 * @param {null|'info'|'success'|'warning'|'error'} [status] - Optional status that tints the Notification.
 * @param {string} [text] - Optional text content. Takes precedence over {@link children}.
 * @param {string} [title] - Optional title headline to display.
 * @param {boolean} [visible] - Hide/show the Notification.
 * @param {string} [testId] - The `[data-testid]` attribute on the component element.
 * @param {object} [otherProps] - Other props passed to the component element.
 * @returns {JSX.Element|null} - The Notification component.
 * @constructor
 */
export function Notification({ buttons = null, children, className, closeLabel = 'Close Notification', context, onCloseClick, showCloseButton = true, status, text, title, visible, testId, ...otherProps }) {
    const divClassName = classNames('c-notification', {
        'c-notification--context': context,
        [`c-notification--${status}`]: status,
    }, className).trim();
    function renderButtons() {
        if (buttons) {
            return React.createElement("div", { className: "c-notification__buttons" }, buttons);
        }
        return null;
    }
    function renderCloseButton() {
        if (showCloseButton) {
            return (React.createElement("button", { className: "c-notification__close js-notification-close", title: closeLabel, type: "button", onClick: onCloseClick },
                React.createElement("span", { className: "u-visually-hidden" }, closeLabel)));
        }
        return null;
    }
    function renderIfStatus() {
        return status ? React.createElement(Icon, { className: "c-notification__status-icon", viaCss: true }) : null;
    }
    function renderIfTitle() {
        return title ? React.createElement("h3", { className: "c-notification__title" }, title) : null;
    }
    function renderText() {
        /**
         * If {@link text} is set, render it as content for `.c-notification__text`
         * and render {@link children} as sibling elements of `.c-notification__text`.
         * Else render out {@link children} as content for `.c-notification__text` only.
         */
        return (React.createElement(React.Fragment, null,
            React.createElement("p", { className: "c-notification__text" }, text || children),
            text ? children : ''));
    }
    return visible ? (React.createElement("div", { className: "c-notification__container js-notification-container js-is-ready js-is-fx", "data-testid": testId },
        React.createElement("div", { className: divClassName, ...otherProps },
            React.createElement("div", { className: "c-notification__outer-wrap" },
                React.createElement("div", { className: "c-notification__inner-wrap" },
                    renderIfStatus(),
                    React.createElement("div", { className: "c-notification__content" },
                        renderIfTitle(),
                        renderText(),
                        renderButtons()),
                    renderCloseButton()))))) : null;
}
