import * as React from 'react';

import { InfoIcon } from '../info-icon';

export type ContractSummaryItemProps = {
    /**
     * The value to be shown next to the label (e.g. "25/10/1992")
     */
    value: string;
    /**
     * The label describing the item's purpose (e.g. "Next instalment date")
     */
    label: string;
    /**
     * The test class of the specific summary item (e.g. "cp-next-instalment-date")
     */
    testClass: string;
    /**
     * An optional tooltip to be shown next to the value
     */
    tooltip?: string | React.ReactNode;
    /**
     * An optional test ID to be associated with the summary item
     */
    testId?: string;
    /**
     * Function that will trigger on infoIcon hover
     */
    onTooltip?: () => void;
};

/**
 * A single entry in the contract summary to show a specific labeled value
 */
export const ContractSummaryItem: React.FC<ContractSummaryItemProps> = ({
    value,
    label,
    testClass,
    tooltip,
    testId,
    onTooltip,
}) => (
    <dl className={testClass}>
        <dt data-testid={testId}>
            {label}
            {!!tooltip && (
                <>
                    &nbsp;
                    <InfoIcon onMouseOver={onTooltip} text={tooltip} />
                </>
            )}
        </dt>
        <dd data-testid={testId ? `${testId}-dd` : undefined}>{value}</dd>
    </dl>
);
