import React from 'react';
import { Card } from '@vwfs-bronson/bronson-react';
import { Skeleton } from '../Skeleton';

export const MarketingCardLoadingPlaceholder: React.FC = () => (
    <Card element="article">
        <Skeleton height={250} />
        <Skeleton height={30} className="u-mb u-mt" />
        <Skeleton count={3} />
        <Skeleton height={25} className="u-mb u-mt" />
    </Card>
);
