import React from 'react';
import { DescriptionList } from '@vwfs-bronson/bronson-react';
import { DefinitionListItem } from '../types';
import { DefinitionListItemTitle } from '../DefinitionListItemTitle';

export type DefinitionListType = {
    list: DefinitionListItem[];
    className?: string;
    testId?: string;
    split?: boolean;
    shortTerms?: boolean;
};

export const DefinitionList: React.FC<DefinitionListType> = ({ list, className, testId, split, shortTerms }) => {
    if (!list || list.length === 0) {
        return null;
    }

    return (
        <DescriptionList className={className} testId={testId} shortTerms={shortTerms} split={split} horizontal>
            {list.map((item: DefinitionListItem, index) => {
                const title = <DefinitionListItemTitle title={item.label} tooltip={item.tooltip} />;
                return (
                    <DescriptionList.Group testId={item.testId} key={index} termText={title}>
                        <DescriptionList.Detail>
                            {/*Workaround is necessary as Bronson does not have a proper spacing when the value dd has no content*/}
                            {item.value ? (
                                <span className={item.uppercaseValue ? 'u-text-uppercase' : ''}>{item.value}</span>
                            ) : (
                                <span
                                    dangerouslySetInnerHTML={{ __html: '&nbsp;' }}
                                    data-testid={item.testId + 'Value'}
                                />
                            )}
                        </DescriptionList.Detail>
                    </DescriptionList.Group>
                );
            })}
        </DescriptionList>
    );
};
