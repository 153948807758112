import React, { Children } from 'react';
import Flickity, { FlickityOptions } from 'react-flickity-component';
import './Carousel.css';

export interface CarouselOptions extends FlickityOptions {
    dotIndicatorOnTop?: boolean;
    withoutMarketingCards?: boolean;
}

export const Carousel: React.FC<{ options?: CarouselOptions; className?: string }> = ({
    children,
    options,
    className,
}) => {
    options = {
        autoPlay: true,
        wrapAround: true,
        adaptiveHeight: true,
        prevNextButtons: false,
        ...options,
        cellSelector: '.c-carousel__item',
    };

    let cnt = 0;
    Children.forEach(children, child => child && cnt++);

    let margin = 14;
    switch (process.env.REACT_APP_WEBSITE_BRONSON_THEME) {
        case 'audi':
            margin = 12;
            break;

        case 'vw6':
            margin = 20;
            break;

        case 'seat':
        case 'skoda':
            margin = 16;
            break;
    }
    margin = options.withoutMarketingCards ? 0 : margin;
    const cssClass = options.dotIndicatorOnTop ? 'dot-indicators-on-top' : '';

    return (
        <div className={className}>
            {cnt > 1 ? (
                <div style={{ margin: `-${margin}px` }} className={cssClass}>
                    <Flickity elementType="div" className="c-carousel js-carousel" options={options}>
                        {Children.map(children, child => {
                            return child && <div className="c-carousel__item u-p-small">{child}</div>;
                        })}
                    </Flickity>
                </div>
            ) : (
                children
            )}
        </div>
    );
};

Carousel.displayName = 'Carousel';
