import { Table } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { Cell, DynamicTableSortOptions } from '../DynamicTableOptions';

function orderRows<TDataRow>(rows: TDataRow[], sortOptions?: DynamicTableSortOptions<TDataRow>): TDataRow[] {
    if (sortOptions) {
        const { compare, reversed = false } = sortOptions;
        const copyRows = [...rows];
        return reversed ? copyRows.sort(compare) : copyRows.sort(compare).reverse();
    }
    return rows;
}

export type OrderedTableRowsProps<TDataRow> = {
    rows: TDataRow[];
    toColumnValues: (row: TDataRow) => Cell[];
    sortOptions?: DynamicTableSortOptions<TDataRow>;
};

export function createOrderedTableRows<TDataRow extends object>(): React.FC<OrderedTableRowsProps<TDataRow>> {
    const OrderedTableRows: React.FC<OrderedTableRowsProps<TDataRow>> = ({ rows, sortOptions, toColumnValues }) => {
        if (rows.length <= 0) {
            return null;
        }
        const orderedRows: TDataRow[] = orderRows(rows, sortOptions);

        return (
            <>
                {orderedRows.map((row, rowIndex) => (
                    <Table.Tr key={rowIndex}>
                        {toColumnValues(row).map((cellData, cellIndex) => {
                            if (typeof cellData === 'string') {
                                return <Table.Td key={cellIndex}>{cellData}</Table.Td>;
                            }
                            return (
                                <Table.Td
                                    key={cellIndex}
                                    className={[
                                        !!cellData.nowrap ? 'u-ws-nowrap' : '',
                                        !!cellData.hiddenForMobile ? 'u-hide@xs' : '',
                                        !!cellData.textAlign ? `u-text-${cellData.textAlign}` : '',
                                    ]
                                        .filter(Boolean)
                                        .join(' ')}
                                >
                                    {cellData?.value}
                                </Table.Td>
                            );
                        })}
                    </Table.Tr>
                ))}
            </>
        );
    };
    OrderedTableRows.displayName = 'OrderedTableRows';
    return OrderedTableRows;
}
