import React from 'react';
import { DefinitionListHorizontal } from '../definition-list-horizontal';
import { DefinitionListItem } from '../types';

export type DefinitionListSection = {
    title?: string;
    summaryInformation?: string;
    items: DefinitionListItem[];
    className?: string;
    testId?: string;
};

export type DefinitionListCollectionProps = {
    collectionItems: DefinitionListSection[];
    shortTerms?: boolean;
};

export const DefinitionListCollection: React.FC<DefinitionListCollectionProps> = ({ collectionItems, shortTerms }) => {
    if (collectionItems.length === 0) {
        return null;
    }

    const lastItemIndex = collectionItems.length - 1;

    return (
        <>
            {collectionItems.map((section: DefinitionListSection, index) => (
                <div key={index}>
                    {section.title && <h6>{section.title}</h6>}
                    {section.summaryInformation && <p> {section.summaryInformation} </p>}
                    <DefinitionListHorizontal
                        list={section.items}
                        className={section.className}
                        testId={section.testId}
                        shortTerms={shortTerms}
                    />
                    {lastItemIndex !== index && <hr className="u-mv" />}
                </div>
            ))}
        </>
    );
};
