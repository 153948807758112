import _ from 'lodash';
import { DataLayer } from './DataLayer';

export type Defaults = {
    currentLanguage?: string;
    privacyStatement?: boolean;
    userGroup?: string;
    loginStatus?: boolean;
    currentBrand?: string;
    productId?: string;
};

export type ErrorTracking = {
    errorCode?: string;
    errorMessage?: string;
    errorCausingUrl?: string;
};

export type ContractData = {
    category?: string;
};

export type MarketingPreference = {
    email?: boolean;
    phone?: boolean;
    sms?: boolean;
    post?: boolean;
};

export type MarketingPreferences = {
    vwfs: MarketingPreference;
    businessPartners: MarketingPreference;
};

enum DataPrivacyStatements {
    EMAIL = 'email',
    PHONE = 'phone',
    SMS = 'sms',
    POST = 'post',
}

export enum TrackingPageName {
    HOOKPAGE = 'Hookpage',
    PROFILE = 'Profile',
}

export type LoginOptions = 'Verimi' | 'Volkswagen' | 'Audi' | 'Seat' | 'Cupra' | 'Skoda' | 'VWFS';

const getDataPrivacyStatementValue = (
    statementType: DataPrivacyStatements,
    marketingPreferences?: MarketingPreferences,
): string => {
    const vwfsStatement = marketingPreferences?.vwfs[statementType];
    const bpStatement = marketingPreferences?.businessPartners[statementType];

    if (vwfsStatement && bpStatement) return 'true';
    else if (vwfsStatement && !bpStatement) return 'true (VWFS)';
    else if (!vwfsStatement && bpStatement) return 'true (BP)';
    else return 'false';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dropNullValues = (_key: string, value: any): any => {
    if (_.isEmpty(value)) {
        return undefined;
    }
    return value;
};

type TrackItem = {
    identifier: string;
    updateDataLayer: () => void;
    payload: unknown;
};

//HINT: structured the "API" of the class Analytics in different categories, e.g. general, dashboard
// when this class/file becomes to big its valid to split it up e.g. using own classes and composition
export class Analytics {
    private _firstVisit = true;
    private dataLayer: DataLayer;
    private intervalDelay = 1000;
    private trackingItemsList: TrackItem[] = [];
    private interval: ReturnType<typeof setTimeout>;
    public clearTimeOut = () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.interval && clearInterval(this.interval);
    };

    constructor(market: string, version: string, releaseDate: string) {
        this.dataLayer = new DataLayer(market, version, releaseDate);
        this.watch();
    }

    private watch() {
        this.clearTimeOut();
        this.interval = setInterval(() => {
            const item = this.trackingItemsList[0];
            if (item) {
                this.sendTrackedData(item.identifier, item.updateDataLayer, item.payload);
                this.trackingItemsList.shift();
            }
        }, this.intervalDelay);
    }

    track(identifier: string, updateDataLayer: () => void, payload?: unknown, noDelay?: boolean): void {
        if (noDelay) this.sendTrackedData(identifier, updateDataLayer, payload);
        else this.trackingItemsList.push({ identifier, updateDataLayer, payload });
    }

    private sendTrackedData(identifier: string, updateDataLayer: () => void, payload?: unknown): void {
        updateDataLayer();
        if (process.env.NODE_ENV === 'development') {
            //TODO: introduce logger e.g. winston
            const payloadStr = payload ? ' = ' + JSON.stringify(payload, dropNullValues, 2) : '';
            // eslint-disable-next-line no-console, @typescript-eslint/no-explicit-any
            console.log(`[Analytics.dataLayer] ${JSON.stringify((window as any).du_digitalData, null, 2)}`);
            // eslint-disable-next-line no-console
            console.log(`[Analytics.track] ${identifier} ${payloadStr}`);
        } else {
            //TODO: add _satellite to window type
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any)._satellite?.track(identifier, payload);
        }
    }

    private _setPageViewEvent(dl: DataLayer): DataLayer {
        dl.set('eventType', 'pageView').set('eventAction', 'Success');
        return dl;
    }

    private _setPageInteractionEvent(dl: DataLayer): DataLayer {
        dl.set('eventType', 'interAction').set('eventAction', 'Success');
        return dl;
    }

    // TODO: Investigate deep cloning of data-layer and rework _setDefaults
    private _setDefaults(dl: DataLayer, defaults: Defaults): DataLayer {
        dl.set('currentLanguage', defaults.currentLanguage)
            .set('userGroup', defaults.userGroup || null)
            .set('loginStatus', !!defaults.loginStatus)
            .set('currentBrand', defaults.currentBrand)
            .set('productId', defaults.productId);
        return dl;
    }

    // Cookie warning currently not in use
    private _onCookiesActions(dl: DataLayer, pageName: string): DataLayer {
        dl.set('pageName', pageName)
            .set('eventType', 'interAction')
            .set('eventAction', 'Success')
            .set('viewChange', 'Cookie layer');
        return dl;
    }

    //IRAP calculation page
    public irapPage = {
        onIrapPage: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl)
                    .set('pageName', 'IRAP calculation')
                    .set('viewChange', 'IRAP calculation')
                    .set('eventType', 'pageView')
                    .set('eventAction', 'Success')
                    .write();
            };
            this.track('page', updateDataLayer);
        },
        onIrapDownload: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setDashboardDefaults(dl)
                    .set('eventType', 'Download')
                    .set('eventAction', 'Request')
                    .set('linkInformation', 'Download IRAP calculation')
                    .write();
            };
            this.track('download', updateDataLayer);
        },
    };

    public cookieWarning = {
        // Not specified in the new documentation
        onShowCookiesLayer: (pageName: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl)
                    .set('pageName', pageName)
                    .set('viewChange', 'Cookie layer')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Not specified in the new documentation
        onAcceptCookies: (pageName: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._onCookiesActions(dl, pageName)
                    .set('linkInformation', 'Cookies accepted')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Not specified in the new documentation
        onShowCookiePolicy: (pageName: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._onCookiesActions(dl, pageName)
                    .set('linkInformation', 'Cookie policy')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },
    };

    // Login

    public login = {
        // Login.goto
        onShowLogin: (defaults: Defaults, pageName?: string, noDelay = true): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                dl.set('pageName', pageName || 'Home')
                    .set('viewChange', 'Login start')
                    .set('eventType', 'Login')
                    .set('eventAction', 'Start')
                    .set('linkInformation', 'Login start')
                    .write();
            };
            this.track('interaction', updateDataLayer, undefined, noDelay);
        },

        // Login.vwid & Login.external
        onExternalLogin: (defaults: Defaults, selectedLogin: LoginOptions): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                const externalValue = `Login start via external provider (${selectedLogin})`;
                const brandValue = `Login start via ${selectedLogin} ID`;
                switch (selectedLogin) {
                    case 'Verimi':
                        dl.set('linkInformation', externalValue).set('viewChange', externalValue);
                        break;
                    case 'Volkswagen':
                    case 'Audi':
                    case 'Seat':
                    case 'Cupra':
                    case 'Skoda':
                    case 'VWFS':
                        dl.set('linkInformation', brandValue).set('viewChange', brandValue);
                        break;
                    default:
                        break;
                }
                dl.set('pageName', 'Home')
                    .set('eventType', 'Login')
                    .set('eventAction', 'Start')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },
    };

    //Contact details hook page
    private _setContactDetailsHookPageDefaults(dl: DataLayer): DataLayer {
        dl.set('pageName', 'Contact data confirmation request');
        dl.set('viewChange', 'Contact data confirmation request');

        return dl;
    }

    public contactDetailsHookPage = {
        // (Contact details hook page).view
        onContactDetailsHookPage: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setContactDetailsHookPageDefaults(dl);
                this._setPageViewEvent(dl).write();
            };
            this.track('page', updateDataLayer);
        },

        // (Contact details hook page).changes
        onSubmitChanges: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setContactDetailsHookPageDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Contact details updated')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // (Contact details hook page).cancel w/o changes
        onNoChangesCancel: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setContactDetailsHookPageDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Contact details unchanged')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // (Contact details hook page).cancel with change
        onChangesCancel: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setContactDetailsHookPageDefaults(dl)
                    .set('eventAction', 'Success')
                    .set('linkInformation', 'Contact data change canceled')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // (Contact details hook page).cancel confirmed
        onCancel: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setContactDetailsHookPageDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('viewChange', 'Confirmation of discarding contact data change')
                    .set('linkInformation', 'Contact data change cancellation confirmed')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // (Contact details hook page).cancel aborted
        onCancelAborted: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setContactDetailsHookPageDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('viewChange', 'Confirmation of discarding contact data change')
                    .set('linkInformation', 'Contact data change confirmed')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },
    };

    // KYC hook page
    private _setKycHookPageDefaults(dl: DataLayer): DataLayer {
        dl.set('pageName', 'Identity confirmation');
        dl.set('viewChange', 'Identity confirmation');

        return dl;
    }

    public kycHookPage = {
        // (KYC hook page).view
        onKycHookPage: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setKycHookPageDefaults(dl);
                this._setPageViewEvent(dl).write();
            };
            this.track('page', updateDataLayer);
        },

        // (KYC hook page).noContract
        onNoContract: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl)
                    .set('pageName', 'Identity confirmation')
                    .set('viewChange', 'Contract number unknown')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // (KYC hook page).serviceNumber
        onServicePhoneNumber: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl)
                    .set('pageName', 'Identity confirmation')
                    .set('viewChange', 'Identity confirmation')
                    .set('linkInformation', 'Click on service number')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },
    };

    // Main navigation

    public mainNavigation = {
        // Main navigation.logout
        onLogout: (defaults: Defaults, pageName?: string, noDelay = true): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl)
                    .set('pageName', pageName || 'Home')
                    .set('viewChange', 'Logout')
                    .set('linkInformation', 'Logout')
                    .write();
            };
            this.track('interaction', updateDataLayer, undefined, noDelay);
        },

        // Main navigation.dealerRequest
        onDealerRequestClicked: (defaults: Defaults, currentPage?: string): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Dealer request')
                    .set('pageName', currentPage)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },
    };

    // Digital Renewal (Hook Page)
    private _setDigitalRenewalDefaults(dl: DataLayer): DataLayer {
        dl.set('pageName', 'Digital renewal invitation');

        return dl;
    }

    public digitalRenewal = {
        // Digital Renewal.view
        onDigitalRenewalPage: (from: 'login' | 'registration' | '', defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl).set('viewChange', 'Digital renewal invitation');
                this._setDigitalRenewalDefaults(dl);
                switch (from) {
                    case 'login':
                        dl.set('additionalEvent', 'Login', 'Success');
                        break;
                    case 'registration':
                        dl.set('additionalEvent', 'Registration', 'Success');
                        break;
                    default:
                        break;
                }
                dl.write();
            };

            this.track('page', updateDataLayer);
        },

        // Digital Renewal.start
        onDigitalRenewalStart: (from: 'Dashboard' | 'Digital renewal invitation', defaults: Defaults): void => {
            const updateDataLayer = (): void => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl)
                    .set('pageName', from)
                    .set('viewChange', 'Digital renewal invitation')
                    .set('eventType', 'interAction')
                    .set('eventAction', 'Success')
                    .set('linkInformation', 'Start digital renewal')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },
    };

    // Marketing Agreement

    private _setMarketingAgreementDefaults(dl: DataLayer): DataLayer {
        dl.set('pageName', 'Marketing agreement notification');
        return dl;
    }

    public marketingAgreement = {
        // Marketing Agreement.view
        onMarketingAgreement: (
            from: 'login' | 'registration' | '',
            marketingPreference: MarketingPreference,
            defaults: Defaults,
        ): void => {
            const updateDataLayer = (): void => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl).set('viewChange', 'Marketing agreement notification');
                this._setMarketingAgreementDefaults(dl);
                switch (from) {
                    case 'login':
                        dl.set('additionalEvent', 'Login', 'Success');
                        break;
                    case 'registration':
                        dl.set('additionalEvent', 'Registration', 'Success');
                        break;
                    default:
                        break;
                }
                if (marketingPreference.post !== undefined)
                    dl.set('privacyStatementPostalAds', marketingPreference.post);
                if (marketingPreference.phone !== undefined)
                    dl.set('privacyStatementPhoneAds', marketingPreference.phone);
                if (marketingPreference.sms !== undefined)
                    dl.set('privacyStatementElectronicAds', marketingPreference.sms);
                if (marketingPreference.email !== undefined)
                    dl.set('privacyStatementMailAds', marketingPreference.email);
                dl.write();
            };
            this.track('page', updateDataLayer);
        },

        // Marketing Agreement.saveyes
        onMarketingAgreementSaveYes: (defaults: Defaults, marketingPreference: MarketingPreference): void => {
            const updateDataLayer = (): void => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setMarketingAgreementDefaults(dl);

                this._setPageInteractionEvent(dl)
                    .set('eventType', 'interAction')
                    .set('eventAction', 'Success')
                    .set('linkInformation', 'Marketing consent form saved');

                if (marketingPreference.post !== undefined)
                    dl.set('privacyStatementPostalAds', marketingPreference.post);
                if (marketingPreference.phone !== undefined)
                    dl.set('privacyStatementPhoneAds', marketingPreference.phone);
                if (marketingPreference.sms !== undefined)
                    dl.set('privacyStatementElectronicAds', marketingPreference.sms);
                if (marketingPreference.email !== undefined)
                    dl.set('privacyStatementMailAds', marketingPreference.email);

                dl.write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Marketing Agreement.saveno
        onMarketingAgreementSaveNo: (defaults: Defaults): void => {
            const updateDataLayer = (): void => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setMarketingAgreementDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('eventType', 'interAction')
                    .set('eventAction', 'Success')
                    .set('linkInformation', 'Marketing preferences not saved')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Marketing Agreement.no-email
        onMarketingAgreementNoEmail: (defaults: Defaults): void => {
            const updateDataLayer = (): void => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setMarketingAgreementDefaults(dl);
                this._setPageViewEvent(dl)
                    .set('viewChange', 'No email address available')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // Marketing Agreement.changes
        onMarketingAgreementSubmitChanges: (defaults: Defaults): void => {
            const updateDataLayer = (): void => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setMarketingAgreementDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Email address for optin entered')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Marketing Agreement.cancel w/o changes
        onMarketingAgreementCancelWithoutChanges: (defaults: Defaults): void => {
            const updateDataLayer = (): void => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setMarketingAgreementDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Email address for optin not entered')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Marketing Agreement.changed
        onMarketingAgreementChanged: (
            defaults: Defaults,
            marketingPreference: MarketingPreference,
            initialValues: MarketingPreference,
            marketingType: string,
            forthAdditionalValue: TrackingPageName,
        ): void => {
            if (!_.isEqual(marketingPreference, initialValues)) {
                const type = marketingType === '(VWFS)' ? '(VWFS)' : '(BP)';

                const updateDataLayer = (): void => {
                    const dl = this._setDefaults(this.dataLayer.clone(), defaults);

                    if (forthAdditionalValue === TrackingPageName.HOOKPAGE) this._setMarketingAgreementDefaults(dl);
                    else this._setProfileDefaults(dl);

                    this._setPageInteractionEvent(dl)
                        .set('eventType', 'interAction')
                        .set('eventAction', 'Success')
                        .set('linkInformation', 'Marketing consent form changed');

                    if (marketingPreference.post !== initialValues.post)
                        dl.set(
                            'privacyStatementPostalAds',
                            marketingPreference.post ? `+Postal ${type}` : `-Postal ${type}`,
                        );
                    else dl.set('privacyStatementPostalAds', '');

                    if (marketingPreference.phone !== initialValues.phone)
                        dl.set(
                            'privacyStatementPhoneAds',
                            marketingPreference.phone ? `+Phone ${type}` : `-Phone ${type}`,
                        );
                    else dl.set('privacyStatementPhoneAds', '');

                    if (marketingPreference.sms !== initialValues.sms)
                        dl.set(
                            'privacyStatementElectronicAds',
                            marketingPreference.sms ? `+SMS ${type}` : `-SMS ${type}`,
                        );
                    else dl.set('privacyStatementElectronicAds', '');

                    if (marketingPreference.email !== initialValues.email)
                        dl.set(
                            'privacyStatementMailAds',
                            marketingPreference.email ? `+EMAIL ${type}` : `-EMAIL ${type}`,
                        );
                    else dl.set('privacyStatementMailAds', '');

                    dl.write();
                };
                this.track('interaction', updateDataLayer);
            }
        },
    };

    // Contact teaser
    private _onIconFooterActions(dl: DataLayer, linkInformation: string, pageName?: string): DataLayer {
        dl.set('pageName', pageName || 'Home')
            .set('eventType', 'interAction')
            .set('eventAction', 'Success')
            .set('linkInformation', linkInformation);
        return dl;
    }

    public contactTeaser = {
        // Contact teaser.view (email)
        onClickEmailLink: (defaults: Defaults, linkTitleEN: string, pageName?: string): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._onIconFooterActions(dl, `Email ${linkTitleEN}`, pageName).write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Contact teaser.view (phone)
        onClickCallUsLink: (defaults: Defaults, linkTitleEN: string, pageName?: string): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._onIconFooterActions(dl, `Phone ${linkTitleEN}`, pageName).write();
            };
            this.track('interaction', updateDataLayer);
        },
    };

    // LandingPage

    public landingPage = {
        // LandingPage.view
        onLandingPage: (defaults: Defaults, loggedOut = false): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl)
                    .set('pageName', 'home')
                    .set('viewChange', 'home');
                // loggedOut variable is needed because if user logged out he is not starting the journey but _firstVist
                // variable was reset while redirecting to other application for login
                if (this._firstVisit && !loggedOut) {
                    this.dataLayer.set('additionalEvent', 'Journey', 'Start');
                    this._firstVisit = false;
                }
                this.dataLayer.write();
            };
            this.track('page', updateDataLayer);
        },

        onLandingPageOffer: (defaults: Defaults, loggedOut = false): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl)
                    .set('pageName', 'Home Offer')
                    .set('viewChange', 'Home Offer');
                // loggedOut variable is needed because if user logged out he is not starting the journey but _firstVist
                // variable was reset while redirecting to other application for login
                if (this._firstVisit && !loggedOut) {
                    this.dataLayer.set('additionalEvent', 'Journey', 'Start');
                    this._firstVisit = false;
                }
                this.dataLayer.write();
            };
            this.track('page', updateDataLayer);
        },

        // LandingPage.learnMore
        onClickMarketingCard: (marketingTitleEN: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl)
                    .set('pageName', 'home')
                    .set('linkInformation', `Internal campaign: ${marketingTitleEN}`)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },
    };

    // Authentication

    private _setAuthFormDefaults(dl: DataLayer): DataLayer {
        dl.set('pageName', 'Authentication')
            .set('formType', 'Authentication')
            .set('formName', 'Personal data');
        return dl;
    }

    private _setAuthConfirmSuccessDefaults(dl: DataLayer): DataLayer {
        dl.set('viewChange', 'Authentication')
            .set('linkInformation', 'Authentication request')
            .set('eventType', 'Authentication')
            .set('eventAction', 'Request');
        return dl;
    }

    public authentication = {
        // Authentication.view
        onConfirmIdentityPage: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setAuthFormDefaults(dl)
                    .set('viewChange', 'Authentication')
                    .set('eventType', 'Authentication')
                    .set('eventAction', 'Start')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // Authentication.startTyping
        onStartTypingConfirmIdentityPage: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setAuthFormDefaults(dl)
                    .set('linkInformation', 'Authentication start typing')
                    .set('viewChange', 'Authentication')
                    .set('eventType', 'Form')
                    .set('eventAction', 'Start typing')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Authentication.confirm.error
        onFormValidationErrorConfirmIdentityPage: (fieldErrors: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setAuthFormDefaults(dl)
                    .set('linkInformation', 'Authentication input error')
                    .set('formErrorFields', fieldErrors)
                    .set('viewChange', 'Input error')
                    .set('eventType', 'Form')
                    .set('eventAction', 'Input error')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Authentication.confirm.success (when next authentication step exists e.g. SMS code verification)
        onConfirmConfirmIdentityPage: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setAuthFormDefaults(dl);
                this._setAuthConfirmSuccessDefaults(dl).write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Authentication.confirm.success (when this is the last step of authentication process)
        onConfirmIdentityPageConfirmSuccess: (defaults: Defaults, noDelay = false): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setAuthFormDefaults(dl);
                this._setAuthConfirmSuccessDefaults(dl)
                    .set('additionalEvent', 'Authentication', 'Success')
                    .write();
            };
            this.track('interaction', updateDataLayer, undefined, noDelay);
        },

        // Authentication.confirm.technicalError
        onConfirmIdentityPageTechnicalError: (defaults: Defaults, error: ErrorTracking): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setAuthFormDefaults(dl)
                    .set('viewChange', 'Authentication error')
                    .set('linkInformation', 'Authentication request (technical error)')
                    .set('eventType', 'Authentication')
                    .set('eventAction', 'Request')
                    .set('errorMessage', error.errorMessage)
                    .set('errorCode', error.errorCode)
                    .set('errorCausingUrl', error.errorCausingUrl);
            };
            this.track('interaction', updateDataLayer);
        },
    };

    // Authentication (verification)

    private _setAuthVerificationDefaults(dl: DataLayer): DataLayer {
        dl.set('pageName', 'Authentication')
            .set('formType', 'Authentication')
            .set('formName', 'Verification');
        return dl;
    }

    public authenticationVerification = {
        // Authentication (verification).view
        onVerificationPage: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setAuthVerificationDefaults(dl)
                    .set('viewChange', 'Verification')
                    .set('eventType', 'pageView')
                    .set('eventAction', 'Success')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // Authentication (verification).startTyping
        onStartTypingVerificationPage: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setAuthVerificationDefaults(dl)
                    .set('linkInformation', 'Authentication verification start typing')
                    .set('eventType', 'Form')
                    .set('eventAction', 'Start typing')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Authentication (verification).confirm.error
        onFormValidationErrorVerificationPage: (fieldErrors: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setAuthVerificationDefaults(dl)
                    .set('viewChange', 'Input error')
                    .set('linkInformation', 'Authentication input error')
                    .set('formErrorFields', fieldErrors)
                    .set('eventType', 'Form')
                    .set('eventAction', 'Input error')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Authentication (verification).confirm.success (when next authentication step exists e.g. SMS code verification)
        onVerificationPageConfirmButton: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setAuthVerificationDefaults(dl)
                    .set('viewChange', 'Authentication')
                    .set('eventType', 'Authentication')
                    .set('eventAction', 'Request')
                    .set('linkInformation', 'Authentication request')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Authentication (verification).confirm.success (when this is the last step of authentication process)
        onVerificationPageConfirmSuccess: (defaults: Defaults, noDelay = false): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setAuthVerificationDefaults(dl)
                    .set('viewChange', 'Authentication')
                    .set('eventType', 'Authentication')
                    .set('eventAction', 'Request')
                    .set('additionalEvent', 'Authentication', 'Success', 'Authentication success')
                    .write();
            };
            this.track('interaction', updateDataLayer, undefined, noDelay);
        },

        // Authentication (verification).confirm.technicalError
        onConfirmVerificationPageTechnicalError: (defaults: Defaults, error: ErrorTracking): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setAuthVerificationDefaults(dl)
                    .set('viewChange', 'Authentication error')
                    .set('linkInformation', 'Authentication request (technical error)')
                    .set('eventType', 'Authentication')
                    .set('eventAction', 'Request')
                    .set('errorMessage', error.errorMessage)
                    .set('errorCode', error.errorCode)
                    .set('errorCausingUrl', error.errorCausingUrl);
            };
            this.track('interaction', updateDataLayer);
        },
    };

    // Authentication (TAN)

    private _onEnterSmsCodeDefaults(dl: DataLayer): DataLayer {
        dl.set('pageName', 'Authentication')
            .set('viewChange', 'One time code')
            .set('eventType', 'interAction')
            .set('eventAction', 'Success');
        return dl;
    }

    public authenticationTan = {
        // Authentication (TAN).submit
        onEnterSmsCode: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._onEnterSmsCodeDefaults(dl).write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Authentication (TAN).confirm.success
        onEnterSmsCodeSuccess: (defaults: Defaults, noDelay = false): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                dl.set('pageName', 'Authentication')
                    .set('viewChange', 'Authentication')
                    .set('eventType', 'Authentication')
                    .set('eventAction', 'Request')
                    .set('linkInformation', 'Authentication request')
                    .set('additionalEvent', 'Authentication', 'Success', 'Authentication success')
                    .write();
            };
            this.track('interaction', updateDataLayer, undefined, noDelay);
        },
    };

    // Authentication failed

    public authenticationFailed = {
        // Authentication failed.confirm.error
        onConfirmIdentityPageAuthFailed: (defaults: Defaults, formName?: string): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                dl.set('pageName', 'Authentication')
                    .set('viewChange', 'Authentication failed')
                    .set('formType', 'Authentication')
                    .set('formName', formName || 'Personal data')
                    .set('eventType', 'Authentication')
                    .set('eventAction', 'Failed')
                    .set('eventAction', 'Authentication failed')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Authentication failed.abort
        onConfirmIdentityAborted: (
            lastTouchedField: string,
            defaults: Defaults,
            noDelay = true,
            formName?: string,
        ): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                dl.set('pageName', 'Authentication')
                    .set('formType', 'Authentication')
                    .set('formName', formName || 'Personal data')
                    .set('formLastTouchedField', lastTouchedField)
                    .set('linkInformation', 'Authentication aborted')
                    .set('eventType', 'Form')
                    .set('eventAction', 'Aborted')
                    .write();
            };
            this.track('interaction', updateDataLayer, undefined, noDelay);
        },
    };

    // Introduction

    public introduction = {
        // Introduction.view
        onIntroductionPage: (defaults: Defaults, isAfterRegistration = false): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl)
                    .set('pageName', 'Introduction')
                    .set('viewChange', 'Introduction');
                if (isAfterRegistration) {
                    dl.set('additionalEvent', 'Registration', 'Success');
                }
                dl.write();
            };
            this.track('page', updateDataLayer);
        },
    };

    private _setDashboardDefaults(dl: DataLayer): DataLayer {
        dl.set('pageName', 'Dashboard');
        return dl;
    }

    private _setEditDashboardDefaults(dl: DataLayer, view: string, item: string): DataLayer {
        dl.set('pageName', 'Dashboard')
            .set('viewChange', `${view} - ${item}`)
            .set('formType', 'Finance')
            .set('formName', item);
        return dl;
    }

    // Dashboard & Dashboard (outstanding-1) & Dashboard (outstanding-2) & Dashboard (outstanding-3)

    public dashboard = {
        //As values for view use: Financial Details, Products / Services included, Vehicle details, Contract parties

        // Dashboard.view
        onDashboardWithoutUnpaid: (
            from: 'login' | 'registration' | '',
            digitalRenewal = false,
            defaults: Defaults,
            bankAccountUpdate?: boolean,
        ): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setDashboardDefaults(dl)
                    .set('eventAction', 'Success')
                    .set('eventType', 'pageView');

                switch (from) {
                    case 'login':
                        dl.set('additionalEvent', 'Login', 'Success');
                        break;
                    case 'registration':
                        dl.set('additionalEvent', 'Registration', 'Success');
                        break;
                    default:
                        break;
                }

                if (digitalRenewal) dl.set('viewChange', 'Digital renewal invitation');
                if (bankAccountUpdate) dl.set('viewChange', 'Bank account update necessary');
                if (digitalRenewal && bankAccountUpdate)
                    dl.set('viewChange', 'Digital renewal invitation / Bank account update necessary');
                if (!digitalRenewal && !bankAccountUpdate) dl.set('viewChange', 'Dashboard');

                dl.write();
            };
            this.track('page', updateDataLayer);
        },

        // Dashboard.inactiveContracts
        onDashboardWithInactiveContracts: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl);
                this._setDashboardDefaults(dl)
                    .set('viewChange', 'Inactive contracts')
                    .set('eventType', 'pageView')
                    .set('eventAction', 'Success')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // Dashboard.addContract
        onAddContract: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl);
                this._setDashboardDefaults(dl)
                    .set('viewChange', 'Add contract')
                    .set('eventType', 'pageView')
                    .set('eventAction', 'Success')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // Dashboard.filter
        filterPage: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl);
                this._setDashboardDefaults(dl)
                    .set('viewChange', 'Filter')
                    .set('eventType', 'pageView')
                    .set('eventAction', 'Success')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // Dashboard.changePaymentDate
        onDashboardChangePaymentDate: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl)
                    .set('pageName', 'End of contract')
                    .set('eventType', 'interAction')
                    .set('eventAction', 'Success')
                    .set('linkInformation', 'Change payment date')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Dashboard.changePaymentDate
        onChangePaymentDate: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl);
                dl.set('viewChange', 'Request purchase price')
                    .set('pageName', 'End of contract')
                    .set('viewChange', 'Change payment date')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // Dashboard.changePaymentDate.submit
        onChangePaymentDateSubmit: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl)
                    .set('pageName', 'End of contract')
                    .set('linkInformation', 'Changed payment date submitted')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Dashboard.addContractError
        onAddContractError: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setAuthFormDefaults(dl)
                    .set('linkInformation', 'Add contract - Error')
                    .set('eventType', 'Success')
                    .set('eventAction', 'interAction')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Dashboard.infoTip
        onMonthlyPaymentInfoTip: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setAuthFormDefaults(dl)
                    .set('linkInformation', 'Info monthly base rate')
                    .set('eventType', 'Success')
                    .set('eventAction', 'interAction')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Dashboard.cancelContract
        onCancelNonStandardContract: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setAuthFormDefaults(dl)
                    .set('linkInformation', 'Cancel contract')
                    .set('eventType', 'Success')
                    .set('eventAction', 'interAction')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Dashboard.suspendInstallment
        onOpenSuspendInstallment: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl);
                this._setDashboardDefaults(dl)
                    .set('viewChange', 'Dashboard')
                    .set('eventType', 'interAction')
                    .set('eventAction', 'Success')
                    .set('linkInformation', 'Suspend installment')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        //Dashboard.learnMore
        onLearnMore: (linkLabelEN: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl);
                this._setDashboardDefaults(dl)
                    .set('viewChange', 'Dashboard')
                    .set('eventType', 'interAction')
                    .set('eventAction', 'Success')
                    .set('linkInformation', `Internal campaign: ${linkLabelEN}`)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        //Dashboard.chi
        chi: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl);
                this._setDashboardDefaults(dl)
                    .set('eventType', 'interAction')
                    .set('eventAction', 'Success')
                    .set('linkInformation', 'Determine customer happiness index')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Dashboard.vehicleDetails
        onVehicleDetails: (defaults: Defaults): void => {
            const updateDataLayer = (): void => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl);
                this._setDashboardDefaults(dl)
                    .set('viewChange', 'Dashboard')
                    .set('eventType', 'interAction')
                    .set('eventAction', 'Success')
                    .set('linkInformation', 'Vehicle details')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        //Dashboard.digitalRenewal
        onDashboardDigitalRenewal: (defaults: Defaults): void => {
            const updateDataLayer = (): void => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl);
                this._setDashboardDefaults(dl)
                    .set('eventType', 'interAction')
                    .set('eventAction', 'Success')
                    .set('linkInformation', 'Start digital renewal')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        //Dashboard.reportDamage
        onReportDamage: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl);
                this._setDashboardDefaults(dl)
                    .set('viewChange', 'Dashboard')
                    .set('linkInformation', 'Report damage')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        //Dashboard.updateBankAccountClick
        onBankAccountUpdateClick: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl);
                this._setDashboardDefaults(dl)
                    .set('linkInformation', 'Bank account update necessary: Update bank account')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Dashboard (outstanding-1) & Dashboard (outstanding-2) & Dashboard (outstanding-3)

        outstandingPayment: {
            // Dashboard (outstanding-1).view
            onDashboard: (
                from: 'login' | 'registration' | '',
                outstandingPayment: boolean,
                digitalRenewal = false,
                defaults: Defaults,
            ): void => {
                const updateDataLayer = () => {
                    const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                    this._setDashboardDefaults(dl)
                        .set('eventAction', 'Success')
                        .set('eventType', 'pageView');
                    switch (from) {
                        case 'login':
                            dl.set('additionalEvent', 'Login', 'Success');
                            break;
                        case 'registration':
                            dl.set('additionalEvent', 'Registration', 'Success');
                            break;
                        default:
                            break;
                    }

                    if (outstandingPayment && digitalRenewal)
                        dl.set('viewChange', 'Outstanding payment / Digital renewal invitation');
                    if (outstandingPayment && !digitalRenewal) dl.set('viewChange', 'Outstanding payment');
                    if (!outstandingPayment && digitalRenewal) dl.set('viewChange', 'Digital renewal invitation');

                    dl.write();
                };
                this.track('page', updateDataLayer);
            },

            // Dashboard (outstanding-2).view
            onOverview: (defaults: Defaults): void => {
                const updateDataLayer = () => {
                    const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                    this._setPageViewEvent(dl);
                    this._setDashboardDefaults(dl)
                        .set('viewChange', 'Outstanding payment - overview')
                        .write();
                };
                this.track('page', updateDataLayer);
            },

            // Dashboard (outstanding-3).view
            onPayment: (defaults: Defaults): void => {
                const updateDataLayer = () => {
                    const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                    this._setPageViewEvent(dl);
                    this._setDashboardDefaults(dl)
                        .set('viewChange', 'Outstanding payment - payment page')
                        .write();
                };
                this.track('page', updateDataLayer);
            },

            // Dashboard (outstanding-3).payNow.submit
            onPay: (defaults: Defaults): void => {
                const updateDataLayer = () => {
                    const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                    this._setPageInteractionEvent(dl);
                    this._setDashboardDefaults(dl)
                        .set('viewChange', 'Outstanding payment - payment page')
                        .set('linkInformation', 'Pay now')
                        .write();
                };
                this.track('interaction', updateDataLayer);
            },

            // Dashboard (outstanding-3).payNow.success
            onPaymentSuccess: (defaults: Defaults): void => {
                const updateDataLayer = () => {
                    const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                    this._setPageViewEvent(dl);
                    this._setDashboardDefaults(dl)
                        .set('viewChange', 'Outstanding payment - success')
                        .write();
                };
                this.track('page', updateDataLayer);
            },

            // Dashboard (outstanding-3).payNow.failed
            onPaymentNoSuccess: (defaults: Defaults): void => {
                const updateDataLayer = () => {
                    const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                    this._setPageViewEvent(dl);
                    this._setDashboardDefaults(dl)
                        .set('viewChange', 'Outstanding payment - no success')
                        .write();
                };
                this.track('page', updateDataLayer);
            },
        },

        onDashboardStartMatchmaker: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setDashboardDefaults(dl);
                this._setStartMatchmakerInteractionDefaults(dl);
                this._setPageInteractionEvent(dl).write();
            };
            this.track('interaction', updateDataLayer);
        },

        //Dashboard.onClickContractActionEntryPoint
        onClickContractActionEntryPoint: (entrypointName: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl);
                this._setDashboardDefaults(dl)
                    .set('linkInformation', `${entrypointName}`)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },
    };

    // Dashboard (edit)

    public dashboardEdit = {
        // Dashboard (edit).accordionOpen
        onOpenDashboardAccordion: (view: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl);
                this._setDashboardDefaults(dl)
                    .set('viewChange', view)
                    .set('linkInformation', `Open section ${view}`)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Dashboard (edit).bankAccount
        onBankAccountChange: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl);
                this._setDashboardDefaults(dl)
                    .set('viewChange', 'Financial Details - Payment Information')
                    .set('formType', 'Finance')
                    .set('formName', 'Bank account')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // Dashboard (edit).SEPA
        onSepa: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl);
                this._setDashboardDefaults(dl)
                    .set('viewChange', 'Financial details - Bank account')
                    .set('formType', 'Finance')
                    .set('formName', 'Bank account')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // Dashboard (edit).SEPAsign
        onSepaSign: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl);
                this._setDashboardDefaults(dl)
                    .set('viewChange', 'Financial details - SEPA digital sign')
                    .set('formType', 'Finance')
                    .set('formName', 'Financial details')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // Dashboard (edit).SEPAsignSuccess
        onSepaSignSuccess: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl);
                this._setDashboardDefaults(dl)
                    .set('viewChange', 'Financial details - SEPA digital sign - Request')
                    .set('formType', 'Finance')
                    .set('formName', 'Financial details')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // Dashboard (edit).SEPAsignError
        onSepaSignError: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl);
                this._setDashboardDefaults(dl)
                    .set('viewChange', 'Financial details - SEPA digital sign - Error')
                    .set('formType', 'Finance')
                    .set('formName', 'Financial details')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // Dashboard (edit).accordion.edit
        onEditDashboardSection: (view: string, item: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl);
                this._setEditDashboardDefaults(dl, view, item)
                    .set('linkInformation', 'Profile edit')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Dashboard (edit).accordion.startTyping
        onStartTypingEditDashboardSection: (view: string, item: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEditDashboardDefaults(dl, view, item)
                    .set('eventType', 'Form')
                    .set('eventAction', 'Start typing')
                    .set('linkInformation', 'Profile edit')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Dashboard (edit).accordion.error
        onFormValidationErrorEditDashboardSection: (
            view: string,
            item: string,
            fieldErrors: string,
            defaults: Defaults,
        ): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setDashboardDefaults(dl)
                    .set('viewChange', `${view} - ${item} input error`)
                    .set('formType', 'Finance')
                    .set('formName', item)
                    .set('formErrorFields', fieldErrors)
                    .set('eventType', 'Form')
                    .set('eventAction', 'Input error')
                    .set('linkInformation', 'Profile edit input error')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Dashboard (edit).accordion.abort
        onCancelEditDashboardSection: (view: string, item: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl);
                this._setEditDashboardDefaults(dl, view, item)
                    .set('linkInformation', 'Edit cancelled')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Dashboard (edit).accordion.failed
        onSubmitErrorEditDashboardSection: (view: string, item: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setDashboardDefaults(dl)
                    .set('viewChange', `${view} - ${item} edit failed`)
                    .set('formType', 'Finance')
                    .set('formName', item)
                    .set('eventType', 'interAction')
                    .set('eventAction', 'Success')
                    .set('linkInformation', 'Edit input error')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Dashboard (edit).accordion.areYouSure
        onConfirmEditDashboardSection: (view: string, item: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl);
                this._setDashboardDefaults(dl)
                    .set('viewChange', `${view} - ${item} edit confirmation`)
                    .set('formType', 'Finance')
                    .set('formName', item)
                    .set('linkInformation', 'Edit confirmation question')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Dashboard (edit).accordion.canceled
        onCancelConfirmationEditDashboardSection: (view: string, item: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl);
                this._setDashboardDefaults(dl)
                    .set('viewChange', `${view} - ${item} edit confirmation`)
                    .set('linkInformation', 'Edit confirmation canceled')
                    .set('formType', 'Finance')
                    .set('formName', item)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Dashboard (edit).accordion.success
        onSuccessEditDashboardSection: (view: string, item: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl);
                this._setDashboardDefaults(dl)
                    .set('viewChange', `${view} - ${item} edit success`)
                    .set('linkInformation', 'Edit success')
                    .set('formType', 'Finance')
                    .set('formName', item)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        sepaMandate: {
            // Dashboard (edit).SEPA.technicalError
            sepaMandateDownloadError: (defaults: Defaults, error: ErrorTracking, section?: string): void => {
                const updateDataLayer = () => {
                    const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                    this._setDashboardDefaults(dl)
                        .set('viewChange', `${section || 'Financial Details'} - download error`)
                        .set('errorCode', error.errorCode)
                        .set('errorMessage', error.errorMessage)
                        .set('errorCausingUrl', error.errorCausingUrl)
                        .set('formType', 'Finance')
                        .set('formName', section || 'Financial Details')
                        .set('eventType', 'Error')
                        .set('eventAction', 'Technical error')
                        .set('linkInformation', 'Download SEPA mandate failed')
                        .write();
                };
                this.track('interaction', updateDataLayer);
            },

            // Dashboard (edit).SEPA.success
            onSepaMandateDownload: (defaults: Defaults, section?: string): void => {
                const updateDataLayer = () => {
                    const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                    this._setDashboardDefaults(dl)
                        .set('viewChange', section || 'Financial Details')
                        .set('formType', 'Finance')
                        .set('formName', section || 'Financial Details')
                        .set('eventType', 'Download')
                        .set('eventAction', 'Request')
                        .set('linkInformation', 'Download SEPA mandate')
                        .write();
                };
                this.track('download', updateDataLayer);
            },
        },
    };

    // Request certificate

    private _setRequestCertificateDefaults(dl: DataLayer): DataLayer {
        dl.set('pageName', 'Document request');
        return dl;
    }

    public requestCertificate = {
        // Request certificate.view
        onRequestCertificate: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl);
                this._setRequestCertificateDefaults(dl)
                    .set('viewChange', 'Document request')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // Request certificate.success
        onSuccessRequestCertificate: (documentNameEN: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl);
                this._setRequestCertificateDefaults(dl)
                    .set('viewChange', 'Document request success')
                    .set('linkInformation', `Document ${documentNameEN}`)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Request certificate.failed
        onRequestCertificateSubmitFailed: (documentNameEN: string, error: ErrorTracking, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setRequestCertificateDefaults(dl)
                    .set('viewChange', 'Document request failed')
                    .set('eventType', 'interAction')
                    .set('eventAction', 'Failed')
                    .set('linkInformation', `Document ${documentNameEN}`)
                    .set('errorCode', error.errorCode)
                    .set('errorMessage', error.errorMessage)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Request certificate.technicalError
        onRequestCertificateSubmitAPIError: (
            documentNameEN: string,
            error: ErrorTracking,
            defaults: Defaults,
        ): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setRequestCertificateDefaults(dl)
                    .set('viewChange', 'Document request failed')
                    .set('eventType', 'API')
                    .set('eventAction', 'Error')
                    .set('linkInformation', `Document ${documentNameEN}`)
                    .set('errorCode', error.errorCode)
                    .set('errorMessage', error.errorMessage)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },
    };

    // Request Subrogation

    private _setRequestSubrogationDefaults(dl: DataLayer): DataLayer {
        dl.set('pageName', 'Subrogation');
        return dl;
    }

    public requestSubrogation = {
        // Request subrogation.view
        onClickEntryPointAction: (entrypointName: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl);
                this._setRequestSubrogationDefaults(dl)
                    .set('viewChange', `${entrypointName}`)
                    .write();
            };
            this.track('page', updateDataLayer);
        },
    };

    //Contract Recalculation

    public contractRecalculation = {
        //Contract recalculation.success
        onContractRecalculationFormSubmitSuccess: (type: string, change: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl)
                    .set('eventType', 'interAction')
                    .set('eventAction', 'Success')
                    .set('linkInformation', `Recalculation: ${type} ${change}`)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },
    };

    // Amortization

    private _setAmortizationTableDefaults(dl: DataLayer): DataLayer {
        dl.set('pageName', 'Amortization Table').set('viewChange', 'Amortization Table');
        return dl;
    }

    public amortizationTable = {
        // Amortization.view
        onAmortizationTable: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setAmortizationTableDefaults(dl);
                this._setPageViewEvent(dl).write();
            };
            this.track('page', updateDataLayer);
        },

        // Amortization.download
        onAmortizationTableDownload: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setAmortizationTableDefaults(dl)
                    .set('eventType', 'Download')
                    .set('eventAction', 'Request')
                    .set('linkInformation', 'Download amortization table')
                    .write();
            };
            this.track('download', updateDataLayer);
        },

        // Amortization.download2
        onBundledProductsDownload: (defaults: Defaults, fieldValues: string): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setAmortizationTableDefaults(dl)
                    .set('eventType', 'Download')
                    .set('eventAction', 'Request')
                    .set('linkInformation', fieldValues || 'Download bundled products')
                    .write();
            };
            this.track('download', updateDataLayer);
        },
    };

    // Early settlement & Early settlement (purchase+hire)

    private _setEarlySettlementDefaults(dl: DataLayer): DataLayer {
        dl.set('pageName', 'Early Settlement');
        return dl;
    }

    private _setEarlySettlementSummaryDefaults(dl: DataLayer, cardPayAvailable: boolean): DataLayer {
        dl.set('pageName', 'Early Settlement summary').set(
            'viewChange',
            `Early settlement summary: ${cardPayAvailable ? 'Pay by card possible' : 'Pay by card not possible'}`,
        );
        return dl;
    }

    private _setEarlyTerminationCreditContractDefaults(dl: DataLayer): DataLayer {
        dl.set('pageName', 'Early termination credit contract');
        return dl;
    }

    private _getEarlySettlementProductName(discriminator: string): string {
        return discriminator === 'settlement' ? 'AutoCredit' : 'Rental';
    }

    public earlySettlement = {
        //Early Settlement.view
        onEarlySettlement: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEarlySettlementDefaults(dl).set('viewChange', 'Early Settlement');
                this._setPageViewEvent(dl).write();
            };
            this.track('page', updateDataLayer);
        },

        //Early SettlementSummary.view
        onEarlySettlementSummaryPage: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl).write();
                this._setPageViewEvent(dl)
                    .set('pageName', 'Early Settlement summary')
                    .set('viewChange', 'Early Settlement summary')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        //Early Settlement.payment.goto
        onEarlySettlementProceedToPayment: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEarlySettlementDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('viewChange', 'Early Settlement')
                    .set('linkInformation', 'Proceed to payment')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        //Early Settlement.accordion.settlementDetails
        onEarlySettlementDetails: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEarlySettlementDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('viewChange', 'Settlement details')
                    .set('linkInformation', 'Settlement details')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        //Early Settlement.simulation
        onEarlySettlementSimulation: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEarlySettlementDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('viewChange', 'Settlement simulation')
                    .set('linkInformation', 'Settlement simulation')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        //Early Settlement.calculation
        onEarlySettlementCalculate: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEarlySettlementDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('viewChange', 'Settlement simulation')
                    .set('linkInformation', 'Settlement simulated')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        //Early Settlement.summary
        onEarlySettlementSummary: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl)
                    .set('pageName', 'Early Settlement summary')
                    .set('viewChange', 'Early Settlement summary')
                    .set('linkInformation', 'Early Settlement summary')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        //Early Settlement.success
        onEarlySettlementSummaryLayerDisplayed: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl)
                    .set('pageName', 'Early Settlement summary')
                    .set('viewChange', 'Submit success')
                    .set('linkInformation', 'Payment details per email')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        //Early Settlement.failed
        onEarlySettlementSummarySubmitFailed: (defaults: Defaults, error?: ErrorTracking): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl)
                    .set('pageName', 'Early Settlement summary')
                    .set('viewChange', 'Submit fail')
                    .set('linkInformation', 'Payment details per email')
                    .set('errorCode', error?.errorCode)
                    .set('errorMessage', error?.errorMessage)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        //Settlement.COVID
        onCOVID19: (defaults: Defaults, error?: ErrorTracking): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl)
                    .set('pageName', 'Early Settlement summary')
                    .set('viewChange', 'Submit fail')
                    .set('linkInformation', 'Payment details per email')
                    .set('errorCode', error?.errorCode)
                    .set('errorMessage', error?.errorMessage)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        hirePurchase: {
            // Early settlement (purchase+hire).view
            onEarlySettlementGenerate: (discriminator: string, defaults: Defaults): void => {
                const updateDataLayer = () => {
                    const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                    this._setEarlySettlementDefaults(dl);
                    this._setPageViewEvent(dl)
                        .set('viewChange', 'Generate settlement quote')
                        .set('productCategory', 'Finance')
                        .set('productName', this._getEarlySettlementProductName(discriminator))
                        .write();
                };
                this.track('page', updateDataLayer);
            },

            // Early settlement (purchase+hire).generateQuote
            onEarlySettlementGenerateQuote: (
                discriminator: string,
                linkInformation: string,
                defaults: Defaults,
            ): void => {
                const updateDataLayer = () => {
                    const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                    this._setEarlySettlementDefaults(dl);
                    this._setPageInteractionEvent(dl)
                        .set('viewChange', 'Generate settlement quote')
                        .set('productCategory', 'Finance')
                        .set('productName', this._getEarlySettlementProductName(discriminator))
                        .set('linkInformation', linkInformation) //"Request quote (by email)", "Request quote (by post)", "Request quote (by email and post)"
                        .write();
                };
                this.track('interaction', updateDataLayer);
            },

            // Early settlement (purchase+hire).view.enquiry
            onEarlySettlementEnquiry: (discriminator: string, defaults: Defaults): void => {
                const updateDataLayer = () => {
                    const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                    this._setEarlySettlementDefaults(dl);
                    this._setPageViewEvent(dl)
                        .set('viewChange', 'Quote')
                        .set('productCategory', 'Finance')
                        .set('productName', this._getEarlySettlementProductName(discriminator))
                        .write();
                };
                this.track('page', updateDataLayer);
            },

            // Early settlement (purchase+hire).print
            onEarlySettlementEnquiryPrint: (discriminator: string, defaults: Defaults): void => {
                const updateDataLayer = () => {
                    const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                    this._setEarlySettlementDefaults(dl);
                    this._setPageInteractionEvent(dl)
                        .set('viewChange', 'Generate settlement quote')
                        .set('productCategory', 'Finance')
                        .set('productName', this._getEarlySettlementProductName(discriminator))
                        .set('linkInformation', 'Print settlement quote')
                        .write();
                };
                this.track('interaction', updateDataLayer);
            },

            // Early settlement (purchase+hire).view.summary
            onEarlySettlementComplete: (discriminator: string, cardPayAvailable: boolean, defaults: Defaults): void => {
                const updateDataLayer = () => {
                    const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                    this._setPageViewEvent(dl);
                    this._setEarlySettlementSummaryDefaults(dl, cardPayAvailable)
                        .set('productCategory', 'Finance')
                        .set('productName', this._getEarlySettlementProductName(discriminator))
                        .write();
                };
                this.track('page', updateDataLayer);
            },

            // Early settlement (purchase+hire).Button Print
            onEarlySettlementCompletePrint: (
                discriminator: string,
                cardPayAvailable: boolean,
                defaults: Defaults,
            ): void => {
                const updateDataLayer = () => {
                    const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                    this._setEarlySettlementSummaryDefaults(dl, cardPayAvailable);
                    this._setPageInteractionEvent(dl)
                        .set('productCategory', 'Finance')
                        .set('productName', this._getEarlySettlementProductName(discriminator))
                        .set('linkInformation', 'Print settlement quote')
                        .write();
                };
                this.track('interaction', updateDataLayer);
            },

            onEarlySettlementPaymentSelectBankTransfer: (
                discriminator: string,
                cardPayAvailable: boolean,
                defaults: Defaults,
            ): void => {
                const updateDataLayer = () => {
                    const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                    this._setEarlySettlementSummaryDefaults(dl, cardPayAvailable)
                        .set('eventType', 'Payment')
                        .set('eventAction', 'Request')
                        .set('productCategory', 'Finance')
                        .set('productName', this._getEarlySettlementProductName(discriminator))
                        .set('linkInformation', 'Pay by bank transfer requested')
                        .write();
                };
                this.track('interaction', updateDataLayer);
            },

            onEarlySettlementPaymentProceedToPayByCard: (
                discriminator: string,
                cardPayAvailable: boolean,
                defaults: Defaults,
            ): void => {
                const updateDataLayer = () => {
                    const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                    this._setEarlySettlementSummaryDefaults(dl, cardPayAvailable)
                        .set('eventType', 'Payment')
                        .set('eventAction', 'Request')
                        .set('productCategory', 'Finance')
                        .set('productName', this._getEarlySettlementProductName(discriminator))
                        .set('linkInformation', 'Pay by card requested')
                        .write();
                };
                this.track('interaction', updateDataLayer);
            },

            onEarlySettlementPaymentSuccess: (discriminator: string, defaults: Defaults): void => {
                const updateDataLayer = () => {
                    const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                    this._setPageViewEvent(dl)
                        .set('pageName', 'Pay by credit card confirmation')
                        .set('viewChange', 'Pay by card successful')
                        .set('productCategory', 'Finance')
                        .set('productName', this._getEarlySettlementProductName(discriminator))
                        .set('eventType', 'Payment')
                        .set('eventAction', 'Success')
                        .write();
                };
                this.track('page', updateDataLayer);
            },
            onEarlySettlementPaymentError: (discriminator: string, defaults: Defaults): void => {
                const updateDataLayer = () => {
                    const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                    this._setPageViewEvent(dl)
                        .set('pageName', 'Pay by credit card confirmation')
                        .set('viewChange', 'Pay by card not successful')
                        .set('productCategory', 'Finance')
                        .set('productName', this._getEarlySettlementProductName(discriminator))
                        .set('eventType', 'Payment')
                        .set('eventAction', 'Failed')
                        .write();
                };
                this.track('page', updateDataLayer);
            },
        },

        // Not specified in the new documentation
        onHeyCarLinkClick: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEarlySettlementDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Hey Car')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        //Choice partial or total
        onClickEntryPointAction: (entrypointName: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl);
                this._setRequestSubrogationDefaults(dl)
                    .set('viewChange', `${entrypointName}`)
                    .write();
            };
            this.track('page', updateDataLayer);
        },
    };

    // Early termination (Leasing) & Early termination (Credit) Info & Early termination (Credit) Upload

    public earlyTermination = {
        // Early termination (Leasing).view
        onEarlyTerminationLeasingContract: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl)
                    .set('pageName', 'Early termination leasing contract')
                    .set('viewChange', 'Early termination leasing contract - Info page')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        //Early termination (Credit) Info.view
        onEarlyTerminationCreditContractView: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEarlyTerminationCreditContractDefaults(dl);
                this._setPageViewEvent(dl)
                    .set('viewChange', 'Early termination credit contract - Info page')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        //Early termination (Credit) Upload.view
        onEarlyTerminationCreditContractUpload: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEarlyTerminationCreditContractDefaults(dl);
                this._setPageViewEvent(dl)
                    .set('viewChange', 'Early termination credit contract - Document upload')
                    .set('formType', 'Contract termination')
                    .set('formType', 'Document upload')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        //Early termination (Credit) Upload.error
        onEarlyTerminationCreditContractValidationError: (fieldErrors: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEarlyTerminationCreditContractDefaults(dl)
                    .set('viewChange', 'Early termination credit contract - form error')
                    .set('formType', 'Contract termination')
                    .set('formName', 'Document upload')
                    .set('formErrorFields', fieldErrors)
                    .set('eventType', 'Form')
                    .set('eventAction', 'Input error')
                    .set('viewChange', 'Early termination credit contract - form error')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        //Early termination (Credit) Upload.success
        onEarlyTerminationCreditContractSendRequest: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEarlyTerminationCreditContractDefaults(dl);
                this._setPageViewEvent(dl)
                    .set('viewChange', 'Early termination credit contract - Request sent')
                    .set('viewChange', 'Early termination credit contract - Request sent')
                    .write();
            };
            this.track('page', updateDataLayer);
        },
    };

    // End of Contract - Common

    private _setEndOfContractDefaults(dl: DataLayer): DataLayer {
        dl.set('pageName', 'End of contract').set('productCategory', 'Finance');
        return dl;
    }

    // End of Contract - PCP

    private _setEndOfContractPcpDefaults(dl: DataLayer): DataLayer {
        this._setEndOfContractDefaults(dl);
        dl.set('productName', 'AutoCredit');
        return dl;
    }

    private _setEndOfContractPcpOptions(dl: DataLayer): DataLayer {
        this._setEndOfContractPcpDefaults(dl);
        dl.set('viewChange', 'End of contract options');
        return dl;
    }

    private _setEndOfContractPcpExchange(dl: DataLayer): DataLayer {
        this._setEndOfContractPcpDefaults(dl);
        dl.set('viewChange', 'Exchange car');
        return dl;
    }

    private _setEndOfContractPcpKeep(dl: DataLayer): DataLayer {
        this._setEndOfContractPcpDefaults(dl);
        dl.set('viewChange', 'Keep car');
        return dl;
    }

    private _setEndOfContractPcpHandBack(dl: DataLayer): DataLayer {
        this._setEndOfContractPcpDefaults(dl);
        dl.set('viewChange', 'Returning your car');
        return dl;
    }

    private _setEndOfContractPcpRefinance(dl: DataLayer): DataLayer {
        this._setEndOfContractPcpDefaults(dl);
        dl.set('viewChange', 'Refinance');
        return dl;
    }

    private _setEndOfContractPcpSell(dl: DataLayer): DataLayer {
        this._setEndOfContractPcpDefaults(dl);
        dl.set('viewChange', 'Sell');
        return dl;
    }

    public endOfContractPcp = {
        // EndOfContractPcpStart.requestPrice
        requestPrice: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl)
                    .set('viewChange', 'Request purchase price')
                    .set('pageName', 'End of contract')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // EndOfContractPcpStart.requestPriceSubmit
        onRequestPriceSubmit: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                dl.set('linkInformation', 'Request purchase price submitted')
                    .set('eventType', 'interAction')
                    .set('eventAction', 'Success')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractPcpStart.requestPriceSubmit
        silentContractExtension: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl)
                    .set('pageName', 'End of contract')
                    .set('viewChange', 'Silent contract extension')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // EndOfContractPcpStart.requestPriceSubmit
        silentContractExtensionSubmit: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                dl.set('linkInformation', 'Silent contract extension submitted')
                    .set('eventType', 'interAction')
                    .set('eventAction', 'Success')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractPcpStart.view
        onEndOfContractPcpStart: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractPcpOptions(dl);
                this._setPageViewEvent(dl).write();
            };
            this.track('page', updateDataLayer);
        },

        // EndOfContractPcpExchange.view
        onEndOfContractPcpExchange: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractPcpExchange(dl);
                this._setPageViewEvent(dl).write();
            };
            this.track('page', updateDataLayer);
        },

        // EndOfContractPcpKeep.view
        onEndOfContractPcpKeep: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractPcpKeep(dl);
                this._setPageViewEvent(dl).write();
            };
            this.track('page', updateDataLayer);
        },

        // EndOfContractPcpHandBack.view
        onEndOfContractPcpHandBack: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractPcpHandBack(dl);
                this._setPageViewEvent(dl).write();
            };
            this.track('page', updateDataLayer);
        },

        // EndOfContractPcpRefinance.view
        onEndOfContractPcpRefinance: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractPcpRefinance(dl);
                this._setPageViewEvent(dl).write();
            };
            this.track('page', updateDataLayer);
        },

        // EndOfContractPcpRefinanceSuccess.view
        onEndOfContractPcpRefinanceSuccess: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractPcpDefaults(dl);
                this._setPageViewEvent(dl)
                    .set('viewChange', 'Refinance request submitted')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // EndOfContractPcpRefinanceFailure.view
        onEndOfContractPcpRefinanceFailure: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractPcpDefaults(dl);
                this._setPageViewEvent(dl)
                    .set('viewChange', 'Refinance request submit failed')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // EndOfContractPcpSell.view
        onEndOfContractPcpSell: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractPcpSell(dl);
                this._setPageViewEvent(dl).write();
            };
            this.track('page', updateDataLayer);
        },

        // EndOfContractPcpExchangeContactRetailer.Contact your dealer
        onEndOfContractPcpExchangeContactRetailer: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractPcpExchange(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Contact your dealer')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractPcpExchangeRequestCallback.Request a call-back
        onEndOfContractPcpExchangeRequestCallback: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractPcpExchange(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Request a call-back')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractPcpExchangeFinanceOffers.Find out more
        onEndOfContractPcpExchangeFinanceOffers: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractPcpExchange(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Find out more')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractPcpExchangeConfigurator.Configure your next car
        onEndOfContractPcpExchangeConfigurator: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractPcpExchange(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Configure your next car')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractPcpExchangeBack.Click
        onEndOfContractPcpExchangeBack: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractPcpExchange(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Back to options')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractPcpExchangeDashboard.Back to dashboard
        onEndOfContractPcpExchangeDashboard: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractPcpExchange(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Back to dashboard')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractPcpExchangeDashboard.dashboard-4
        onEndOfContractPcpRenewalSelected: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractPcpOptions(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Go to match maker')
                    .write();
            };
            this.track('exit', updateDataLayer);
        },

        // EndOfContractPcpExchangeDashboard.dealer
        onEndOfContractPcpDealerData: (
            defaults: Defaults,
            renewalOption: 'Refinancing' | 'Payment' | 'Return the car',
        ): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractPcpOptions(dl);
                this._setPageViewEvent(dl)
                    .set('viewChange', renewalOption)
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // EndOfContractPcpKeepBack.Back to options
        onEndOfContractPcpKeepBack: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractPcpKeep(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Back to options')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractPcpKeepDashboard.Back to dashboard
        onEndOfContractPcpKeepDashboard: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractPcpKeep(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Back to dashboard')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractPcpHandBackPersonalisedPlates.Get private plate
        onEndOfContractPcpHandBackPersonalisedPlates: (eventTargetURL: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractPcpHandBack(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Get private plate')
                    .set('eventTargetURL', eventTargetURL)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractPcpHandBackBvrlaGuidelines.Fair wear & tear guide
        onEndOfContractPcpHandBackBvrlaGuidelines: (eventTargetURL: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractPcpHandBack(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Fair wear & tear guide')
                    .set('eventTargetURL', eventTargetURL)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractPcpHandBackEndOfContractCharges.Charges for Damage or Missing Items
        onEndOfContractPcpHandBackEndOfContractCharges: (eventTargetURL: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractPcpHandBack(dl)
                    .set('eventType', 'Download')
                    .set('eventAction', 'Request')
                    .set('linkInformation', 'Charges for Damage or Missing Items')
                    .set('eventTargetURL', eventTargetURL)
                    .write();
            };
            this.track('download', updateDataLayer);
        },

        // EndOfContractPcpHandBackBack.Back to options
        onEndOfContractPcpHandBackBack: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractPcpHandBack(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Back to options')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractPcpHandBackDashboard.Back to dashboard
        onEndOfContractPcpHandBackDashboard: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractPcpHandBack(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Back to dashboard')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractPcpRefinanceBack.Back to options
        onEndOfContractPcpRefinanceBack: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractPcpRefinance(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Back to options')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractPcpRefinanceRequest.Refinance request
        onEndOfContractPcpRefinanceRequest: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractPcpRefinance(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Refinance request')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractPcpSell.Back to options
        onEndOfContractPcpSellBack: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractPcpSell(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Back to options')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractPcpSell.Back to dashboard
        onEndOfContractPcpSellDashboard: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractPcpSell(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Back to dashboard')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractPcpSell.Value and inspect my car
        onEndOfContractPcpSellInspect: (eventTargetURL: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractPcpSell(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Value my car')
                    .set('eventTargetURL', eventTargetURL)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        onEndOfContractPcpStartMatchmaker: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractPcpDefaults(dl);
                this._setStartMatchmakerInteractionDefaults(dl);
                this._setPageInteractionEvent(dl).write();
            };
            this.track('interaction', updateDataLayer);
        },
    };

    // End Of Contract - RCH

    private _setEndOfContractRchDefaults(dl: DataLayer): DataLayer {
        this._setEndOfContractDefaults(dl);
        dl.set('productName', 'Rental');
        return dl;
    }

    private _setEndOfContractRchOptions(dl: DataLayer): DataLayer {
        this._setEndOfContractRchDefaults(dl);
        dl.set('viewChange', 'End of contract options');
        return dl;
    }

    private _setEndOfContractRchNew(dl: DataLayer): DataLayer {
        this._setEndOfContractRchDefaults(dl);
        dl.set('viewChange', 'Find new car');
        return dl;
    }

    private _setEndOfContractRchExtend(dl: DataLayer): DataLayer {
        this._setEndOfContractRchDefaults(dl);
        dl.set('viewChange', 'Extend agreement');
        return dl;
    }

    private _setEndOfContractRchExtension(dl: DataLayer): DataLayer {
        this._setEndOfContractRchDefaults(dl);
        dl.set('viewChange', 'Extension request - options');
        return dl;
    }

    private _setEndOfContractRchReturn(dl: DataLayer): DataLayer {
        this._setEndOfContractRchDefaults(dl);
        dl.set('viewChange', 'Returning your car');
        return dl;
    }

    private _setEndOfContractRchCallBack(section: string, dl: DataLayer): DataLayer {
        this._setEndOfContractRchDefaults(dl);
        dl.set('viewChange', `RCH ${section}: Callback request`);
        return dl;
    }

    public endOfContractRch = {
        // EndOfContractRchStart.view
        onEndOfContractRchStart: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractRchOptions(dl);
                this._setPageViewEvent(dl).write();
            };
            this.track('page', updateDataLayer);
        },

        // EndOfContractRchNew.View
        onEndOfContractRchNew: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractRchNew(dl);
                this._setPageViewEvent(dl).write();
            };
            this.track('page', updateDataLayer);
        },

        // EndOfContractRchExtend.View
        onEndOfContractRchExtend: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractRchExtend(dl);
                this._setPageViewEvent(dl).write();
            };
            this.track('page', updateDataLayer);
        },

        // EndOfContractRchExtension.View
        onEndOfContractRchExtension: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractRchExtension(dl);
                this._setPageViewEvent(dl).write();
            };
            this.track('page', updateDataLayer);
        },

        // EndOfContractRchExtensionSuccess.View
        onEndOfContractRchExtensionSuccess: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractRchExtension(dl);
                this._setPageViewEvent(dl)
                    .set('viewChange', 'RCH Extension Request submitted')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // EndOfContractRchExtensionFailure.View
        onEndOfContractRchExtensionFailure: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractRchExtension(dl);
                this._setPageViewEvent(dl)
                    .set('viewChange', 'RCH Extension Request submit failed')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // EndOfContractRchReturn.View
        onEndOfContractRchReturn: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractRchReturn(dl);
                this._setPageViewEvent(dl).write();
            };
            this.track('page', updateDataLayer);
        },

        // EndOfContractRchCallBack.View
        onEndOfContractRchCallBack: (section: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractRchCallBack(section, dl);
                this._setPageViewEvent(dl).write();
            };
            this.track('page', updateDataLayer);
        },

        // EndOfContractRchCallBackSuccess.View
        onEndOfContractRchCallBackSuccess: (section: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractRchCallBack(section, dl);
                this._setPageViewEvent(dl)
                    .set('viewChange', `RCH ${section}: Callback request submitted`)
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // EndOfContractRchCallBackFailure.View
        onEndOfContractRchCallBackFailure: (section: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractRchCallBack(section, dl);
                this._setPageViewEvent(dl)
                    .set('viewChange', `RCH ${section}: Callback request submit failed`)
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // EndOfContractRchNewContactRetailer.Contact your dealer
        onEndOfContractRchNewContactRetailer: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractRchNew(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Contact your dealer')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractRchNewFinanceOffers.Find out more
        onEndOfContractRchNewFinanceOffers: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractRchNew(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Find out more')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractRchNewNewFromStock.Find your next car
        onEndOfContractRchNewNewFromStock: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractRchNew(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Find your next car')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractRchNewConfigurator.Configure your next car
        onEndOfContractRchNewConfigurator: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractRchNew(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Configure your next car')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractRchNewBack.Back to options
        onEndOfContractRchNewBack: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractRchNew(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Back to options')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractRchNewDashboard.Back to dashboard
        onEndOfContractRchNewDashboard: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractRchNew(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Back to dashboard')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractRchExtendShortTermExtension.Apply for short-term extension (FEATURE TOGGLED OFF)
        onEndOfContractRchExtendShortTermExtension: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractRchExtend(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Apply for short-term extension')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractRchExtendFormalExtension.Apply for formal extension (FEATURE TOGGLED OFF)
        onEndOfContractRchExtendFormalExtension: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractRchExtend(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Apply for formal extension')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractRchExtendBack.Back to options
        onEndOfContractRchExtendBack: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractRchExtend(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Back to options')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractRchExtendDashboard.Back to dashboard
        onEndOfContractRchExtendDashboard: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractRchExtend(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Back to dashboard')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractRchExtension.Back to options
        onEndOfContractRchExtensionBack: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractRchExtension(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Back to options')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractRchExtension.RCH Extension Request
        onEndOfContractRchExtensionRequest: (formName: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractRchExtension(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'RCH Extension Request')
                    .set('formName', formName)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractRchReturnBvrlaGuidelines.Fair wear & tear guide
        onEndOfContractRchReturnBvrlaGuidelines: (eventTargetURL: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractRchReturn(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Fair wear & tear guide')
                    .set('eventTargetURL', eventTargetURL)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractRchReturnEndOfContractCharges.Charges for Damage or Missing Items
        onEndOfContractRchReturnEndOfContractCharges: (eventTargetURL: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractRchReturn(dl)
                    .set('eventType', 'Download')
                    .set('eventAction', 'Request')
                    .set('linkInformation', 'Charges for Damage or Missing Items')
                    .set('eventTargetURL', eventTargetURL)
                    .write();
            };
            this.track('download', updateDataLayer);
        },

        // EndOfContractRchReturnBack.Back to options
        onEndOfContractRchReturnBack: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractRchReturn(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Back to options')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractRchReturnDashboard.Back to dashboard
        onEndOfContractRchReturnDashboard: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractRchReturn(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Back to dashboard')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractRchCallBackBack.Back to options
        onEndOfContractRchCallBackBack: (section: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractRchCallBack(section, dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Back to options')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // EndOfContractRchCallBackRequest.Request
        onEndOfContractRchCallBackRequest: (section: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractRchCallBack(section, dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'RCH Callback Request')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        onEndOfContractRchStartMatchmaker: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setEndOfContractRchDefaults(dl);
                this._setStartMatchmakerInteractionDefaults(dl);
                this._setPageInteractionEvent(dl).write();
            };
            this.track('interaction', updateDataLayer);
        },
    };

    // My profile

    private _setProfileDefaults(dl: DataLayer): DataLayer {
        dl.set('pageName', 'Profile');
        return dl;
    }

    public profile = {
        //As values for section use: Identification, Address, Contact, Consent settings, Dealer

        // My profile.view
        onProfile: (defaults: Defaults, marketingPreferences?: MarketingPreferences): void => {
            const vwfsPreferences = marketingPreferences?.vwfs;
            const bpPreferences = marketingPreferences?.businessPartners;
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setProfileDefaults(dl).set('viewChange', 'Profile');
                this._setPageViewEvent(dl).write();

                if (vwfsPreferences?.post !== undefined || bpPreferences?.post !== undefined)
                    dl.set(
                        'privacyStatementPostalAds',
                        getDataPrivacyStatementValue(DataPrivacyStatements.POST, marketingPreferences),
                    );
                if (vwfsPreferences?.phone !== undefined || bpPreferences?.phone !== undefined)
                    dl.set(
                        'privacyStatementPhoneAds',
                        getDataPrivacyStatementValue(DataPrivacyStatements.PHONE, marketingPreferences),
                    );
                if (vwfsPreferences?.sms !== undefined || bpPreferences?.sms !== undefined)
                    dl.set(
                        'privacyStatementElectronicAds',
                        getDataPrivacyStatementValue(DataPrivacyStatements.SMS, marketingPreferences),
                    );
                if (vwfsPreferences?.email !== undefined || bpPreferences?.email !== undefined)
                    dl.set(
                        'privacyStatementMailAds',
                        getDataPrivacyStatementValue(DataPrivacyStatements.EMAIL, marketingPreferences),
                    );

                dl.write();
            };
            this.track('page', updateDataLayer);
        },

        // My profile.edit
        onEditProfile: (section: string, defaults: Defaults, sectionType?: string): void => {
            const updateDataLayer = () => {
                const fullSectionName = !!sectionType ? `${section} ${sectionType}` : section;
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setProfileDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Profile edit')
                    .set('viewChange', fullSectionName)
                    .set('formType', 'Profile')
                    .set('formName', fullSectionName)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // My profile.view.section
        onEditProfileSectionDisplayed: (section: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setProfileDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Profile edit section displayed')
                    .set('viewChange', section)
                    .set('formType', 'Profile')
                    .set('formName', section)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // My profile.startTyping
        onEditProfileTypedIn: (section: string, defaults: Defaults, sectionType?: string): void => {
            const updateDataLayer = () => {
                const fullSectionName = !!sectionType ? `${section} ${sectionType}` : section;
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setProfileDefaults(dl)
                    .set('linkInformation', 'Profile edit started')
                    .set('viewChange', fullSectionName)
                    .set('eventType', 'Form')
                    .set('eventAction', 'Start typing')
                    .set('formType', 'Profile')
                    .set('formName', fullSectionName)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // My profile.abort
        onEditProfileCancel: (section: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setProfileDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('viewChange', section)
                    .set('linkInformation', 'Profile edit cancelled')
                    .set('formType', 'Profile')
                    .set('formName', section)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // My profile.error
        onEditProfileValidationError: (
            fieldErrors: string,
            section: string,
            defaults: Defaults,
            sectionType?: string,
        ): void => {
            const updateDataLayer = () => {
                const fullSectionName = !!sectionType ? `${section} ${sectionType}` : section;
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setProfileDefaults(dl)
                    .set('linkInformation', 'Profile edit - form error')
                    .set('viewChange', `${fullSectionName} input error`)
                    .set('eventType', 'Form')
                    .set('eventAction', 'Input error')
                    .set('formType', 'Profile')
                    .set('formName', fullSectionName)
                    .set('formErrorFields', fieldErrors)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // My profile.failed
        onEditProfileSubmitFailed: (section: string, defaults: Defaults, sectionType?: string): void => {
            const updateDataLayer = () => {
                const fullSectionName = !!sectionType ? `${section} ${sectionType}` : section;
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setProfileDefaults(dl)
                    .set('linkInformation', 'Profile edit - change rejected')
                    .set('viewChange', `${fullSectionName} edit failed`)
                    .set('eventType', 'interAction')
                    .set('eventAction', 'Failed')
                    .set('formType', 'Profile')
                    .set('formName', fullSectionName)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // My profile.accordion.areYouSure
        onEditProfileConfirmation: (section: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setProfileDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Profile edit - confirmation requested')
                    .set('viewChange', `${section} edit confirmation`)
                    .set('formType', 'Profile')
                    .set('formName', section)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // My profile.accordion.areYouSure.abort
        onEditProfileConfirmationCancel: (section: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setProfileDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('viewChange', `${section} edit confirmation`)
                    .set('linkInformation', 'Edit confirmation cancelled')
                    .set('formType', 'Profile')
                    .set('formName', section)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Not specified in the new documentation
        onEditProfileDownload: (section: string, documentType: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setProfileDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('viewChange', section)
                    .set('linkInformation', `Download ${documentType}`)
                    .write();
            };
            this.track('download', updateDataLayer);
        },

        // My profile.accordion.success
        onEditProfileSuccess: (
            section: string,
            defaults: Defaults,
            sectionType?: string,
            electronicInvoicesStatus?: string,
            marketingPreference?: MarketingPreference,
        ): void => {
            const updateDataLayer = () => {
                const fullSectionName = !!sectionType ? `${section} ${sectionType}` : section;
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setProfileDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('viewChange', `${fullSectionName} edit success`)
                    .set('formType', 'Profile')
                    .set('formName', fullSectionName);
                if (!!electronicInvoicesStatus) {
                    dl.set('linkInformation', `Electronic invoices: ${electronicInvoicesStatus}`);
                } else {
                    dl.set('linkInformation', 'Edit success');
                }

                if (marketingPreference?.post !== undefined)
                    dl.set('privacyStatementPostalAds', marketingPreference.post);
                if (marketingPreference?.phone !== undefined)
                    dl.set('privacyStatementPhoneAds', marketingPreference.phone);
                if (marketingPreference?.sms !== undefined)
                    dl.set('privacyStatementElectronicAds', marketingPreference.sms);
                if (marketingPreference?.email !== undefined)
                    dl.set('privacyStatementMailAds', marketingPreference.email);

                dl.write();
            };
            this.track('interaction', updateDataLayer);
        },

        // My profile.documentUpload
        onEditProfileDocumentUpload: (section: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setProfileDefaults(dl);
                this._setPageViewEvent(dl)
                    .set('formName', section)
                    .set('formType', 'Profile')
                    .set('viewChange', 'Document upload')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // My profile.documentUpload.failed
        onEditProfileDocumentUploadFailed: (section: string, error: ErrorTracking, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setProfileDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('viewChange', 'Document upload')
                    .set('linkInformation', 'Document upload failed')
                    .set('formType', 'Profile')
                    .set('formName', section)
                    .set('errorMessage', error.errorMessage)
                    .set('errorCode', error.errorCode)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // My profile.accountDelete
        onDeleteAccountSuccess: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setProfileDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('viewChange', 'Delete account')
                    .set('linkInformation', 'Delete account request')
                    .set('formType', 'Profile')
                    .set('formName', 'Account')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // My profile.accountDelete.areYouSure
        onConfirmDeleteAccount: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setProfileDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('viewChange', 'Security question delete account')
                    .set('formType', 'Profile')
                    .set('formName', 'Account')
                    .set('linkInformation', 'Security question delete account')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // My profile.accountDelete.success
        onConfirmDeleteAccountSuccess: (defaults: Defaults, noDelay = true): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setProfileDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('viewChange', 'Security question delete account')
                    .set('formType', 'Profile')
                    .set('formName', 'Account')
                    .set('linkInformation', 'Delete account confirmation')
                    .write();
            };
            this.track('interaction', updateDataLayer, undefined, noDelay);
        },

        // My profile.dealer.call
        onCallDealerClicked: (defaults: Defaults, noDelay = true): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setProfileDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('viewChange', 'Dealer')
                    .set('formType', 'Profile')
                    .set('formName', 'Dealer')
                    .set('linkInformation', 'Dealer phone number clicked')
                    .write();
            };
            this.track('interaction', updateDataLayer, undefined, noDelay);
        },

        // My profile.dealer.request
        onCallDealerRequest: (defaults: Defaults, noDelay = true): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setProfileDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('viewChange', 'Dealer')
                    .set('formType', 'Profile')
                    .set('formName', 'Dealer')
                    .set('linkInformation', 'Send a request')
                    .write();
            };
            this.track('interaction', updateDataLayer, undefined, noDelay);
        },
    };

    //Motor property tax
    private _setPropertyTaxDefaults(dl: DataLayer): DataLayer {
        dl.set('pageName', 'Motor property tax');
        return dl;
    }

    public motorPropertyTax = {
        //Motor property tax.view
        onMotorTax: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl).set('viewChange', 'Motor property tax');
                this._setPropertyTaxDefaults(dl).write();
            };
            this.track('page', updateDataLayer);
        },

        //Motor property tax.download
        onMotorTaxDownload: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPropertyTaxDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('eventType', 'Download')
                    .set('eventAction', 'Request')
                    .set('linkInformation', 'Download motor property tax')
                    .write();
            };
            this.track('download', updateDataLayer);
        },
    };

    // Dealer Request

    private _setDealerDefaults(dl: DataLayer): DataLayer {
        dl.set('pageName', 'Dealer request');
        return dl;
    }

    public dealerRequest = {
        // Dealer Request.view
        onDealerRequest: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl).set('viewChange', 'Dealer request');
                this._setDealerDefaults(dl).write();
            };
            this.track('page', updateDataLayer);
        },

        // Dealer Request.success
        onDealerRequestSuccess: (defaults: Defaults, fieldValues: string): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setDealerDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('viewChange', 'Submit successful')
                    .set('formType', 'Contact')
                    .set('formName', 'Dealer request')
                    .set('linkInformation', 'Dealer request submit successful')
                    .set('formFieldValues', fieldValues || '')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Dealer Request.failed
        onDealerRequestFailed: (defaults: Defaults, fieldValues: string, error?: ErrorTracking): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setDealerDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('viewChange', 'Submit failed')
                    .set('formType', 'Contact')
                    .set('formName', 'Dealer request')
                    .set('linkInformation', 'Dealer request submit failed')
                    .set('formFieldValues', fieldValues || '')
                    .set('errorCode', error?.errorCode)
                    .set('errorMessage', error?.errorMessage)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },
    };

    // Dealer card

    public dealerCard = {
        // Dealer Card.choose
        onChooseDealer: (defaults: Defaults, currentPage: string): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl)
                    .set('viewChange', 'Dealer card')
                    .set('linkInformation', 'Choose your dealer')
                    .set('pageName', currentPage)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Dealer Card.change
        onChangeDealer: (defaults: Defaults, currentPage: string): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl)
                    .set('viewChange', 'Dealer card')
                    .set('linkInformation', 'Change your dealer')
                    .set('pageName', currentPage)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Dealer Card.request
        onRequestDealer: (defaults: Defaults, currentPage: string): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl)
                    .set('viewChange', 'Dealer card')
                    .set('linkInformation', 'Send a request')
                    .set('pageName', currentPage)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },
    };

    // Dealer Selection
    private _setDealerSelectionDefaults(dl: DataLayer): DataLayer {
        dl.set('pageName', 'Dealer selection');
        return dl;
    }

    public dealerSelection = {
        // Dealer Selection.view
        onDealerSelection: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl).set('viewChange', 'Dealer selection');
                this._setDealerSelectionDefaults(dl).write();
            };
            this.track('page', updateDataLayer);
        },

        // Dealer Selection.save
        onDealerSelectionSave: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setDealerSelectionDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Dealer save clicked')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Dealer Selection.dealer.call
        onDealerSelectionCall: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setDealerSelectionDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Dealer phone number clicked')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },
    };

    // Postbox

    private _setPostboxDefaults(dl: DataLayer): DataLayer {
        dl.set('pageName', 'Postbox');
        return dl;
    }

    public postbox = {
        // Postbox.view
        onPostbox: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl).set('viewChange', 'Postbox');
                this._setPostboxDefaults(dl).write();
            };
            this.track('page', updateDataLayer);
        },

        // Postbox.download
        onPostboxDownload: (documentTypeEN: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPostboxDefaults(dl)
                    .set('viewChange', 'Postbox')
                    .set('eventType', 'Download')
                    .set('eventAction', 'Request')
                    .set('linkInformation', `Download ${documentTypeEN}`)
                    .write();
            };
            this.track('download', updateDataLayer);
        },

        // Postbox.technicalError
        onPostboxDownloadError: (documentTypeEN: string, error: ErrorTracking, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPostboxDefaults(dl)
                    .set('viewChange', 'Download error')
                    .set('eventType', 'Error')
                    .set('eventAction', 'Technical error')
                    .set('linkInformation', `Download ${documentTypeEN}`)
                    .set('errorCode', error.errorCode)
                    .set('errorMessage', error.errorMessage)
                    .set('errorCausingUrl', error.errorCausingUrl)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Postbox.signUp.view
        onPostboxInvoiceSignUpView: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl);
                this._setPostboxDefaults(dl)
                    .set('viewChange', 'Electronic invoice sign up')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // Postbox.signUp.cancel
        onPostboxInvoiceSignUpCancel: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPostboxDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('viewChange', 'Electronic invoice sign up')
                    .set('linkInformation', 'Electronic invoice sign up canceled')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Postbox.signUp.success
        onPostboxInvoiceSignUpConfirm: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPostboxDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('viewChange', 'Electronic invoice sign up')
                    .set('linkInformation', 'Electronic invoice sign up successful')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Postbox.signUp.prohibited
        onPostboxDownloadErrorInvoiceDeactivated: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPostboxDefaults(dl)
                    .set('viewChange', 'Download invoice prohibited')
                    .set('eventType', 'Access')
                    .set('eventAction', 'Denied')
                    .set('linkInformation', 'Download invoice prohibited')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },
    };

    // FAQ

    private _setFAQDefaults(dl: DataLayer): DataLayer {
        dl.set('pageName', 'FAQ');
        return dl;
    }

    public faq = {
        // FAQ.view
        onFaq: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setFAQDefaults(dl);
                this._setPageViewEvent(dl)
                    .set('viewChange', 'FAQ')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // FAQ.accordion.opened
        onFaqQuestion: (question: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setFAQDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('viewChange', question)
                    .set('linkInformation', 'FAQ question clicked')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },
    };

    //Financial aid
    private _setRequestAdditionalHelpDefaults(dl: DataLayer): DataLayer {
        dl.set('pageName', 'Financial aid');
        return dl;
    }

    public requestAdditionalHelp = {
        //Financial aid.view
        onRequestAdditionalHelpSituation: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setRequestAdditionalHelpDefaults(dl);
                this._setPageViewEvent(dl)
                    .set('viewChange', 'Current situation')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        //Financial aid.view.financialDetails
        onRequestAdditionalHelpIncomeAndExpenditure: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setRequestAdditionalHelpDefaults(dl);
                this._setPageViewEvent(dl)
                    .set('viewChange', 'Financial details')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        //Financial aid.Breathing space
        onRequestAdditionalHelpBreathingSpace: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setRequestAdditionalHelpDefaults(dl);
                this._setPageViewEvent(dl)
                    .set('viewChange', 'Payment interruption')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        //Financial aid.Payment by instalments
        onRequestAdditionalHelpArrangementToPay: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setRequestAdditionalHelpDefaults(dl);
                this._setPageViewEvent(dl)
                    .set('viewChange', 'Payment by instalments')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        //Financial aid.Payment delay
        onRequestAdditionalHelpPromiseToPay: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setRequestAdditionalHelpDefaults(dl);
                this._setPageViewEvent(dl)
                    .set('viewChange', 'Payment delay')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        //Financial aid.End agreement
        requestAdditionalHelpEndAgreement: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setRequestAdditionalHelpDefaults(dl);
                this._setPageViewEvent(dl)
                    .set('viewChange', 'End agreement')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        //Financial aid.view.financialDetails.Print
        onRequestAdditionalHelpIncomeAndExpenditurePrint: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setRequestAdditionalHelpDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Print financial details')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        //Financial aid.success
        onRequestAdditionalHelpSubmitSuccess: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setRequestAdditionalHelpDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Request financial aid - Success')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        //Financial aid.failed
        onRequestAdditionalHelpSubmitFailed: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setRequestAdditionalHelpDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Request financial aid - Failed')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        //Financial aid.Button Back
        onRequestAdditionalHelpBack: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setRequestAdditionalHelpDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', 'Back')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },
    };

    //Contact

    private _setContactDefaults(dl: DataLayer): DataLayer {
        dl.set('pageName', 'Contact').set('viewChange', 'Contact');
        return dl;
    }

    public contact = {
        //Contact.view
        onContact: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setContactDefaults(dl);
                this._setPageViewEvent(dl).write();
            };
            this.track('page', updateDataLayer);
        },

        //Contact.success
        onContactSubmitSuccess: (reason: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setContactDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', `Request contact - Success (${reason})`)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        //Contact.failed
        onContactSubmitFailed: (reason: string, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setContactDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('linkInformation', `Request contact - Failed (${reason})`)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },
    };

    //Mandatory pages

    public legalNotice = {
        // Mandatory pages.Legal.view
        onLegalNotice: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl)
                    .set('pageName', 'Legal')
                    .set('viewChange', 'Legal')
                    .write();
            };
            this.track('page', updateDataLayer);
        },
    };

    public privacyPolicy = {
        // Mandatory pages.Privacy policy.view
        onPrivacyPolicy: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl)
                    .set('pageName', 'Privacy policy')
                    .write();
            };
            this.track('page', updateDataLayer);
        },
    };

    public cookiePolicy = {
        // Mandatory pages.Cookie policy.view
        onCookiePolicy: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl)
                    .set('pageName', 'Cookie policy')
                    .write();
            };
            this.track('page', updateDataLayer);
        },
    };

    public modernSlaveryStatement = {
        // Mandatory pages.Modern slavery statement.view
        onModernSlaveryStatement: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl)
                    .set('pageName', 'Modern slavery statement')
                    .write();
            };
            this.track('page', updateDataLayer);
        },
    };

    public thirdPartyLicenses = {
        // Mandatory pages.Third party licences.view
        onThirdPartyLicenses: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl)
                    .set('pageName', 'Third party licences')
                    .write();
            };
            this.track('page', updateDataLayer);
        },
    };

    // Error pages

    public error = {
        // Error pages.API not available.view
        onApiError: (defaults: Defaults, url: string, errorMessage = 'Connection Problem'): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                dl.set('pageName', 'API Error')
                    .set('eventType', 'API')
                    .set('eventAction', 'Error')
                    .set('errorMessage', errorMessage)
                    .set('errorCausingUrl', url)
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // Error pages.Error: Unauthorized (401).view
        onNotAuthorizedError: (defaults: Defaults, url: string): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                dl.set('pageName', 'Error: Unauthorized (401)')
                    .set('viewChange', 'Error: Unauthorized (401)')
                    .set('eventType', 'Login')
                    .set('eventAction', 'Unauthorized')
                    .set('errorCode', 401)
                    .set('errorMessage', 'Unauthorized')
                    .set('errorCausingUrl', url)
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // Error pages.Error: Access denied (403).view
        onAccessDeniedError: (defaults: Defaults, url: string): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                dl.set('pageName', 'Error: Access denied (403)')
                    .set('viewChange', 'Error: Access denied (403)')
                    .set('eventType', 'Access')
                    .set('eventAction', 'Denied')
                    .set('errorCode', 403)
                    .set('errorMessage', 'Access denied')
                    .set('errorCausingUrl', url)
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // Error pages.Error: Page not found (404).view
        onPageNotFoundError: (defaults: Defaults, url: string): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                dl.set('pageName', 'Error: Page not found (404)')
                    .set('viewChange', 'Error: Page not found (404)')
                    .set('eventType', 'Error')
                    .set('eventAction', 'Page not found')
                    .set('errorCode', 404)
                    .set('errorMessage', 'Page not found')
                    .set('errorCausingUrl', url)
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // Error pages.Error: Internal server error (500).view
        onConnectionProblemError: (defaults: Defaults, url: string, errorMessage = 'Connection Problem'): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                dl.set('pageName', 'Error: Internal server error (500)')
                    .set('viewChange', 'Error: Internal server error (500)')
                    .set('eventType', 'Error')
                    .set('eventAction', 'Technical error')
                    .set('errorCode', 500)
                    .set('errorMessage', errorMessage)
                    .set('errorCausingUrl', url)
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // Error pages.Error: Auto logout.view
        onAutoLogout: (defaults: Defaults, noDelay = true): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl)
                    .set('pageName', 'Auto logout')
                    .set('viewChange', 'Auto logout')
                    .write();
            };
            this.track('page', updateDataLayer, undefined, noDelay);
        },
    };

    // Make a request

    private _setRequestsDefaults(dl: DataLayer): DataLayer {
        dl.set('pageName', 'Requests');
        return dl;
    }

    public requests = {
        // Make a request.view
        onRequests: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setRequestsDefaults(dl).set('viewChange', 'Requests');
                this._setPageViewEvent(dl).write();
            };
            this.track('page', updateDataLayer);
        },

        // Make a request.success
        onRequestSuccess: (defaults: Defaults, fieldValues: string): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl);
                this._setRequestsDefaults(dl)
                    .set('viewChange', 'Submit successful')
                    .set('linkInformation', 'Request submit successful')
                    .set('formType', 'Contact')
                    .set('formName', 'Request')
                    .set('formFieldValues', fieldValues || '')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // Make a request.failed
        onRequestFailure: (defaults: Defaults, fieldValues?: string): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl);
                this._setRequestsDefaults(dl)
                    .set('viewChange', 'Submit failed')
                    .set('linkInformation', 'Request submit failed')
                    .set('formType', 'Contact')
                    .set('formName', 'Request')
                    .set('formFieldValues', fieldValues || '')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },
    };

    // Report Damage

    public reportDamage = {
        // Report Damage (RD).view
        onReportDamage: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl)
                    .set('pageName', 'Report damage (RD)')
                    .set('viewChange', 'Report damage (RD)')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // (RD) Repair approval.view
        onRepairApproval: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl)
                    .set('pageName', '(RD) Repair approval')
                    .set('viewChange', '(RD) Repair approval')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // (RD) Repair approval.success
        onRepairApprovalSuccess: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl)
                    .set('pageName', '(RD) Repair approval')
                    .set('viewChange', '(RD) Repair approval success')
                    .set('linkInformation', 'Repair approval success')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // (RD) Repair approval.failed
        onRepairApprovalFailure: (error: ErrorTracking, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl)
                    .set('pageName', '(RD) Repair approval')
                    .set('viewChange', '(RD) Repair approval failed')
                    .set('linkInformation', 'Repair approval failed')
                    .set('errorCode', error.errorCode)
                    .set('errorMessage', error.errorMessage)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // (RD) Export Report.view
        onExpertReport: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl)
                    .set('pageName', '(RD) Expert report')
                    .set('viewChange', '(RD) Expert report')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // (RD) Export Report.success
        onExpertReportSuccess: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl)
                    .set('pageName', '(RD) Expert report')
                    .set('viewChange', '(RD) Expert report success')
                    .set('linkInformation', 'Expert report success')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // (RD) Export Report.failed
        onExpertReportFailure: (error: ErrorTracking, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl)
                    .set('pageName', '(RD) Expert report')
                    .set('viewChange', '(RD) Expert report failed')
                    .set('linkInformation', 'Expert report failed')
                    .set('errorCode', error.errorCode)
                    .set('errorMessage', error.errorMessage)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // (RD) Export Report.download
        onExpertReportInsuranceClaimDownload: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl)
                    .set('pageName', '(RD) Expert report')
                    .set('viewChange', '(RD) Expert report')
                    .set('linkInformation', 'Expert report download')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // (RD) Total loss.view
        onTotalLoss: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl)
                    .set('pageName', '(RD) Total loss')
                    .set('viewChange', '(RD) Total loss')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // (RD) Total loss.success
        onTotalLossSuccess: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl)
                    .set('pageName', '(RD) Total loss')
                    .set('viewChange', '(RD) Total loss success')
                    .set('linkInformation', 'Total loss success')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // (RD) Total loss.failed
        onTotalLossFailure: (error: ErrorTracking, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl)
                    .set('pageName', '(RD) Total loss')
                    .set('viewChange', '(RD) Total loss failed')
                    .set('linkInformation', 'Total loss failed')
                    .set('errorCode', error.errorCode)
                    .set('errorMessage', error.errorMessage)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // (RD) Total loss.download
        onTotalLossInsuranceClaimDownload: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl)
                    .set('pageName', '(RD) Total loss')
                    .set('viewChange', '(RD) Total loss')
                    .set('linkInformation', 'Total loss download')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },
        // (RD) Total loss.earlySettlementRedirect
        onTotalLossEarlySettlementRedirect: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl)
                    .set('pageName', '(RD) Total loss')
                    .set('viewChange', '(RD) Total loss redirect early settlement')
                    .set('linkInformation', 'Total loss redirect early settlement performed')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // (RD) Theft.view
        onTheft: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageViewEvent(dl)
                    .set('pageName', '(RD) Theft report')
                    .set('viewChange', '(RD) Theft report')
                    .write();
            };
            this.track('page', updateDataLayer);
        },

        // (RD) Theft.success
        onTheftSuccess: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl)
                    .set('pageName', '(RD) Theft report')
                    .set('viewChange', '(RD) Theft report success')
                    .set('linkInformation', 'Theft report success')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // (RD) Theft.failed
        onTheftFailure: (error: ErrorTracking, defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl)
                    .set('pageName', '(RD) Theft report')
                    .set('viewChange', '(RD) Theft report failed')
                    .set('linkInformation', 'Theft report failed')
                    .set('errorCode', error.errorCode)
                    .set('errorMessage', error.errorMessage)
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // (RD) Theft.download
        onTheftInsuranceClaimDownload: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl)
                    .set('pageName', '(RD) Theft report')
                    .set('viewChange', '(RD) Theft report')
                    .set('linkInformation', 'Theft report download')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },

        // (RD) Theft.requestRegistrationCertRedirect
        onTheftRequestRegistrationCertRedirect: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setPageInteractionEvent(dl)
                    .set('pageName', '(RD) Theft report')
                    .set('viewChange', '(RD) Theft report success')
                    .set('linkInformation', 'Theft redirect request registration cert')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },
    };

    // Matchmaker

    _setMatchmakerLandingPageDefaults(dl: DataLayer): DataLayer {
        dl.set('pageName', 'CRM Match maker landing page').set('viewChange', 'CRM Match maker landing page');
        return dl;
    }

    _setStartMatchmakerPageDefaults(dl: DataLayer): DataLayer {
        dl.set('pageName', 'Match maker info page').set('viewChange', 'Match maker info page');
        return dl;
    }

    _setStartMatchmakerInteractionDefaults(dl: DataLayer): DataLayer {
        dl.set('eventType', 'interAction')
            .set('eventAction', 'Success')
            .set('linkInformation', 'Go to Match maker info page');
        return dl;
    }

    public matchmaker = {
        // UseOurMatchmaker.view
        onUseOurMatchmaker: (defaults: Defaults, loggedOut = false): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setMatchmakerLandingPageDefaults(dl);
                this._setPageViewEvent(dl);
                // loggedOut variable is needed because if user logged out he is not starting the journey but _firstVist
                // variable was reset while redirecting to other application for login
                if (this._firstVisit && !loggedOut) {
                    this.dataLayer.set('additionalEvent', 'Product journey', 'Start');
                    this._firstVisit = false;
                }
                this.dataLayer.write();
            };
            this.track('page', updateDataLayer);
        },

        onMatchmakerLogin: (defaults: Defaults, noDelay = true): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setMatchmakerLandingPageDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('eventType', 'Login')
                    .set('eventAction', 'Start')
                    .set('linkInformation', 'Login start')
                    .write();
            };
            this.track('interaction', updateDataLayer, undefined, noDelay);
        },

        onStartMatchmaker: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setStartMatchmakerPageDefaults(dl);
                this._setPageViewEvent(dl).write();
            };
            this.track('page', updateDataLayer);
        },

        onContinueToMatchmaker: (defaults: Defaults): void => {
            const updateDataLayer = () => {
                const dl = this._setDefaults(this.dataLayer.clone(), defaults);
                this._setStartMatchmakerPageDefaults(dl);
                this._setPageInteractionEvent(dl)
                    .set('eventType', 'interAction')
                    .set('eventAction', 'Success')
                    .set('linkInformation', 'Go to match maker')
                    .write();
            };
            this.track('interaction', updateDataLayer);
        },
    };
}
