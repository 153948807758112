import { useHistory } from 'react-router-dom';

import { navigateTo } from '../utilities';

type useNavigationReturnType = (url: string | undefined) => void;

export function useNavigation(): useNavigationReturnType {
    const history = useHistory();
    return (url: string | undefined): void => {
        navigateTo(history, url);
    };
}
