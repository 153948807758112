import React from 'react';
import { Layout } from '@vwfs-bronson/bronson-react';
import { Skeleton } from '../Skeleton';
import { allowedFractions } from 'components/contracts';

export type MyDocumentsLoadingPlaceholderProps = { summaryElements?: number };

export const MyDocumentsLoadingPlaceholder: React.FC<MyDocumentsLoadingPlaceholderProps> = ({
    summaryElements = 3,
}) => (
    <>
        <Skeleton height={60} className="u-mb" />
        <Skeleton height={30} className="u-mb-small" />
        <Layout className="u-mb-large">
            {Array.from(Array(summaryElements).keys()).map(key => (
                <Layout.Item key={key} default={`1/${summaryElements}` as allowedFractions} s={'1/1'}>
                    <Skeleton height={60} />
                </Layout.Item>
            ))}
        </Layout>
        <Skeleton height={40} className="u-mb-small" />
        <Skeleton height={35} />
        <Skeleton count={4} height={50} />
    </>
);
