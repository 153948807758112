import React, { useState } from 'react';

import { CookieWarningUi } from './CookieWarningUi';

type CookieWarningProps = {
    warningText: string | React.ReactNode;
    secondLineText?: string | React.ReactNode;
    linkText: string | React.ReactNode;
    acceptLabel: string | React.ReactNode;
    cookiePolicyLink: string;
    cookiePolicyLinkClickHandler?: () => void;
};

export const CookieWarning: React.FC<CookieWarningProps> = ({
    warningText,
    secondLineText,
    acceptLabel,
    cookiePolicyLink,
    linkText,
    cookiePolicyLinkClickHandler,
}) => {
    const [accepted, setAccepted] = useState(localStorage.cookiesAccepted || false);

    function setCookiesAccepted() {
        localStorage.cookiesAccepted = 'true';
        setAccepted('true');
    }

    return (
        <CookieWarningUi
            warningText={warningText}
            secondLineText={secondLineText}
            linkText={linkText}
            accepted={accepted === 'true'}
            acceptLabel={acceptLabel}
            setCookiesAccepted={setCookiesAccepted}
            cookiePolicyLink={cookiePolicyLink}
            cookiePolicyLinkClickHandler={cookiePolicyLinkClickHandler}
        />
    );
};

CookieWarning.displayName = 'CookieWarning';
