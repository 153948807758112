import { SiteNav } from '@vwfs-bronson/bronson-react';
import React from 'react';

export type LanguageDescription = {
    /**
     * The locale code of the language (e.g. "es")
     */
    locale: string;
    /**
     * The text to show in the list of selectable options (e.g. "Spanish (ES)")
     */
    dropdownLabel: string;
    /**
     * The text to show when this language is selected by the user (e.g. "ES")
     */
    activeLabel: string;
};

export type LanguageDescriptionList = Array<LanguageDescription>;

export type LanguageSwitcherProps = {
    /**
     * The list of selectable languages
     */
    selectableLanguages: LanguageDescriptionList;
    /**
     * The currently selected language
     */
    currentLanguage: LanguageDescription;
    /**
     * Callback on each language selection
     * @param newLocale New locale
     * @param currentLocale Current locale
     */
    onClick?: (newLocale: string, currentLocale: string) => void;
};

export const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
    currentLanguage,
    selectableLanguages,
    onClick,
}) => (
    <SiteNav.Item label={currentLanguage.activeLabel} icon reversed language topAtBreakpoint>
        {selectableLanguages.map(language => (
            <SiteNav.Item.DropdownItem
                key={language.locale}
                onClick={() => onClick?.(language.locale, currentLanguage.locale)}
            >
                {language.dropdownLabel}
            </SiteNav.Item.DropdownItem>
        ))}
    </SiteNav.Item>
);
