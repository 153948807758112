import React, { FC, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useAuthentication } from './use-authentication';

export type AutoLogoutProps = {
    idleTimeInMinutes: number;
    redirectUri: string;
    debounce?: number;
};

export const AutoLogout: FC<AutoLogoutProps> = ({ redirectUri, idleTimeInMinutes, debounce = 1000, children }) => {
    const timeout = 1000 * 60 * idleTimeInMinutes;
    const { isAuthenticated, logout } = useAuthentication();
    const [isAutoLogoutPolling, setAutoLogoutPolling] = useState(false);

    useEffect(() => {
        const interval = setInterval(async () => {
            if (isAuthenticated && isAutoLogoutPolling) {
                const lastActive = Number(localStorage.getItem('last-active'));
                if (Date.now() - lastActive > timeout) {
                    await logout({ redirectUri });
                }
            }
        }, debounce);
        return () => clearInterval(interval);
    }, [isAutoLogoutPolling, debounce]);

    useIdleTimer({
        timeout,
        debounce,
        onIdle: () => {
            if (isAuthenticated) {
                setAutoLogoutPolling(true);
            }
        },
        onActive: () => {
            if (isAuthenticated) {
                setAutoLogoutPolling(false);
            }
        },
        onAction: () => {
            if (isAuthenticated) {
                localStorage.setItem('last-active', Date.now().toString());
            }
        },
    });

    return <>{children}</>;
};
