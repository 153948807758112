import { ContentSection } from '@vwfs-bronson/bronson-react';
import React from 'react';

import { BackToTop } from '../footer/BackToTop';

export type MasterPageProps = {
    cookieWarning?: React.ReactNode;
    navigationBar?: React.ReactNode;
    iconFooter?: React.ReactNode;
    legalFooter?: React.ReactNode;
    label?: string;
};

export const MasterPage: React.FC<MasterPageProps> = ({
    cookieWarning,
    navigationBar,
    iconFooter,
    legalFooter,
    label,
    children,
}) => (
    <div className="o-root">
        {cookieWarning}
        {navigationBar}
        <main className="o-main">{children}</main>
        {label && <BackToTop label={label} />}
        {iconFooter && (
            <ContentSection pageWrapSize="medium" altBackground={true}>
                {iconFooter}
            </ContentSection>
        )}
        {legalFooter}
    </div>
);
