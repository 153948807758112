import React from 'react';
import { Layout } from '@vwfs-bronson/bronson-react';
import { Skeleton } from '../Skeleton';
import { allowedFractions } from 'components/contracts';

export const IconFooterLoadingPlaceholder: React.FC<{ summaryElements?: number }> = ({ summaryElements = 3 }) => (
    <Layout className="row">
        {Array.from(Array(summaryElements).keys()).map(key => (
            <Layout.Item key={key} default={`1/${summaryElements}` as allowedFractions} s={'1/1'}>
                <Skeleton height={170} />
            </Layout.Item>
        ))}
    </Layout>
);
