import _ from 'lodash';

export type DataLayerType = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
};

//TODO: Fill all data layer fields, e.g. vehicle-data, contract-data
export class DataLayer {
    private _template: DataLayerType;

    private _dataLayer: DataLayerType;

    constructor(market: string, version: string, releaseDate: string) {
        this._template = {
            core: {
                /*
                @Developers: Constant value.
                Acceptance criteria:
                - Do not change
                */
                dataLayerVersion: '2.4',
                pageInfo: {
                    /*
                    The values for pagename are defined in tracking specification
                    document.
                    */
                    pageName: null,

                    /*
                    @Developers: Constant value.
                    Acceptance criteria:
                    - Do not change
                    */
                    intendedCustomerDeviceType: 'all',

                    /*
                    @Developers: The version of application.
                    */
                    version,

                    /*
                    @Developers: Constant value (format YYYY-MM-DD): Date of rollout.
                    */
                    releaseDate,

                    /*
                    @Developers: Current display language.
                    Type: Enum
                    ISO-639-2; lower case two letter code
                    Hint: Visitor can change the language while his visit (e.g. in
                    Switzerland).
                    */
                    language: null,

                    /*
                    Type: Enum
                    The country name of the market this site is serving
                    (e.g. DE: W&I -> DE, UK: S&I -> UK).
                    ISO-3166-1-alpha-2; upper case two letter code
                    */
                    market,

                    /*
                    @Developers: Constant value.
                    */
                    publisher: 'DU',
                },
                category: {
                    /*
                    @Developers: Constant value.
                    */
                    primaryCategory: 'customerportal',

                    /*
                    @Developers: Constant value.
                    Acceptance criteria:
                    - Do not change
                    */
                    siteType: 'standalone',

                    /*
                    @Developers: Constant value.
                    Acceptance criteria:
                    - Do not change
                    */
                    maturityLevel: 'Portal',
                },
                attributes: {
                    /*
                    @Developers: Constant value.
                    Acceptance criteria:
                    - Do not change
                    */
                    journeyType: 'after-sales-journey',

                    /*
                    Refers to the branding of the site.

                    Never include design versions here: This should not be changed to
                    vw6 when a new design is released. The brand is still vw. vwcv
                    refers to VW Commercial Vehicles. BFF’s data model supplies vwn,
                    but we expect vwcv.

                    @Developers: Please fill in the correct value here based on the
                    site's branding

                    Type: Enum
                    seat
                    vw
                    skoda
                    vwcv
                    audi
                    */
                    brand: null,
                    /*
                    @Developers:
                    Acceptance criteria:
                    - If the viewChange relates to a selection of a FAQ please out the question text (not the answer text) here

                    */
                    faqText: null,
                },
            },
            /*
            Write here if the customer sees a 404, 401, etc. page
            */
            error: {
                //Error code of application exceptions or caught errors
                errorCode: null,

                //Error message for caught errors with available message
                errorMessage: null,

                //Full URL which caused the error
                errorCausingURL: null,
            },
            universally: {
                /* The number of contracts the customer has concluded
                 */
                numberOfProducts: null,
            },
            /*
            Please note that this structure is an array structure. We expect one entry
            for each contract sold. If the customer can bundle his contract (i.e. get
            a leasing contract, a service and maintenance package, and a tire package,
            this array should have three entries.
            */
            product: [
                {
                    /*
                External (contract) ID; at least the brand of the website
                Note: right now it was decided to use transactionId for this field

                Type: String
                */
                    productID: null,

                    /*
                The name of the product from the given list. If you cannot find a
                suitable entry for the product sold in the journey, please reach out
                to the web analytics team. Please do not add any products to this list
                on your own.

                Type: Enum
                AutoCredit
                CarSharingProtection
                ChargeAndFuel
                ClassicCredit
                CreditProtectionInsurance
                LeasingRateInsurance
                DigitalRenewal
                ExtendedWarranty
                FinanceLease
                GuaranteedAssetProtection
                MotorInsurance
                OperateLease
                RentalCarProtection
                ServiceInspection
                ServiceLimitedMaintenance
                TestDriveProtection
                ThirdPartyProtection
                UsedCarWarranty
                TirePackage
                */
                    name: null,

                    /*
                Further sub-categories of the product, e.g. deductibles in an insurance
                contract which the customer can choose
                */
                    productVariants: [],

                    /*
                The main category of the current contract.
                Type: Enum
                Finance
                Insurance
                Leasing
                Service
                Other
                */
                    category: null,
                    attributes: {
                        /*
                    E.g. monthly payment for insurance or leasing contract or an
                    installment. Please provide exact (float) value
                    Type: Float
                    */
                        recurringPayment: null,

                        /*
                    Relates to installment.
                    Type: Enum
                    WEEK
                    TWO-WEEKS
                    MONTH
                    TWO-MONTHS
                    QUARTER
                    SIX-MONTHS
                    YEAR
                    */
                        durationUnit: null,

                        /*
                    Relates to installment. Planned duration of contract; please use
                    value -1 if indefinite
                    Type: Integer
                    */
                        duration: null,

                        /*
                    Only if part of the contract
                    Type: Integer
                    */
                        yearlyMileage: null,

                        /*
                    The maximum agreed excess of the yearly mileage. This is the
                    difference of the actual yearly mileage and the expected yearly
                    mileage (yearlyMileage).
                    Type: Integer
                    */
                        excessMileage: null,

                        /*The unit of yearlyMileage, excessMileage or undrivenMileage.
                    Should always be KILOMETERS in the european region.
                    Type: Enum
                    KILOMETERS,
                    MILES
                    */
                        mileageUnit: null,

                        /*
                    Specifies the type of complete sales process

                    Type: Enum
                    Hybrid sales
                    Online sales
                    Partner sales
                    */
                        typeOfSale: null,

                        /*
                    The start date of contract in focus.
                    Type: Date (YYYY-MM-DD)
                    */
                        startDateOfContract: null,

                        /*
                    The end date of contract in focus.
                    Type: Date (YYYY-MM-DD)
                    */
                        endDateOfContract: null,

                        /*
                    The type of payment.

                    Type: Enum

                    Automatic Bank Transfer
                    Credit Card
                    Direct Deposit
                    E-Wallet
                    Mobile Payment
                    */
                        paymentType: null,

                        /*
                    The date of actual registration. Only if part of the contract.
                    Type: Date (YYYY-MM-DD)
                    This is a particular important value. Do not skip it due to
                    management request!
                    */
                        registrationDateOwner: null,

                        /*
                    If the contract is entitled to deduct input tax.
                    Type: Boolean
                    */
                        preTaxSubstraction: null,

                        /*
                    Single special payment in euro
                    Type: Float
                    */
                        specialPayment: null,

                        /*
                    This is the repayment of the outstanding principal sum made at the
                    end of a loan period, interest only having been paid hitherto.
                    Type: Float
                    */
                        balloonPayment: null,

                        /*
                    The gross loan amount. This value is the total sum of the net loan
                    amount plus all credit costs (including all interests and all
                    fixed costs). It is also the amount which the customer will pay in
                    the end. The currency is €.
                    Type: Float
                    */
                        grossLoanAmount: null,

                        /*
                    The amount of deposit that the customer makes in the context of a
                    financing product. The currency is €.
                    Type: Float
                    */
                        downPaymentAmount: null,

                        /*
                    The nominal interest rate expressed as a factor applied to the net
                    loan amount. It includes only the credit costs that are caused by
                    the nominal interest itself.
                    Type: Float
                    */
                        nominalInterestRate: null,

                        /*
                    The amount of interests caused by the nominalInterestRate over the
                    entire timespan of the credit. It does not include other credit
                    costs than nominal interests. The currency is €.
                    Type: Float
                    */
                        nominalInterestAmount: null,

                        /*
                    Currency used for the product.
                    Type: string
                    */
                        currency: null,
                    },
                },
            ],
            //Aligned with BFF data model!
            vehicleModel: [
                {
                    /*
                The manufacturer of the current vehicle.
                Type: Enum
                audi
                bentley
                cupra
                ducati
                man
                porsche
                scania
                seat
                skoda
                vw
                vwcv
                vwfs
                */
                    manufacturer: null,

                    /*
                The main name of the current car.

                Type: string, for example
                A3
                Kodiaq
                XC60
                Auris
                Golf
                */
                    name: null,

                    /*
                The first subcategory of the current car in manufacturers product
                program.
                Type: string
                For example
                GTI
                */
                    modelVariation: null,

                    /*
                The year when the model has been released.
                Type: string
                Format: YYYY
                */
                    year: null,

                    /*
                The second subcategory of the current car in manufacturers product
                program.
                Type: string
                For example
                Sportline
                Momentum
                Sportback
                Performance
                */
                    modelLine: null,

                    /*
                Long description of the vehicle model
                Type: String
                Example:
                Golf GTI Performance
                */
                    descriptionLong: null,

                    /*
                For tracking of hybrid sales, the sales id which is handed over
                Type: String
                */
                    salesID: null,

                    /*
                Describes the body type of the vehicle.
                Type: Enum
                CONVERTIBLE
                COUPE
                CROSSOVER
                CABRIO
                LUXURY CAR
                SEDAN
                SPORTS CAR
                SUV
                TRUCK
                VAN
                WAGON
                */
                    bodyType: null,

                    /*
                If it is a vehicle with a special warranty (Das Weltauto, TradePort),
                true, otherwise false
                Type: Boolean
                */
                    certifiedPreOwned: null,

                    /*
                The current mileage of the car
                Original value: 15388 km
                Data layer value: 15388
                Type: Integer
                */
                    currentMileage: null,

                    /*
                The unit of the current mileage of the car
                Type: Enum
                KILOMETERS,
                MILES
                */
                    currentMileageUnit: null,

                    /*
                The date when the vehicle has been registered first (In german
                "Tag der Erstzulassung").

                This value is of special importance to be tracked correctly as it
                serves as a measure for customer loyalty. Tracking this value
                correctly is of high importance for our management.
                Type: String
                Format: YYYY-MM-DD
                */
                    initialRegistrationDate: null,

                    /*
                The base price of current vehicle
                Type: Integer
                Unit local currency
                Original value: 15388 PLN
                Data layer value: 15388
                */
                    basePrice_localCurrency: null,

                    /*
                Local currency
                ISO-4217 alphabetic code upper case letters
                Example: PLN
                */
                    currency: null,

                    /*
                The final vehicle price (local currency). The unit is not part of the
                value.
                Type: Integer
                Unit local currency
                Original value: 15388 PLN
                Data layer value: 15388
                */
                    endPrice_localCurrency: null,

                    /*
                The base color of the current vehicle

                Enum:
                Black
                Blue
                Brown
                Green
                Grey
                Red
                Violet
                White
                Yellow
                */
                    colorExterior: null,

                    /*
                The type of usage

                Enum:
                Private
                Business
                Both
                */
                    typeOfUse: null,

                    engine: {
                        /*
                    The engines fuel type of the vehicle

                    Enum:
                    CNG
                    Diesel
                    Electric
                    Hybrid
                    Hydrogen
                    LPG
                    Petrol
                    */
                        fuelType: null,
                    },
                },
            ],
            form: {
                /*
                The main subject of the form. Search forms are handled by the search
                element of this data layer.
                */
                type: null,

                /*
                The meaningful descriptive name of the form in context of the
                current site.
                */
                name: null,

                /*
                If the form is aborted the meaningful name of last touched field.
                */
                lastTouchedField: null,

                /*
                In case of user input errors, a semicolon-separated list of
                meaningful names of all erroneous fields and shortened error messages:

                <field name 1>: <error message 1>;<field name 2>: <error message 2>
                */
                errorFields: null,

                fieldValues: null,
            },
            design: {
                /*
                For responsive design: Thresholds when design changes. E.g. Customer
                has a size of 1500 x 900 -> 1024 x 768 (if this is your threshold)
                Type: string
                Example: 800 x 600
                */
                browserResolutionBreakpoint: null,
            },
            customerData: {
                /*
                Indicates whether the user is currently logged in or not
                Type: Boolean
                */
                loginStatus: null,

                /*
                The user group the customer belongs to. Specified values are:
                - private
                - business
                */
                loggedInUserGroup: null,

                /*
                The year the customer was born.

                Format: YYYY
                */
                yearOfBirth: null,

                /*
                The gender of the customer
                Type: Enum
                MALE
                FEMALE
                */
                gender: null,
            },
            //Aligned with BFF data model!
            CMS: {
                /*
                Internal unique identifier. Should identify a page uniquely, preferred
                a reference to the corresponding page in authoring system. This value
                is set as a CMS generated fixed value.
                */
                pageID: null,
            },
            //For Marketing Hook Page
            dataPrivacyStatement: {
                allowPostalAds: null,
                allowPhoneAds: null,
                allowElectronicAds: null,
                allowMailAds: null,
                allowElectronicInvoices: null,
            },
            /*
            Together with the PO or BA we map our standard events to your journey and
            provide you an annotated version of the journey. Please fill these values
            based on the annotated journey.
            */
            event: [
                {
                    eventInfo: {
                        /*
                    The type of events which occurred. This information describes the
                    context in which the current events occurred.
                    */
                        eventType: null,

                        /*
                    The event which occurred.
                    */
                        eventAction: null,

                        /*
                    If a link or button triggers this event, please provide the target
                    url of this link
                    */
                        eventTargetURL: null,

                        /* If additional information in context of an interaction element
                    is needed a short but meaningful description is placed here:
                     */
                        linkInformation: null,
                    },
                },
            ],
        };
        this._dataLayer = _.cloneDeep(this._template);
    }

    public addDefaults(props: object): DataLayer {
        this._template = { ..._.cloneDeep(this._template), ...props };
        return this;
    }

    public clone(): DataLayer {
        this._dataLayer = _.cloneDeep(this._template);
        return this;
    }

    public set(
        key:
            | 'pageName'
            | 'viewChange'
            | 'eventType'
            | 'additionalEvent'
            | 'eventAction'
            | 'linkInformation'
            | 'eventTargetURL'
            | 'formType'
            | 'formName'
            | 'formErrorFields'
            | 'formLastTouchedField'
            | 'formFieldValues'
            | 'currentLanguage'
            | 'events'
            | 'privacyStatement'
            | 'userGroup'
            | 'loginStatus'
            | 'errorCode'
            | 'errorMessage'
            | 'errorCausingUrl'
            | 'currentBrand'
            | 'productCategory'
            | 'productName'
            | 'privacyStatementPostalAds'
            | 'privacyStatementPhoneAds'
            | 'privacyStatementElectronicAds'
            | 'privacyStatementMailAds'
            | 'productId',
        value: unknown,
        value2?: unknown,
        value3?: unknown,
    ): DataLayer {
        switch (key) {
            case 'pageName':
                this._dataLayer.core.pageInfo.pageName = value;
                break;
            case 'viewChange':
                this._dataLayer.core.attributes.viewChange = value;
                break;
            case 'eventType':
                this._dataLayer.event[0].eventInfo.eventType = value;
                break;
            case 'additionalEvent':
                this._dataLayer.event.push({ eventInfo: { eventType: value } });
                this._dataLayer.event[1].eventInfo.eventAction = value2;
                this._dataLayer.event[1].eventInfo.linkInformation = value3;
                break;
            case 'eventAction':
                this._dataLayer.event[0].eventInfo.eventAction = value;
                break;
            case 'linkInformation':
                this._dataLayer.event[0].eventInfo.linkInformation = value;
                break;
            case 'eventTargetURL':
                this._dataLayer.event[0].eventInfo.eventTargetURL = value;
                break;
            case 'formType':
                this._dataLayer.form.type = value;
                break;
            case 'formName':
                this._dataLayer.form.name = value;
                break;
            case 'formErrorFields':
                this._dataLayer.form.errorFields = value;
                break;
            case 'formLastTouchedField':
                this._dataLayer.form.lastTouchedField = value;
                break;
            case 'formFieldValues':
                this._dataLayer.form.fieldValues = value;
                break;
            case 'currentLanguage':
                this._dataLayer.core.pageInfo.language = value;
                break;
            case 'events':
                this._dataLayer.event = value;
                break;
            case 'privacyStatement':
                this._dataLayer.dataPrivacyStatement.allowElectronicInvoices = value;
                break;
            case 'privacyStatementPostalAds':
                this._dataLayer.dataPrivacyStatement.allowPostalAds = value;
                break;
            case 'privacyStatementPhoneAds':
                this._dataLayer.dataPrivacyStatement.allowPhoneAds = value;
                break;
            case 'privacyStatementElectronicAds':
                this._dataLayer.dataPrivacyStatement.allowElectronicAds = value;
                break;
            case 'privacyStatementMailAds':
                this._dataLayer.dataPrivacyStatement.allowMailAds = value;
                break;
            case 'userGroup':
                this._dataLayer.customerData.loggedInUserGroup = value;
                break;
            case 'loginStatus':
                this._dataLayer.customerData.loginStatus = value;
                break;
            case 'errorCode':
                this._dataLayer.error.errorCode = value;
                break;
            case 'errorMessage':
                this._dataLayer.error.errorMessage = value;
                break;
            case 'errorCausingUrl':
                this._dataLayer.error.errorCausingURL = value;
                break;
            case 'currentBrand':
                this._dataLayer.core.attributes.brand = value;
                break;
            case 'productCategory':
                this._dataLayer.product[0].category = value;
                break;
            case 'productName':
                this._dataLayer.product[0].name = value;
                break;
            case 'productId':
                this._dataLayer.product[0].productID = value;
            default:
                break;
        }
        return this;
    }

    public write(): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).du_digitalData = this._dataLayer;
    }
}
