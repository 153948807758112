import { ContentSection, SectionHeading } from '@vwfs-bronson/bronson-react';
import React from 'react';

export type CookiePolicyProps = {
    title?: string;
    /**
     * Text to be shown above the cookie table. Can contain HTML markup!
     */
    textAboveTable?: string;
    cookieTable?: React.ReactNode;
    /**
     * Text to be shown below the cookie table. Can contain HTML markup!
     */
    textBelowTable?: string;
};

export const CookiePolicy: React.FC<CookiePolicyProps> = ({ title, textAboveTable, cookieTable, textBelowTable }) => {
    const titleComponent = title ? <SectionHeading level={'2'}>{title}</SectionHeading> : null;

    const textAboveTableComponent = textAboveTable ? (
        <div dangerouslySetInnerHTML={{ __html: textAboveTable }} className={'u-pb-small'}></div>
    ) : null;

    const cookieTableComponent = cookieTable ? cookieTable : null;

    const textBelowTableComponent = textBelowTable ? (
        <div dangerouslySetInnerHTML={{ __html: textBelowTable }}></div>
    ) : null;

    return (
        <ContentSection.ComponentWrapper>
            {titleComponent} {textAboveTableComponent} {cookieTableComponent} {textBelowTableComponent}
        </ContentSection.ComponentWrapper>
    );
};

CookiePolicy.displayName = 'CookiePolicy';
