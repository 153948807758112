import { ErrorMessage } from '@vwfs-bronson/bronson-react';
import { FieldMetaProps } from 'formik';
import React from 'react';
import { hasError } from './hasError';

export type ErrorMessageProps = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    meta: FieldMetaProps<any>;
    testId?: string;
    withHtmlValidationWarning?: boolean;
    id?: string;
};

export const ValidationErrorMessage: React.FC<ErrorMessageProps> = ({
    meta,
    testId,
    withHtmlValidationWarning,
    id,
}) => {
    /**
     * Working with the style and not with an if statement was required to not break the tests in the markets.
     * See also hint from Bronson team
     * https://teams.microsoft.com/l/message/19:1ff395352c3542fa8477b5c53072ebdf@thread.skype/1598356613820?tenantId=e9293843-ced6-4c97-b99f-4998c3285f92&groupId=ca9aef03-550e-48ec-8b25-840a6c13b765&parentMessageId=1598356613820&teamName=Bronson-%20%2F%20UX-Support&channelName=General&createdTime=1598356613820
     */
    const hideErrorStyle = {
        display: 'none',
    };

    const hideErrorMessage = !hasError(meta);

    return (
        <ErrorMessage
            id={id}
            testId={`${testId}ErrorMessage`}
            style={hideErrorMessage ? hideErrorStyle : undefined}
            aria-hidden={hideErrorMessage}
        >
            {withHtmlValidationWarning ? <span dangerouslySetInnerHTML={{ __html: meta.error || '' }} /> : meta.error}
        </ErrorMessage>
    );
};
