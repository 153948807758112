import React from 'react';
import classNames from 'classnames';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';
/**
 * Bronson DataOverview component.
 */
export function DataOverview({ buttonLabel, buttonProps, children, className, headerLanguage = 'de', icon, title, testId, ...otherProps }) {
    const articleClassNameList = classNames('c-data-overview ', className).trim();
    // generated
    function renderIfDataOverviewIcon() {
        if (icon) {
            return (React.createElement("div", { className: "c-data-overview__icon" },
                React.createElement(Icon, { name: icon })));
        }
        return null;
    }
    function renderIfDataOverviewButton() {
        if (buttonLabel) {
            return (React.createElement(Button, { element: "a", link: true, small: true, simple: true, className: "c-data-overview__button", iconViaCss: true, ...buttonProps }, buttonLabel));
        }
        return null;
    }
    return (React.createElement("article", { ...otherProps, "data-testid": testId, className: articleClassNameList },
        React.createElement("header", { className: "c-data-overview__header", lang: headerLanguage },
            renderIfDataOverviewIcon(),
            React.createElement("h4", { className: "c-data-overview__title" }, title),
            renderIfDataOverviewButton()),
        React.createElement("div", { className: "c-data-overview__body" }, children)));
}
