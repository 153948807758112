export const testIds = {
    resetButton: 'ResetButton',
    filters: {
        documentType: {
            openId: 'DocumentTypeFilter',
            leasingDocument: 'DocumentTypeFilterItem-LEASING_DOCUMENT',
            all: 'DocumentTypeFilterItem-ALL',
        },
        contract: {
            openId: 'ContractIdentifierFilter',
            contract1000: 'ContractIdentifierFilter-1000',
            all: 'ContractIdentifierFilter-ALL',
            general: 'ContractIdentifierFilter-GENERAL',
        },
        timeFrame: {
            openId: 'TimeFrameFilter',
            year2020: 'TimeFrameFilter-2020',
            all: 'TimeFrameFilter-ALL',
        },
    },
    firstDocumentRow: 'InboxRow-1',
};
