import { Context, createContext } from 'react';
import { AuthorizationFlowState } from './types';

export type AuthorizationState<T> =
    | [AuthorizationFlowState<T>, (newState: AuthorizationFlowState<T>) => void]
    | undefined;

export function createAuthorizationContext<T>(displayName: string): Context<AuthorizationState<T>> {
    const AuthorizationContext = createContext<AuthorizationState<T>>(undefined);
    AuthorizationContext.displayName = displayName;
    return AuthorizationContext;
}
