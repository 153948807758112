import React, { CSSProperties, ReactNode } from 'react';
import ReactLoadingSkeleton from 'react-loading-skeleton';

export type SkeletonProps = {
    /**
     * Number of lines loading. If not defined, simple single-line loading skeleton will be displayed.
     */
    count?: number;
    /**
     * Duration is how long it takes do one cycle of the skeleton animation.
     */
    duration?: number;
    /**
     * Number of seconds before looping the animation.
     */
    delay?: number;
    /**
     * Width of the skeleton. Useful when the skeleton is inside an inline element with no width of its own.
     */
    width?: string | number;
    /**
     * ReactNode wrapping the skeleton component.
     */
    wrapper?: ReactNode;
    /**
     * Height of the skeleton. Useful when you don't want to adapt the skeleton to a text element but for instance a card.
     */
    height?: string | number;
    /**
     * Prop for making the skeleton look like a circle, for when you are creating a user card with a profile picture for instance.
     */
    circle?: boolean;
    /**
     * CSS properties for style.
     */
    style?: CSSProperties;
    /**
     * Class name.
     */
    className?: string;
};

export const Skeleton: React.FC<SkeletonProps> = props => {
    return <ReactLoadingSkeleton {...props} />;
};
