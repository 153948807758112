import * as React from 'react';
import { FormSection } from '@vwfs-bronson/bronson-react';
import { Accordion } from '../accordion';
import './contract.css';

export type ContractProps = {
    /**
     * A component describing contract header (@see ContractHeader)
     */
    contractHeader: React.ReactNode;
    /**
     * A component describing contract body (@see ContractBody)
     */
    contractBody: React.ReactNode;
    /**
     * A component describing contract unpaids (@see UnpaidAlert)
     */
    contractUnpaids?: React.ReactNode;
    /**
     * A component describing the contract actions such as Amortization, Documents, Settlements
     */
    contractActions?: React.ReactNode;
};

export const Contract: React.FC<ContractProps> = ({
    contractHeader,
    contractBody,
    contractUnpaids,
    contractActions,
}) => {
    const header = (
        <>
            {contractHeader}

            {contractUnpaids}

            {contractActions}
        </>
    );
    return (
        <FormSection title={header}>
            <Accordion>
                <>{contractBody}</>
            </Accordion>
        </FormSection>
    );
};
