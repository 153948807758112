import React from 'react';
import { ContentSection, Layout } from '@vwfs-bronson/bronson-react';
import { Skeleton } from '../Skeleton';

export const LoginChoiceLoadingPlaceholder: React.FC = () => (
    <Layout>
        <Layout.Item>
            <ContentSection className={'login-choice-form u-text-center'}>
                <Layout.Item>
                    <Skeleton height={90} width={400} className="u-mb u-mt-xlarge " />
                </Layout.Item>
                <Layout.Item>
                    <Skeleton height={70} />
                </Layout.Item>
                <Layout.Item>
                    <Skeleton height={70} className="u-mb u-mt" />
                </Layout.Item>
                <Layout.Item>
                    <Skeleton height={40} width={150} className="u-mb u-m-xsmall" />
                </Layout.Item>
                <Layout.Item>
                    <Skeleton height={40} width={200} className="u-mt" />
                </Layout.Item>
                <Layout.Item>
                    <Skeleton height={40} width={200} className="u-mb" />
                </Layout.Item>
            </ContentSection>
        </Layout.Item>
    </Layout>
);
