import React from 'react';

import { InfoIcon } from '../info-icon';

export type DefinitionListItemTitleProps = {
    title: string | React.ReactNode;
    tooltip?: string | React.ReactNode;
};

export const DefinitionListItemTitle: React.FC<DefinitionListItemTitleProps> = ({ title, tooltip }) => {
    return (
        <div>
            {title}
            {tooltip && <InfoIcon text={tooltip} />}
        </div>
    );
};
