import React from 'react';
import { Card, Layout } from '@vwfs-bronson/bronson-react';
import { ContractHeader, allowedFractions } from '../contracts';

export type AmortizationTableHeaderProps = {
    /**
     * The complete car information (e.g. "VW CALIFORNIA 2.0BITDI SE 180 4DR 4MTN DSG")
     */
    carInformation: string;
    /**
     * The contract category to be shown to the customer (e.g. "Operating Lease")
     */
    contractCategory: string;
    /**
     * The contract number
     */
    contractNumber: string;
    /**
     * A component describing license plate
     */
    licensePlateComponent?: React.ReactNode;
    /**
     * A component describing tiles see @AmortizationTableTiles
     */
    tiles?: React.ReactNode;
    /**
     * Props to overwrite properties default in Layout
     */
    displayFractionTitle?: string;
    displayFractionLicensePlate?: string;
    thirdLineData?: string | React.ReactNode;
};

export const AmortizationTableHeader: React.FC<AmortizationTableHeaderProps> = ({
    carInformation,
    contractCategory,
    contractNumber,
    licensePlateComponent,
    tiles,
    displayFractionLicensePlate,
    displayFractionTitle,
    thirdLineData,
}) => {
    return (
        <Layout>
            <Layout.Item default="1/1">
                <Card element="article">
                    <ContractHeader
                        carInformation={carInformation}
                        contractCategory={contractCategory}
                        contractNumber={contractNumber}
                        contractIdentifier={licensePlateComponent}
                        displayFractionLicensePlate={displayFractionLicensePlate as allowedFractions}
                        displayFractionTitle={displayFractionTitle as allowedFractions}
                        thirdLineData={thirdLineData}
                    />
                </Card>
            </Layout.Item>
            {tiles && (
                <Layout.Item default="1/1">
                    <Layout className={'o-layout--equal-height'}>{tiles}</Layout>
                </Layout.Item>
            )}
        </Layout>
    );
};
