import { ErrorMessage as ErrorMessageShared } from '@vwfs-bronson/bronson-react';
import React from 'react';

export type ValueOrErrorMessageProps = {
    /**
     * The value to be displayed if it is truthy
     */
    value?: string;
    /**
     * The message to be displayed in case the value provided is not truthy
     */
    errorMessage: string;
};

export const ValueOrErrorMessage: React.FC<ValueOrErrorMessageProps> = ({ value, errorMessage }) => {
    return value ? <>{value}</> : <ErrorMessageShared>{errorMessage}</ErrorMessageShared>;
};
