export const groupByFileSize = (files: File[], maxRequestSize: number): Array<File[]> => {
    return files
        .sort(({ size: a }, { size: b }) => b - a)
        .reduce((acc: Array<File[]>, curr: File) => {
            let assigned = false;
            const result = acc.map((combined: File[]) => {
                const size = combined.reduce((sum, file: File) => sum + file.size, 0);
                if (size + curr.size < maxRequestSize && !assigned) {
                    assigned = true;
                    return [...combined, curr];
                }
                return combined;
            });
            return !assigned ? [...result, [curr]] : result;
        }, []);
};
