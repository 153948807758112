import { IconList as IconListShared } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { IconListCMS } from '@cp-shared-8/apis';
import { Override } from '@cp-shared-8/common-utilities';

export type IconListItem = {
    label: string | React.ReactNode;
    icon: string;
};

export type IconList = Override<
    IconListCMS,
    {
        items: IconListItem[];
    }
>;

export const IconList: React.FC<IconList> = ({ items }) => {
    return (
        <IconListShared>
            {items.map((item, index) => (
                <IconListShared.Item key={index} icon={item.icon}>
                    {item.label}
                </IconListShared.Item>
            ))}
        </IconListShared>
    );
};
