import React, { FC, ReactElement, ReactNode, useEffect } from 'react';

import { AuthorizationFlowHookResult } from '../hooks/create-authorization-flow-hook';
import { hasError, isLoading, notInitiated } from '../context';

export type AuthorizationComponentFactoryOptions<T> = {
    useAuthorizationFlow: () => AuthorizationFlowHookResult<T>;
    onLoading: ReactNode;
    onError: ReactNode;
    onMissingAuthorization: ReactNode | ((authorizationResult: T) => ReactNode);
    displayName: string;
};

export function createAuthorizationComponent<T>({
    useAuthorizationFlow,
    onLoading,
    onError,
    onMissingAuthorization,
    displayName,
}: AuthorizationComponentFactoryOptions<T>): FC {
    const Component: FC = ({ children }): ReactElement => {
        const { state, initiate } = useAuthorizationFlow();

        useEffect(() => {
            if (notInitiated(state)) {
                initiate();
            }
        }, []);

        if (notInitiated(state) || isLoading(state)) {
            return <>{onLoading}</>;
        }

        if (hasError(state)) {
            return <>{onError}</>;
        }

        if (state.isAuthorized) {
            return <>{children}</>;
        } else {
            if (onMissingAuthorization instanceof Function) {
                return <>{onMissingAuthorization(state)}</>;
            }
            return <>{onMissingAuthorization}</>;
        }
    };
    Component.displayName = displayName;

    return Component;
}
