import React from 'react';
import { Layout } from '@vwfs-bronson/bronson-react';
import { Skeleton } from '../Skeleton';

export type ContractDetailsLoadingPlaceholderProps = {
    /**
     * Array containg numbers of rows in each section. If there are more than one section, a header element is added for each section.
     */
    sections?: number[];
};

export const ContractDetailsLoadingPlaceholder: React.FC<ContractDetailsLoadingPlaceholderProps> = ({
    sections = [3, 2],
}) => {
    return (
        <Layout>
            {sections.map((section, index) => (
                <div key={index}>
                    {sections.length > 1 ? (
                        <Layout.Item default={'1/2'} s={'1/1'}>
                            <Skeleton height={40} className="u-mb-small u-mt-small" />
                        </Layout.Item>
                    ) : null}
                    {Array.from(Array(section).keys()).map(key => (
                        <div key={`${index}${key}`}>
                            <Layout.Item default={'1/2'} s={'1/1'}>
                                <Skeleton height={30} />
                            </Layout.Item>
                            <Layout.Item default={'1/2'} s={'1/1'}>
                                <Skeleton height={30} />
                            </Layout.Item>
                        </div>
                    ))}
                </div>
            ))}
        </Layout>
    );
};
