import React, { FormEventHandler, MouseEventHandler } from 'react';
import { useField } from 'formik';
import { FormField, Paragraph } from '@vwfs-bronson/bronson-react';
import { ValidationErrorMessage } from '../errors';
import { ValidatedCheckbox, ValidatedCheckboxProps } from '../validated-checkbox';
import { InfoIcon } from '../../info-icon';

export const ValidatedCheckboxWithGroup: React.FC<ValidatedCheckboxProps & { groupName: string }> = ({ ...props }) => (
    <ValidatedCheckbox {...props} />
);

export type ValidatedCheckboxGroupProps = {
    children: React.ReactNode;
    name: string;
    label: string;
    secondaryLabel?: string | React.ReactNode;
    helpText?: string | React.ReactNode;
    className?: string;
    groupClassName?: string;
    testId?: string;
    id?: string;
    isMandatory?: boolean;
    withHtmlValidationWarning?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
    onFocus?: Function;
    onLinkClick?: MouseEventHandler;
    tooltip?: string | React.ReactNode;
    handleChange?: FormEventHandler<HTMLElement>;
    hint?: string;
};

export const ValidatedCheckboxGroup = ({
    children,
    name,
    label,
    secondaryLabel,
    className,
    testId,
    id,
    isMandatory = false,
    withHtmlValidationWarning = false,
    groupClassName,
    onLinkClick,
    handleChange,
    tooltip,
    hint,
}: ValidatedCheckboxGroupProps) => {
    const [, meta] = useField({ name });
    const infoIcon = tooltip ? <InfoIcon text={tooltip} /> : null;
    const errorMessage = (
        <ValidationErrorMessage meta={meta} testId={testId} withHtmlValidationWarning={withHtmlValidationWarning} />
    );

    return (
        <FormField
            type="other"
            testId={`${testId}FormField`}
            id={id}
            labelText={label}
            notion={isMandatory}
            onClick={onLinkClick}
            infoIcon={infoIcon}
            onChange={handleChange}
            className={className}
            hint={hint}
        >
            {secondaryLabel && <Paragraph className={'u-text-muted'}>{secondaryLabel}</Paragraph>}
            <div className={groupClassName}>
                {children}
                {errorMessage}
            </div>
        </FormField>
    );
};

ValidatedCheckboxGroup.Checkbox = ValidatedCheckboxWithGroup;
