import { SiteNav as SiteNavBronson } from '@vwfs-bronson/bronson-react';
import React from 'react';

import { SimpleNavItem, SimpleNavItemProps } from './SimpleNavItem';

export type SiteNavItemPropsList = Array<SimpleNavItemProps>;

export type SiteNavProps = {
    /**
     * Navigation items to be shown in the nav bar.
     */
    navigationItems: SiteNavItemPropsList;
    /**
     * An optional language switcher (@see LanguageSwitcher)
     */
    languageSwitcher?: React.ReactNode;
    /**
     * Optional close menu text for VWFS brand ("Close Menu" when omitted)
     */
    closeMenuText?: string;
    /**
     * Optional custom title for open button ("Show navigation" when omitted)
     */
    openButtonTitle?: string;

    /**
     * Optional open menu text for VW6 brand ("Menu" when omitted)
     */
    openMenuText?: string;
};

/**
 * A one-level navigation bar composed of left-oriented nav links, right-oriented nav links and a language switcher
 */
export const SiteNav: React.FC<SiteNavProps> = ({
    navigationItems,
    languageSwitcher,
    closeMenuText,
    openButtonTitle,
    openMenuText,
}) => {
    const reversedNavItems = navigationItems.filter(item => item.isReversed);
    const reversedNav = reversedNavItems.map(item => <SimpleNavItem {...item} key={item.label} />);

    const commonNavItems = navigationItems.filter(item => !item.isReversed);
    const commonNav = commonNavItems.map(item => <SimpleNavItem {...item} key={item.label} />);

    return (
        <SiteNavBronson closeMenuText={closeMenuText} title={openButtonTitle} openMenuText={openMenuText}>
            {languageSwitcher || commonNavItems.length > 0 ? (
                <SiteNavBronson.Area>
                    {commonNav}
                    {languageSwitcher}
                </SiteNavBronson.Area>
            ) : null}

            {reversedNavItems.length > 0 && <SiteNavBronson.Area>{reversedNav}</SiteNavBronson.Area>}
        </SiteNavBronson>
    );
};
