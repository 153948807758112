import { AxiosResponse } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { NotificationNC } from '@cp-shared-8/apis';

export type NotificationsCenterItem = {
    id: string;
    title: string;
    content: string | React.ReactNode;
    read: boolean;
    date: string;
    statusReadLabel?: string;
    statusUnreadLabel?: string;
    showUnreadButton?: boolean;
    markAsReadLabel?: string;
    markAsUnreadLabel?: string;
    close?: boolean;
    closeLabel?: string;
    timestamp?: boolean;
    timestampText?: string;
    onMarkReadUnread?: () => void;
    onClose?: () => void;
    onClick: () => void;
};

export const useNotificationsCenter: (
    fetchFunction: () => Promise<AxiosResponse>,
    intervalInMinutes: number,
) => {
    notificationsCenter: NotificationNC[];
    update: (notifications: NotificationNC[]) => void;
} = (fetchFunction, intervalInMinutes) => {
    const [notificationsCenter, setNotificationsCenter] = useState<NotificationNC[]>([]);
    const cancelRequest = useRef<boolean>(false);
    const fetchInterval = useRef<ReturnType<typeof setTimeout>>();

    const fetch = (): void => {
        fetchFunction()
            .then(response => {
                if (cancelRequest.current) return;
                setNotificationsCenter(response.data);
            })
            .catch(error => {
                // eslint-disable-next-line no-console
                console.warn(error);
                // eslint-disable-next-line no-console
                console.warn(`Can not get notifications. Retrying in ${intervalInMinutes} min.`);
            });
    };

    const startInterval = (): ReturnType<typeof setTimeout> => {
        return setInterval(() => {
            fetch();
        }, intervalInMinutes * 60 * 1000);
    };

    const update = (notifications: NotificationNC[]): void => {
        setNotificationsCenter(notifications);
    };

    useEffect(() => {
        fetch();
        fetchInterval.current = startInterval();

        return (): void => {
            cancelRequest.current = true;
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            fetchInterval.current && clearInterval(fetchInterval.current);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return { notificationsCenter, update };
};
