import React from 'react';
import { Picker } from '../Picker/Picker';
/**
 * Bronson DatePicker component.
 *
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-datepicker.html
 *
 * @constructor
 */
export function DatePicker({ className, dateFormat = 'Y-m-d', datePickerClassName, disabled = false, disableDays = [], error = false, flatpickrOptions = {}, flatpickrProps, locale, maskedInput = true, maxDate, minDate, mode = 'single', monthSelectorDropdown = false, name, noBackground = true, onBlur, onChange, onError, placeholder, value, weekNumbers, testId, ...otherProps }) {
    return (React.createElement(Picker, { className: className, componentClassName: "c-datepicker js-datepicker", dateFormat: dateFormat, disabled: disabled, disableDays: disableDays, error: error, flatpickrOptions: {
            ...flatpickrOptions,
            ...{ minDate, maxDate },
        }, flatpickrProps: flatpickrProps, locale: locale, maskedInput: maskedInput, mode: mode, monthSelectorDropdown: monthSelectorDropdown, name: name, noBackground: noBackground, onBlur: onBlur, onChange: onChange, onError: onError, pickerClassName: datePickerClassName, placeholder: placeholder, value: value, weekNumbers: weekNumbers, testId: testId, ...otherProps }));
}
