import React from 'react';
import { ContentSection, Layout } from '@vwfs-bronson/bronson-react';
import { MarketingCardLoadingPlaceholder } from '../marketing-card-loading-placeholder';
import { Skeleton } from '../Skeleton';

export type RequestLoadingPlaceholderProps = {
    withTextArea?: boolean;
    withMarketingCard?: boolean;
    numberOfButtons?: number;
};

export const RequestLoadingPlaceholder: React.FC<RequestLoadingPlaceholderProps> = ({
    withTextArea = false,
    withMarketingCard = false,
    numberOfButtons = 1,
}) => (
    <Layout>
        <Layout.Item default={withMarketingCard ? '2/3' : '1/1'} m={'1/1'}>
            <ContentSection className={'request-certificate-form u-text-center'}>
                <Layout>
                    <Layout.Item>
                        <Skeleton height={70} />
                    </Layout.Item>
                    <Layout.Item>
                        <Skeleton height={70} />
                    </Layout.Item>
                    {withTextArea && (
                        <Layout.Item>
                            <Skeleton height={140} />
                        </Layout.Item>
                    )}
                </Layout>
                <Skeleton height={40} width={200} className="u-mt u-m-xsmall" count={numberOfButtons} />
            </ContentSection>
        </Layout.Item>
        {withMarketingCard && (
            <Layout.Item default={'1/3'} m={'1/1'}>
                <MarketingCardLoadingPlaceholder />
            </Layout.Item>
        )}
    </Layout>
);
