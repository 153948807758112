import { Notification, NotificationsGroup } from '@vwfs-bronson/bronson-react';
import React, { useState } from 'react';

import { CookieNotificationStatusEnum } from './CookieNotificationStatusEnum';

export type CookieNotificationProps = {
    status?: CookieNotificationStatusEnum;
    headline?: string;
    text?: string;
    className?: string;
    testId?: string;
    clickHandler?: () => void;
};

export const CookieNotification: React.FC<CookieNotificationProps> = ({
    status,
    headline,
    text,
    className,
    testId,
    clickHandler,
    children,
}) => {
    const [accepted, setAccepted] = useState(localStorage.cookiesAccepted || false);

    const onClickButton = () => {
        localStorage.cookiesAccepted = 'true';
        setAccepted(true);
    };

    return (
        <NotificationsGroup>
            <Notification
                visible={!accepted}
                className={className}
                status={status}
                title={headline}
                showCloseButton
                testId={testId}
                text={text}
                onCloseClick={() => {
                    onClickButton();
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    !!clickHandler && clickHandler();
                }}
            >
                {children}
            </Notification>
        </NotificationsGroup>
    );
};
