import React from 'react';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { HeroTeaserLoadingPlaceholder } from '../hero-teaser-loading-placeholder';
import { Skeleton } from '../Skeleton';

export type FaqLoadingPlaceholderProps = {
    withHeroTeaser?: boolean;
    withDescription?: boolean;
    questionsCount?: number;
    itemGroups?: number[];
};

export const FaqLoadingPlaceholder: React.FC<FaqLoadingPlaceholderProps> = ({
    withHeroTeaser = true,
    withDescription,
    questionsCount = 5,
    itemGroups = [],
}) => {
    return (
        <ContentSection pageWrapSize="medium">
            {withHeroTeaser && <HeroTeaserLoadingPlaceholder />}
            <div className="u-mh">
                <Skeleton height={60} />
                {withDescription && <Skeleton height={30} />}
                <div className="u-mb" />
                {!itemGroups.length && <Skeleton height={30} count={questionsCount} />}
                {itemGroups.map((group, index) => (
                    <div key={index} className="u-mb">
                        <Skeleton height={50} width={200} />
                        <Skeleton height={30} count={group} />
                    </div>
                ))}
            </div>
        </ContentSection>
    );
};
