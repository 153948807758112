import { Table } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { Cell, DynamicTableGroupByOptions, DynamicTableSortOptions } from '../DynamicTableOptions';

type GroupedRows<TDataRow> = {
    [groupKey: string]: TDataRow[];
};

function groupRows<TDataRow>(rows: TDataRow[], groupByFn: (row: TDataRow) => string): GroupedRows<TDataRow> {
    const result: GroupedRows<TDataRow> = {};
    rows.forEach(row => {
        const groupLabel = groupByFn(row);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        result[groupLabel] ? result[groupLabel].push(row) : (result[groupLabel] = [row]);
    });
    return result;
}

function sortGroupLabels<TDataRow>(
    groupedRows: GroupedRows<TDataRow>,
    groupByOptions: DynamicTableGroupByOptions<TDataRow>,
): string[] {
    const groupLabels = Object.keys(groupedRows);
    const { reversed, compareGroupByLabel } = groupByOptions;
    const sortedGroupLabels = groupLabels.sort(compareGroupByLabel);
    return reversed ? sortedGroupLabels.reverse() : sortedGroupLabels;
}

function sortRows<TDataRow>(rows: TDataRow[], sortOptions?: DynamicTableSortOptions<TDataRow>): TDataRow[] {
    if (sortOptions) {
        const { compare, reversed = false } = sortOptions;
        return reversed ? rows.sort(compare) : rows.sort(compare).reverse();
    }
    return rows;
}

export type GroupedTableRowsProps<TDataRow> = {
    rows: TDataRow[];
    toColumnValues: (row: TDataRow) => Cell[];
    groupByOptions: DynamicTableGroupByOptions<TDataRow>;
    sortOptions?: DynamicTableSortOptions<TDataRow>;
};

export function createGroupedTableRows<TDataRow extends object>(): React.FC<GroupedTableRowsProps<TDataRow>> {
    const GroupedTableRows: React.FC<GroupedTableRowsProps<TDataRow>> = ({
        rows,
        groupByOptions,
        sortOptions,
        toColumnValues,
    }) => {
        if (rows.length <= 0) {
            return null;
        }
        const { groupBy, formatGroupLabel } = groupByOptions;
        const sortedRows: TDataRow[] = sortRows(rows, sortOptions);
        const groupedRows: GroupedRows<TDataRow> = groupRows(sortedRows, groupBy);
        const sortedGroupLabels: string[] = sortGroupLabels(groupedRows, groupByOptions);
        const numberOfColumns: number = toColumnValues(rows[0]).length;

        return (
            <>
                {sortedGroupLabels.map(groupLabel => (
                    <React.Fragment key={groupLabel}>
                        <Table.Tr>
                            <Table.Th colSpan={numberOfColumns}>
                                {formatGroupLabel ? formatGroupLabel(groupLabel) : groupLabel}
                            </Table.Th>
                        </Table.Tr>
                        {groupedRows[groupLabel].map((row, rowIndex) => (
                            <Table.Tr key={groupLabel + rowIndex}>
                                {toColumnValues(row).map((cellData, cellIndex) => {
                                    if (typeof cellData === 'string') {
                                        return <Table.Td key={cellIndex}>{cellData}</Table.Td>;
                                    }
                                    return (
                                        <Table.Td
                                            key={cellIndex}
                                            className={[
                                                !!cellData.nowrap ? 'u-ws-nowrap' : '',
                                                !!cellData.hiddenForMobile ? 'u-hide@xs' : '',
                                                !!cellData.textAlign ? `u-text-${cellData.textAlign}` : '',
                                            ]
                                                .filter(Boolean)
                                                .join(' ')}
                                        >
                                            {cellData?.value}
                                        </Table.Td>
                                    );
                                })}
                            </Table.Tr>
                        ))}
                    </React.Fragment>
                ))}
            </>
        );
    };
    GroupedTableRows.displayName = 'GroupedTableRows';
    return GroupedTableRows;
}
