import React from 'react';
import classNames from 'classnames';
/**
 * Bronson Logo component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-logo.html
 * @constructor
 */
export function Logo({ alt, ariaLabel, breakpoint, className, disableLink, onClick, specificLogo, src, srcSet, title, testId, ...otherProps }) {
    const aClassNameList = classNames('c-logo', {
        'c-logo--has-image': src,
        [`c-logo--${specificLogo}`]: specificLogo,
    }, className).trim();
    /**
     * Conditionally render a `<span>` as Logo wrapper.
     * @type {string}
     */
    const LogoElement = disableLink ? 'span' : 'a';
    return (React.createElement(LogoElement, { className: aClassNameList, 
        /**
         * Display the `[title]` conditionally depending on
         * if {@link disableLink} is not set or {@link title} is set.
         */
        title: disableLink ? undefined : title, "data-testid": testId, "aria-label": src ? null : ariaLabel, role: disableLink ? undefined : 'link', onClick: disableLink ? undefined : onClick, ...otherProps }, src && (React.createElement("picture", null,
        breakpoint && srcSet && React.createElement("source", { srcSet: srcSet, media: `(max-width: ${breakpoint})` }),
        React.createElement("img", { src: src, alt: alt || '', className: "c-logo__image" })))));
}
