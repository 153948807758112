import React, { FC, ReactElement } from 'react';
import { KeycloakProvider } from '@react-keycloak/web';
import Keycloak, { KeycloakInitOptions, KeycloakInstance } from 'keycloak-js';

function throwIfMissing(value: string | undefined): string {
    if (value) {
        return value;
    } else {
        throw new Error(
            'Missing env variables for authentication. Make sure to specify "REACT_APP_AUTH_PROVIDER_URL", "REACT_APP_AUTH_REALM" und "REACT_APP_AUTH_CLIENT_ID"',
        );
    }
}

function init(): KeycloakInstance {
    return Keycloak({
        url: throwIfMissing(process.env.REACT_APP_AUTH_PROVIDER_URL),
        realm: throwIfMissing(process.env.REACT_APP_AUTH_REALM),
        clientId: throwIfMissing(process.env.REACT_APP_AUTH_CLIENT_ID),
    });
}

export type AuthenticationProviderProps = {
    loadingComponent?: ReactElement;
    redirectUri?: string;
    initConfig?: KeycloakInitOptions;
};

export const AuthenticationProvider: FC<AuthenticationProviderProps> = ({
    loadingComponent,
    redirectUri,
    initConfig,
    children,
}) => (
    <KeycloakProvider
        keycloak={init()}
        LoadingComponent={loadingComponent}
        initConfig={initConfig || { checkLoginIframe: false, redirectUri }}
    >
        {children}
    </KeycloakProvider>
);
