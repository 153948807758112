import { IconList } from '@vwfs-bronson/bronson-react';
import React from 'react';

export type CheckmarkListProps = {
    items: string[];
};

export const CheckmarkList: React.FC<CheckmarkListProps> = ({ items }) => {
    if (items.length === 0) {
        return null;
    }
    return (
        <IconList>
            {items.map((item, index) => (
                <IconList.Item key={index} icon="checkmark">
                    {item}
                </IconList.Item>
            ))}
        </IconList>
    );
};
