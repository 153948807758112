import React, { FC } from 'react';

export type GreetingProps = {
    /**
     * The full greeting text
     */
    fullGreetingText?: string;
};

export const Greeting: FC<GreetingProps> = ({ fullGreetingText }) => {
    return <h2>{fullGreetingText}</h2>;
};
