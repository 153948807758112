import React from 'react';

export type AlertOrSuccessProp = {
    text: string;
    isSuccess: boolean;
};

export const AlertOrSuccessText: React.FC<AlertOrSuccessProp> = ({ text, isSuccess }) => {
    return <span className={isSuccess ? 'u-text-success' : 'u-text-alert'}> {text} </span>;
};
