import React, { isValidElement, useCallback, useRef } from 'react';
import classNames from 'classnames';
import { apply } from '../../features/ContainerQuery';
import useResizeObserver from '../../features/useResizeObserver';
/**
 * The DescriptionList component is our implementation of the
 * [HTML Description List element `<dl>`](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dl)
 * and is offered in different responsive layout variants.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-description-list.html
 * @constructor
 */
export function DescriptionList({ children, className, grid, horizontal, shortTerms, split, table, testId, ...otherProps }) {
    const ref = useRef(null);
    const applyQC = useCallback(apply, [apply]);
    /**
     * Apply `onResizeHandler` handler conditionally to only use it
     * when in {@link split} mode.
     */
    useResizeObserver({
        ref,
        onResizeHandler: split ? applyQC : null,
    });
    const classNameList = classNames('c-description-list', {
        // Convenience props from Bronson variants.
        'c-description-list--grid': grid,
        'c-description-list--horizontal': horizontal,
        'c-description-list--terms-short': shortTerms,
        'c-description-list--split': split,
        'c-description-list--table': table,
    }, className).trim();
    return (React.createElement("dl", { ref: ref, className: classNameList, "data-testid": testId, ...otherProps }, children));
}
/**
 * Internal DescriptionListGroup container.
 * @internal
 * @returns {JSX.Element} - The DescriptionListGroup element.
 * @constructor
 */
function DescriptionListGroup({ children, className, id, termClassName, termText, testId, ...otherProps }) {
    const classNameList = classNames('c-description-list__group', className).trim();
    const dtClassNameList = classNames('c-description-list__term', termClassName).trim();
    return (React.createElement("div", { className: classNameList, "data-testid": testId, ...otherProps },
        React.createElement("dt", { className: dtClassNameList, id: id }, termText),
        children));
}
DescriptionListGroup.displayName = 'DescriptionList.Group';
DescriptionList.Group = DescriptionListGroup;
/**
 * Internal DescriptionListDetail container.
 * @internal
 * @constructor
 */
function DescriptionListDetail({ action, children, className }) {
    const ddClassNameList = classNames('c-description-list__detail', className).trim();
    return (React.createElement("dd", { className: ddClassNameList },
        children && (React.createElement("span", { className: "c-description-list__text" }, React.Children.map(children, (child) => {
            /**
             * Add CSS class `.c-description-list__time` conditionally,
             * only if the child is from HTML element `<time>`.
             */
            if (isValidElement(child)) {
                if (child?.type === 'time') {
                    const timeProps = child?.props;
                    return React.cloneElement(React.createElement("time", { className: "c-description-list__time", dateTime: timeProps?.dateTime }, timeProps?.children));
                }
                else {
                    return child;
                }
            }
            else {
                return child;
            }
        }))),
        action && React.createElement("div", { className: "c-description-list__action" }, action)));
}
DescriptionListDetail.displayName = 'DescriptionList.Detail';
DescriptionList.Detail = DescriptionListDetail;
