import React, { Context, FC, useState } from 'react';
import { AuthorizationState } from './create-authorization-context';
import { AuthorizationFlowState } from './types';

export function createAuthorizationProvider<T>(
    displayName: string,
    AuthorizationContext: Context<AuthorizationState<T>>,
): FC {
    const Provider: FC = ({ children }) => {
        const [localState, setLocalState] = useState<AuthorizationFlowState<T>>({ notInitiated: true });

        return (
            <AuthorizationContext.Provider value={[localState, setLocalState]}>
                {children}
            </AuthorizationContext.Provider>
        );
    };
    Provider.displayName = displayName;
    return Provider;
}
