import { SiteNav } from '@vwfs-bronson/bronson-react';
import React from 'react';

import { useNavigation } from './useNavigation';

export type ItemClickCallback = (url?: string) => void;

export type DropDownItem = {
    /**
     * Optional URL, only considered if no click handler provided
     */
    url?: string;
    /**
     * Label of the dropdown item (e.g. FAQs)
     */
    label: string;
    /**
     * Is the dropdown item currently active (e.g. because it reflects the current page)
     */
    isActive?: boolean;
    /**
     * An optional click handler that will be used instead of the URL redirection
     * @param url The URL of the nav item if provided
     */
    onClick?: ItemClickCallback;
};

export type SimpleNavItemProps = {
    /**
     * Optional URL, only considered if no click handler provided
     */
    url?: string;
    /**
     * Label of the nav item (e.g. FAQs)
     */
    label: string;
    /**
     * Is the nav item currently active (e.g. because it reflects the current page)
     */
    isActive?: boolean;
    /**
     * Should it be in the reversed area or not?
     */
    isReversed?: boolean;
    /**
     * An optional click handler that will be used instead of the URL redirection
     * @param url The URL of the nav item if provided
     */
    onClick?: ItemClickCallback;
    /**
     * Title to display inside the SimpleNavItem dropdown element
     */
    dropdownTitle?: string;
    /**
     * A collection of {@link DropDownItem} elements to render a dropdown
     */
    dropdownItems?: DropDownItem[];
};

/**
 * A simple, direct nav item without a dropdown. Could be either a link or a "fake" link with a click handler
 */
export const SimpleNavItem: React.FC<SimpleNavItemProps> = ({
    url,
    label,
    isActive,
    isReversed,
    onClick: externalClickHandler,
    dropdownTitle,
    dropdownItems,
}) => {
    const navigateTo = useNavigation();

    const onClick = (itemUrl?: string, onClick?: ItemClickCallback) => {
        if (onClick) {
            onClick(itemUrl);
        } else {
            navigateTo(itemUrl);
        }
    };

    return (
        <SiteNav.Item
            label={label}
            active={isActive}
            reversed={isReversed}
            onClick={() => onClick(url, externalClickHandler)}
            icon={!!dropdownItems?.length}
            dropdownTitle={dropdownTitle}
        >
            {dropdownItems?.map(item => (
                <SiteNav.Item.DropdownItem
                    key={item.label}
                    onClick={() => onClick(item.url, item.onClick)}
                    active={item.isActive}
                >
                    {item.label}
                </SiteNav.Item.DropdownItem>
            ))}
        </SiteNav.Item>
    );
};
