import React from 'react';
import { ContentSection, PageWrap } from '@vwfs-bronson/bronson-react';
import { createDynamicTable } from '../dynamic-table';
import { Row, ThirdPartyLicensesTableProps } from './types';

const formatOptions = {
    auto: true,
    noScroll: false,
    colored: false,
    bordered: true,
    highlight: false,
    wide: true,
};

const toColumnValues = (row: Row): string[] => [row.name, row.version, row.copyright];

export const ThirdPartyLicensesTable: React.FC<ThirdPartyLicensesTableProps> = ({
    textAboveTable,
    columnHeadings,
    rows,
    description = '',
    link = { label: '', url: '' },
    viewLicenseLabel,
}) => {
    const DynamicTable = createDynamicTable<Row>();
    return (
        <ContentSection>
            <PageWrap size={'medium'}>
                <header className="u-text-center">
                    <h3>
                        <p>{textAboveTable}</p>
                    </h3>
                </header>
                <DynamicTable
                    rows={rows}
                    columnHeadings={columnHeadings}
                    toColumnValues={toColumnValues}
                    formatOptions={formatOptions}
                />
                {link.url && (
                    <div className="u-mb">
                        <span>{viewLicenseLabel}: </span>
                        <a href={link.url}>{link.label}</a>
                    </div>
                )}
                {description && <p dangerouslySetInnerHTML={{ __html: description }} />}
            </PageWrap>
        </ContentSection>
    );
};
