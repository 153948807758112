import { useKeycloak } from '@react-keycloak/web';
import { useEffect, useState } from 'react';

export function useAuthProfile() {
    const [keycloak] = useKeycloak();
    const [profile, setProfile] = useState<unknown>();

    useEffect(() => {
        keycloak.loadUserProfile().then(value => {
            setProfile(value);
        });
    }, [keycloak, profile]);

    return profile;
}
