import { Accordion } from '@vwfs-bronson/bronson-react';
import React from 'react';

export type AccordionItemProps = {
    children?: React.ReactElement | React.ReactElement[];
    defaultOpen?: boolean;
    title: string | React.ReactNode;
    subtitle?: string | React.ReactNode;
    icon?: string;
    tooltip?: string | React.ReactNode;
    testId?: string;
    contentClassName?: string;
    className?: string;
    titleSplit?: string | React.ReactNode;
};

export const AccordionItem: React.FC<AccordionItemProps> = ({
    defaultOpen,
    title,
    subtitle,
    icon,
    tooltip,
    testId,
    contentClassName,
    className,
    titleSplit,
    children,
}) => {
    return (
        <Accordion.Item
            defaultOpen={defaultOpen}
            title={title}
            subtitle={subtitle}
            icon={icon}
            tooltip={tooltip}
            testId={testId}
            contentClassName={contentClassName}
            className={className}
            titleSplit={titleSplit}
        >
            {children}
        </Accordion.Item>
    );
};

AccordionItem.displayName = 'AccordionItem';
