import React from 'react';
import { Footer } from '@vwfs-bronson/bronson-react';
import { Skeleton } from '../Skeleton';

export const LegalFooterLoadingPlaceholder: React.FC = () => (
    <Footer>
        <div className="u-p-small">
            <Skeleton height={65} />
        </div>
    </Footer>
);
