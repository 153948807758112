import {
    Analytics,
    Defaults,
    ErrorTracking,
    LoginOptions,
    MarketingPreference,
    MarketingPreferences,
    TrackingPageName,
} from './Analytics';

export type TrackingId =
    | 'unpaidDetails'
    | 'onPayment'
    | 'paymentSuccess'
    | 'paymentError'
    | 'requestCertificate'
    | 'amortizationTable'
    | 'earlySettlement'
    | 'earlySettlementSummaryPage'
    | 'earlySettlementSimulation'
    | 'earlySettlementSummary'
    | 'earlySettlementDetails'
    | 'earlySettlementGenerate'
    | 'earlySettlementEnquiry'
    | 'earlySettlementComplete'
    | 'earlySettlementPaymentSuccess'
    | 'earlySettlementPaymentError'
    | 'earlyTerminationLeasingContract'
    | 'earlyTerminationCreditContract'
    | 'earlyTerminationCreditContractView'
    | 'earlyTerminationCreditRequest'
    | 'authenticationVerification'
    | 'confirmIdentity'
    | 'enterSmsCode'
    | 'profile'
    | 'editProfileDocumentUpload'
    | 'editProfileSectionDisplayed'
    | 'dealerRequest'
    | 'dealerSelection'
    | 'digitalRenewalPage'
    | 'digitalRenewal'
    | 'marketingAgreement'
    | 'marketingAgreementNoEmail'
    | 'postbox'
    | 'postboxInvoiceSignUp'
    | 'faq'
    | 'irapCalculation'
    | 'requestAdditionalHelpSituation'
    | 'requestAdditionalHelpIncomeAndExpenditure'
    | 'requestAdditionalHelpBreathingSpace'
    | 'requestAdditionalHelpArrangementToPay'
    | 'requestAdditionalHelpPromiseToPay'
    | 'requestAdditionalHelpEndAgreement'
    | 'contact'
    | 'landingPage'
    | 'landingPageOffer'
    | 'dashboard'
    | 'dashboardAfterLogin'
    | 'dashboardWithoutUnpaid'
    | 'dashboardWithInactiveContracts'
    | 'dashboardAfterRegistration'
    | 'dashboardVehicleDetails'
    | 'changePaymentDate'
    | 'silentContractExtension'
    | 'addContract'
    | 'filterPage'
    | 'requestPrice'
    | 'requests'
    | 'onMotorTax'
    | 'onShowCookiesLayer'
    | 'onAutoLogout'
    | 'introductionPage'
    | 'legalNotice'
    | 'privacyPolicy'
    | 'cookiePolicy'
    | 'modernSlaveryStatement'
    | 'thirdPartyLicenses'
    | 'reportDamage'
    | 'reportDamageRepairApproval'
    | 'reportDamageExpertReport'
    | 'reportDamageTotalLoss'
    | 'reportDamageTheft'
    | 'endOfContractPcpStart'
    | 'endOfContractPcpExchange'
    | 'endOfContractPcpKeep'
    | 'endOfContractPcpHandBack'
    | 'endOfContractPcpRefinance'
    | 'endOfContractPcpRefinanceSuccess'
    | 'endOfContractPcpRefinanceFailure'
    | 'endOfContractPcpSell'
    | 'endOfContractRchStart'
    | 'endOfContractRchNew'
    | 'endOfContractRchExtend'
    | 'endOfContractRchExtension'
    | 'endOfContractRchExtensionSuccess'
    | 'endOfContractRchExtensionFailure'
    | 'endOfContractRchReturn'
    | 'endOfContractRchCallBack'
    | 'endOfContractRchCallBackSuccess'
    | 'endOfContractRchCallBackFailure'
    | 'contactDetailsHookPage'
    | 'kycHookPage'
    | 'dashboardEditSepa'
    | 'useOurMatchmaker'
    | 'startMatchmaker';

export type ErrorTrackingId =
    | 'onApiError'
    | 'notFoundError'
    | 'unauthorizedError'
    | 'accessDeniedError'
    | 'connectionProblemError';

export type ActionType =
    | 'login'
    | 'logout'
    | 'dealerRequest'
    | 'dealerSelection'
    | 'digitalRenewal'
    | 'onExternalLogin'
    | 'onKycHookPageNoContract'
    | 'onContactDetailsHookPageSubmitChanges'
    | 'onContactDetailsHookPageNoChangesCancel'
    | 'onContactDetailsHookPageChangesCancel'
    | 'onContactDetailsHookPageCancel'
    | 'onContactDetailsHookPageCancelAborted'
    | 'onKycHookPageServicePhoneNumber'
    | 'onMarketingAgreementSubmitChanges'
    | 'onMarketingAgreementCancelWithoutChanges'
    | 'onPay'
    | 'onMotorTaxDownload'
    | 'silentContractExtensionSubmit'
    | 'financialDetailsEditDueDateSection'
    | 'financialDetailsEditDueDateSectionSuccess'
    | 'financialDetailsEditDueDateSectionStartTyping'
    | 'financialDetailsEditDueDateSectionCancel'
    | 'financialDetailsEditDueDateSectionValidationError'
    | 'financialDetailsEditDueDateSectionSubmitFailed'
    | 'financialDetailsEditDueDateSectionConfirmation'
    | 'financialDetailsEditDueDateSectionConfirmationCancel'
    | 'financialDetailsEditMileageSection'
    | 'financialDetailsEditMileageSectionSuccess'
    | 'financialDetailsEditMileageSectionStartTyping'
    | 'financialDetailsEditMileageSectionCancel'
    | 'financialDetailsEditMileageSectionValidationError'
    | 'financialDetailsEditMileageSectionSubmitFailed'
    | 'financialDetailsEditIbanSection'
    | 'financialDetailsEditIbanSectionSuccess'
    | 'financialDetailsEditIbanSectionStartTyping'
    | 'financialDetailsEditIbanSectionCancel'
    | 'financialDetailsEditIbanSectionValidationError'
    | 'financialDetailsEditIbanSectionSubmitFailed'
    | 'vehicleDetailsEditLicensePlate'
    | 'vehicleDetailsEditLicensePlateSuccess'
    | 'vehicleDetailsEditLicensePlateCancel'
    | 'vehicleDetailsEditLicensePlateSubmitFailed'
    | 'onRequestAdditionalHelpIncomeAndExpenditurePrint'
    | 'onRequestAdditionalHelpSubmitSuccess'
    | 'onRequestAdditionalHelpSubmitFailed'
    | 'onRequestAdditionalHelpBack'
    | 'onLearnMore'
    | 'chi'
    | 'onReportDamage'
    | 'onBankAccountUpdate'
    | 'onRequestCertificateSuccess'
    | 'onRequestCertificateSubmitFailed'
    | 'onRequestCertificateSubmitAPIError'
    | 'onOpenContractAccordion'
    | 'onStartTypingConfirmIdentity'
    | 'onFormValidationErrorConfirmIdentity'
    | 'onConfirmConfirmIdentiy'
    | 'onConfirmIdentiyAuthFailed'
    | 'onCOVID19'
    | 'onConfirmIdentiyAuthSuccess'
    | 'onConfirmIdentityAuthTechnicalError'
    | 'onConfirmIdentityAborted'
    | 'onStartTypingVerificationPage'
    | 'onFormValidationErrorVerificationPage'
    | 'onConfirmVerificationPageTechnicalError'
    | 'onVerificationPageConfirm'
    | 'onVerificationPageSuccess'
    | 'onEditProfileIdentification'
    | 'onEditProfileAddress'
    | 'onEditProfileContact'
    | 'onEditProfileDealer'
    | 'onEditProfileConsentSettings'
    | 'onEditProfileIdentificationSuccess'
    | 'onEditProfileAddressSuccess'
    | 'onEditProfileContactSuccess'
    | 'onEditProfileConsentSettingsSuccess'
    | 'onEditProfileIdentificationTypedIn'
    | 'onEditProfileAddressTypedIn'
    | 'onEditProfileContactTypedIn'
    | 'onEditProfileConsentSettingsTypedIn'
    | 'onEditProfileCancel'
    | 'onEditProfileIdentificationValidationError'
    | 'onEditProfileAddressValidationError'
    | 'onEditProfileContactValidationError'
    | 'onEditProfileConsentSettingsValidationError'
    | 'onEditProfileIdentificationSubmitFailed'
    | 'onEditProfileAddressSubmitFailed'
    | 'onEditProfileContactSubmitFailed'
    | 'onEditProfileConsentSettingsSubmitFailed'
    | 'onEditProfileConfirmation'
    | 'onEditProfileConfirmationCancel'
    | 'onEditProfileDownload'
    | 'onEditProfileDocumentUploadFailed'
    | 'onEditProfileDocumentUpload'
    | 'onDealerCall'
    | 'onCallDealerRequest'
    | 'onDealerRequestSuccess'
    | 'onDealerCardChoose'
    | 'onDealerCardChange'
    | 'onDealerCardRequest'
    | 'onDealerRequestFailed'
    | 'onDealerSelectionSave'
    | 'onDealerSelectionCall'
    | 'onDigitalRenewal'
    | 'onVehicleDetails'
    | 'onDigitalRenewalStart'
    | 'onDashboardDigitalRenewal'
    | 'onMarketingAgreementSaveYes'
    | 'onMarketingAgreementSaveNo'
    | 'onMarketingAgreementChanged'
    | 'onDashboardEditBankAccountChange'
    | 'onDashboardEditSepaSign'
    | 'onDashboardEditSepaSignSuccess'
    | 'onDashboardEditSepaSignError'
    | 'onOpenEarlySettlementDetails'
    | 'onOpenEarlySettlementSimulation'
    | 'onEditEarlySettlementSummaryLayerSuccess'
    | 'onEarlySettlementSummarySubmitFailed'
    | 'onEarlyTerminationContractValidationError'
    | 'onOpenSuspendInstallment'
    | 'onDashboardChangePaymentDate'
    | 'onAddContractError'
    | 'onMonthlyPaymentInfoTip'
    | 'onCancelContract'
    | 'onChangePaymentDateSubmit'
    | 'onPostboxDownload'
    | 'onPostboxDownloadError'
    | 'onPostboxInvoiceSignUpCancel'
    | 'onPostboxInvoiceSignUpConfirm'
    | 'onPostboxDownloadErrorInvoiceDeactivated'
    | 'onRequestsSuccess'
    | 'onRequestsError'
    | 'onHeyCarLinkClick'
    | 'onClickMarketingCard'
    | 'onDeleteAccountSuccess'
    | 'onConfirmDeleteAccount'
    | 'onConfirmDeleteAccountSuccess'
    | 'onClickEmailLink'
    | 'onClickCallUsLink'
    | 'onEarlySettlementProceedToPayment'
    | 'onEarlySettlementCalculate'
    | 'onEarlySettlementPaymentSelectBankTransfer'
    | 'onEarlySettlementPaymentProceedToPayByCard'
    | 'sepaMandateDownloadError'
    | 'onSepaMandateDownload'
    | 'onAmortizationTableDownload'
    | 'onBundledProductsDownload'
    | 'onFaqQuestion'
    | 'onContactSubmitSuccess'
    | 'onContactSubmitFailed'
    | 'onEarlySettlementGenerateQuote'
    | 'onEarlySettlementEnquiryPrint'
    | 'onEarlySettlementCompletePrint'
    | 'onShowCookiePolicy'
    | 'onAcceptCookies'
    | 'onEnterSmsCodeSuccess'
    | 'onRepairApprovalSuccess'
    | 'onRepairApprovalFailure'
    | 'onIrapDownload'
    | 'onExpertReportSuccess'
    | 'onIrapDownload'
    | 'onExpertReportFailure'
    | 'onExpertReportInsuranceClaimDownload'
    | 'onTotalLossSuccess'
    | 'onTotalLossFailure'
    | 'onTotalLossInsuranceClaimDownload'
    | 'onTotalLossEarlySettlementRedirect'
    | 'onTheftSuccess'
    | 'onTheftFailure'
    | 'onTheftInsuranceClaimDownload'
    | 'onTheftRequestRegistrationCertRedirect'
    | 'onEndOfContractPcpExchangeContactRetailer'
    | 'onEndOfContractPcpExchangeRequestCallback'
    | 'onRequestPriceSubmit'
    | 'onEndOfContractPcpExchangeFinanceOffers'
    | 'onEndOfContractPcpExchangeConfigurator'
    | 'onEndOfContractPcpExchangeBack'
    | 'onEndOfContractPcpExchangeDashboard'
    | 'onEndOfContractPcpKeepBack'
    | 'onEndOfContractPcpKeepDashboard'
    | 'onEndOfContractPcpHandBackPersonalisedPlates'
    | 'onEndOfContractPcpHandBackBvrlaGuidelines'
    | 'onEndOfContractPcpHandBackEndOfContractCharges'
    | 'onEndOfContractPcpHandBackBack'
    | 'onEndOfContractPcpHandBackDashboard'
    | 'onEndOfContractPcpRefinanceBack'
    | 'onEndOfContractPcpRefinanceRequest'
    | 'onEndOfContractPcpSellBack'
    | 'onEndOfContractPcpSellDashboard'
    | 'onEndOfContractPcpSellInspect'
    | 'onEndOfContractRchNewContactRetailer'
    | 'onEndOfContractRchNewFinanceOffers'
    | 'onEndOfContractRchNewNewFromStock'
    | 'onEndOfContractRchNewConfigurator'
    | 'onEndOfContractRchNewBack'
    | 'onEndOfContractRchNewDashboard'
    | 'onEndOfContractRchExtendShortTermExtension'
    | 'onEndOfContractRchExtendFormalExtension'
    | 'onEndOfContractRchExtendBack'
    | 'onEndOfContractRchExtendDashboard'
    | 'onEndOfContractRchExtensionBack'
    | 'onEndOfContractRchExtensionRequest'
    | 'onEndOfContractRchReturnBvrlaGuidelines'
    | 'onEndOfContractRchReturnEndOfContractCharges'
    | 'onEndOfContractRchReturnBack'
    | 'onEndOfContractRchReturnDashboard'
    | 'onEndOfContractRchCallBackBack'
    | 'onEndOfContractRchCallBackRequest'
    | 'onEndOfContractPcpRenewalSelected'
    | 'onEndOfContractPcpDealerData'
    | 'onMatchmakerLogin'
    | 'onDashboardStartMatchmaker'
    | 'onEndOfContractRchStartMatchmaker'
    | 'onEndOfContractPcpStartMatchmaker'
    | 'onContinueToMatchmaker'
    | 'onContractActionEntryPointClick'
    | 'onRequestSubrogationActionEntryPointClick'
    | 'contractRecalculationOnSubmitSuccess'
    | 'onEarlySettlementActionEntryPointClick';

export class AnalyticsTracker {
    private analytics: Analytics;

    constructor(market: string, version: string, releaseDate: string) {
        this.analytics = new Analytics(market, version, releaseDate);
    }

    public clearTimeOut = () => {
        this.analytics.clearTimeOut();
    };

    trackPageView = (
        action: TrackingId,
        defaults: Defaults,
        additionalValue?: string | boolean,
        secondAdditionalValue?: unknown,
        thirdAdditionalValue?: unknown,
    ): void => {
        switch (action) {
            case 'contactDetailsHookPage':
                this.analytics.contactDetailsHookPage.onContactDetailsHookPage(defaults);
                break;
            case 'kycHookPage':
                this.analytics.kycHookPage.onKycHookPage(defaults);
                break;
            case 'onPayment':
                this.analytics.dashboard.outstandingPayment.onPayment(defaults);
                break;
            case 'paymentSuccess':
                this.analytics.dashboard.outstandingPayment.onPaymentSuccess(defaults);
                break;
            case 'paymentError':
                this.analytics.dashboard.outstandingPayment.onPaymentNoSuccess(defaults);
                break;
            case 'requestCertificate':
                this.analytics.requestCertificate.onRequestCertificate(defaults);
                break;
            case 'amortizationTable':
                this.analytics.amortizationTable.onAmortizationTable(defaults);
                break;
            case 'irapCalculation':
                this.analytics.irapPage.onIrapPage(defaults);
                break;
            case 'earlySettlement':
                this.analytics.earlySettlement.onEarlySettlement(defaults);
                break;
            case 'earlySettlementSummaryPage':
                this.analytics.earlySettlement.onEarlySettlementSummaryPage(defaults);
                break;
            case 'earlySettlementSimulation':
                this.analytics.earlySettlement.onEarlySettlementSimulation(defaults);
                break;
            case 'earlySettlementDetails':
                this.analytics.earlySettlement.onEarlySettlementDetails(defaults);
                break;
            case 'earlySettlementSummary':
                this.analytics.earlySettlement.onEarlySettlementSummary(defaults);
                break;
            case 'earlySettlementGenerate':
                this.analytics.earlySettlement.hirePurchase.onEarlySettlementGenerate(
                    additionalValue as string,
                    defaults,
                );
                break;
            case 'irapCalculation':
                this.analytics.irapPage.onIrapPage(defaults);
                break;
            case 'earlySettlementEnquiry':
                this.analytics.earlySettlement.hirePurchase.onEarlySettlementEnquiry(
                    additionalValue as string,
                    defaults,
                );
                break;
            case 'earlySettlementComplete':
                this.analytics.earlySettlement.hirePurchase.onEarlySettlementComplete(
                    additionalValue as string,
                    secondAdditionalValue as boolean,
                    defaults,
                );
                break;
            case 'earlySettlementPaymentSuccess':
                this.analytics.earlySettlement.hirePurchase.onEarlySettlementPaymentSuccess(
                    additionalValue as string,
                    defaults,
                );
                break;
            case 'earlySettlementPaymentError':
                this.analytics.earlySettlement.hirePurchase.onEarlySettlementPaymentError(
                    additionalValue as string,
                    defaults,
                );
                break;
            case 'earlyTerminationLeasingContract':
                this.analytics.earlyTermination.onEarlyTerminationLeasingContract(defaults);
                break;
            case 'earlyTerminationCreditContractView':
                this.analytics.earlyTermination.onEarlyTerminationCreditContractView(defaults);
                break;
            case 'earlyTerminationCreditContract':
                this.analytics.earlyTermination.onEarlyTerminationCreditContractUpload(defaults);
                break;
            case 'earlyTerminationCreditRequest':
                this.analytics.earlyTermination.onEarlyTerminationCreditContractSendRequest(defaults);
                break;
            case 'authenticationVerification':
                this.analytics.authenticationVerification.onVerificationPage(defaults);
                break;
            case 'confirmIdentity':
                this.analytics.authentication.onConfirmIdentityPage(defaults);
                break;
            case 'enterSmsCode':
                this.analytics.authenticationTan.onEnterSmsCode(defaults);
                break;
            case 'profile':
                this.analytics.profile.onProfile(defaults, secondAdditionalValue as MarketingPreferences);
                break;
            case 'editProfileDocumentUpload':
                this.analytics.profile.onEditProfileDocumentUpload(additionalValue as string, defaults);
                break;
            case 'editProfileSectionDisplayed':
                this.analytics.profile.onEditProfileSectionDisplayed(additionalValue as string, defaults);
                break;
            case 'dealerRequest':
                this.analytics.dealerRequest.onDealerRequest(defaults);
                break;
            case 'dealerSelection':
                this.analytics.dealerSelection.onDealerSelection(defaults);
                break;
            case 'digitalRenewalPage':
                this.analytics.digitalRenewal.onDigitalRenewalPage(
                    additionalValue as 'login' | 'registration' | '',
                    defaults,
                );
                break;
            case 'marketingAgreement':
                this.analytics.marketingAgreement.onMarketingAgreement(
                    additionalValue as 'login' | 'registration' | '',
                    secondAdditionalValue as MarketingPreference,
                    defaults,
                );
                break;
            case 'marketingAgreementNoEmail':
                this.analytics.marketingAgreement.onMarketingAgreementNoEmail(defaults);
                break;
            case 'postbox':
                this.analytics.postbox.onPostbox(defaults);
                break;
            case 'postboxInvoiceSignUp':
                this.analytics.postbox.onPostboxInvoiceSignUpView(defaults);
                break;
            case 'faq':
                this.analytics.faq.onFaq(defaults);
                break;
            case 'requestAdditionalHelpSituation':
                this.analytics.requestAdditionalHelp.onRequestAdditionalHelpSituation(defaults);
                break;
            case 'requestAdditionalHelpIncomeAndExpenditure':
                this.analytics.requestAdditionalHelp.onRequestAdditionalHelpIncomeAndExpenditure(defaults);
                break;
            case 'requestAdditionalHelpBreathingSpace':
                this.analytics.requestAdditionalHelp.onRequestAdditionalHelpBreathingSpace(defaults);
                break;
            case 'requestAdditionalHelpArrangementToPay':
                this.analytics.requestAdditionalHelp.onRequestAdditionalHelpArrangementToPay(defaults);
                break;
            case 'requestAdditionalHelpPromiseToPay':
                this.analytics.requestAdditionalHelp.onRequestAdditionalHelpPromiseToPay(defaults);
                break;
            case 'requestAdditionalHelpEndAgreement':
                this.analytics.requestAdditionalHelp.requestAdditionalHelpEndAgreement(defaults);
                break;
            case 'contact':
                this.analytics.contact.onContact(defaults);
                break;
            case 'legalNotice':
                this.analytics.legalNotice.onLegalNotice(defaults);
                break;
            case 'privacyPolicy':
                this.analytics.privacyPolicy.onPrivacyPolicy(defaults);
                break;
            case 'cookiePolicy':
                this.analytics.cookiePolicy.onCookiePolicy(defaults);
                break;
            case 'modernSlaveryStatement':
                this.analytics.modernSlaveryStatement.onModernSlaveryStatement(defaults);
                break;
            case 'thirdPartyLicenses':
                this.analytics.thirdPartyLicenses.onThirdPartyLicenses(defaults);
                break;
            case 'landingPage':
                this.analytics.landingPage.onLandingPage(defaults, additionalValue as boolean);
                break;
            case 'landingPageOffer':
                this.analytics.landingPage.onLandingPageOffer(defaults, additionalValue as boolean);
                break;
            case 'dashboard':
                this.analytics.dashboard.outstandingPayment.onDashboard(
                    '',
                    additionalValue as boolean,
                    secondAdditionalValue as boolean,
                    defaults,
                );
                break;
            case 'dashboardAfterLogin':
                this.analytics.dashboard.outstandingPayment.onDashboard(
                    'login',
                    additionalValue as boolean,
                    secondAdditionalValue as boolean,
                    defaults,
                );
                break;
            case 'dashboardWithoutUnpaid':
                this.analytics.dashboard.onDashboardWithoutUnpaid(
                    additionalValue as 'login' | 'registration' | '',
                    secondAdditionalValue as boolean,
                    defaults,
                    thirdAdditionalValue as boolean,
                );
                break;
            case 'dashboardWithInactiveContracts':
                this.analytics.dashboard.onDashboardWithInactiveContracts(defaults);
                break;
            case 'dashboardAfterRegistration':
                this.analytics.dashboard.outstandingPayment.onDashboard(
                    'registration',
                    additionalValue as boolean,
                    secondAdditionalValue as boolean,
                    defaults,
                );
                break;

            case 'dashboardVehicleDetails':
                this.analytics.dashboard.onVehicleDetails(defaults);
                break;
            case 'changePaymentDate':
                this.analytics.dashboard.onChangePaymentDate(defaults);
                break;
            case 'silentContractExtension':
                this.analytics.endOfContractPcp.silentContractExtension(defaults);
                break;
            case 'addContract':
                this.analytics.dashboard.onAddContract(defaults);
                break;
            case 'filterPage':
                this.analytics.dashboard.filterPage(defaults);
                break;
            case 'requestPrice':
                this.analytics.endOfContractPcp.requestPrice(defaults);
                break;
            case 'dashboardEditSepa':
                this.analytics.dashboardEdit.onSepa(defaults);
                break;
            case 'requests':
                this.analytics.requests.onRequests(defaults);
                break;
            case 'onMotorTax':
                this.analytics.motorPropertyTax.onMotorTax(defaults);
                break;
            case 'unpaidDetails':
                this.analytics.dashboard.outstandingPayment.onOverview(defaults);
                break;
            case 'onShowCookiesLayer':
                this.analytics.cookieWarning.onShowCookiesLayer(additionalValue as string, defaults);
                break;
            case 'introductionPage':
                this.analytics.introduction.onIntroductionPage(defaults, additionalValue as boolean);
                break;
            case 'reportDamage':
                this.analytics.reportDamage.onReportDamage(defaults);
                break;
            case 'reportDamageRepairApproval':
                this.analytics.reportDamage.onRepairApproval(defaults);
                break;
            case 'reportDamageExpertReport':
                this.analytics.reportDamage.onExpertReport(defaults);
                break;
            case 'reportDamageTotalLoss':
                this.analytics.reportDamage.onTotalLoss(defaults);
                break;
            case 'reportDamageTheft':
                this.analytics.reportDamage.onTheft(defaults);
                break;
            case 'onAutoLogout':
                this.analytics.error.onAutoLogout(defaults, additionalValue as boolean);
                break;
            case 'endOfContractPcpStart':
                this.analytics.endOfContractPcp.onEndOfContractPcpStart(defaults);
                break;
            case 'endOfContractPcpExchange':
                this.analytics.endOfContractPcp.onEndOfContractPcpExchange(defaults);
                break;
            case 'endOfContractPcpKeep':
                this.analytics.endOfContractPcp.onEndOfContractPcpKeep(defaults);
                break;
            case 'endOfContractPcpHandBack':
                this.analytics.endOfContractPcp.onEndOfContractPcpHandBack(defaults);
                break;
            case 'endOfContractPcpRefinance':
                this.analytics.endOfContractPcp.onEndOfContractPcpRefinance(defaults);
                break;
            case 'endOfContractPcpRefinanceSuccess':
                this.analytics.endOfContractPcp.onEndOfContractPcpRefinanceSuccess(defaults);
                break;
            case 'endOfContractPcpRefinanceFailure':
                this.analytics.endOfContractPcp.onEndOfContractPcpRefinanceFailure(defaults);
                break;
            case 'endOfContractPcpSell':
                this.analytics.endOfContractPcp.onEndOfContractPcpSell(defaults);
                break;
            case 'endOfContractRchStart':
                this.analytics.endOfContractRch.onEndOfContractRchStart(defaults);
                break;
            case 'endOfContractRchNew':
                this.analytics.endOfContractRch.onEndOfContractRchNew(defaults);
                break;
            case 'endOfContractRchExtend':
                this.analytics.endOfContractRch.onEndOfContractRchExtend(defaults);
                break;
            case 'endOfContractRchExtension':
                this.analytics.endOfContractRch.onEndOfContractRchExtension(defaults);
                break;
            case 'endOfContractRchExtensionSuccess':
                this.analytics.endOfContractRch.onEndOfContractRchExtensionSuccess(defaults);
                break;
            case 'endOfContractRchExtensionFailure':
                this.analytics.endOfContractRch.onEndOfContractRchExtensionFailure(defaults);
                break;
            case 'endOfContractRchReturn':
                this.analytics.endOfContractRch.onEndOfContractRchReturn(defaults);
                break;
            case 'endOfContractRchCallBack':
                this.analytics.endOfContractRch.onEndOfContractRchCallBack(additionalValue as string, defaults);
                break;
            case 'endOfContractRchCallBackSuccess':
                this.analytics.endOfContractRch.onEndOfContractRchCallBackSuccess(additionalValue as string, defaults);
                break;
            case 'endOfContractRchCallBackFailure':
                this.analytics.endOfContractRch.onEndOfContractRchCallBackFailure(additionalValue as string, defaults);
                break;
            case 'useOurMatchmaker':
                this.analytics.matchmaker.onUseOurMatchmaker(defaults, additionalValue as boolean);
                break;
            case 'startMatchmaker':
                this.analytics.matchmaker.onStartMatchmaker(defaults);
                break;
            default:
                break;
        }
    };

    trackErrorPageView = (
        action: ErrorTrackingId,
        defaults: Defaults,
        additionalValue: string,
        secondAdditionalValue?: string,
    ): void => {
        switch (action) {
            case 'onApiError':
                this.analytics.error.onApiError(defaults, additionalValue, secondAdditionalValue);
                break;
            case 'unauthorizedError':
                this.analytics.error.onNotAuthorizedError(defaults, additionalValue);
                break;
            case 'notFoundError':
                this.analytics.error.onPageNotFoundError(defaults, additionalValue);
                break;
            case 'accessDeniedError':
                this.analytics.error.onAccessDeniedError(defaults, additionalValue);
                break;
            case 'connectionProblemError':
                this.analytics.error.onConnectionProblemError(defaults, additionalValue, secondAdditionalValue);
                break;
            default:
                break;
        }
    };

    trackAction = (
        action: ActionType,
        defaults: Defaults,
        additionalValue?: unknown,
        secondAdditionalValue?: unknown,
        thirdAdditionalValue?: unknown,
        forthAdditionalValue?: unknown,
    ): void => {
        switch (action) {
            case 'login':
                this.analytics.login.onShowLogin(defaults, additionalValue as string, secondAdditionalValue as boolean);
                break;
            case 'logout':
                this.analytics.mainNavigation.onLogout(
                    defaults,
                    additionalValue as string,
                    secondAdditionalValue as boolean,
                );
                break;
            case 'dealerRequest':
                this.analytics.mainNavigation.onDealerRequestClicked(defaults, additionalValue as string);
                break;
            case 'silentContractExtensionSubmit':
                this.analytics.endOfContractPcp.silentContractExtensionSubmit(defaults);
                break;
            case 'onExternalLogin':
                this.analytics.login.onExternalLogin(defaults, additionalValue as LoginOptions);
                break;
            case 'onContactDetailsHookPageSubmitChanges':
                this.analytics.contactDetailsHookPage.onSubmitChanges(defaults);
                break;
            case 'onContactDetailsHookPageNoChangesCancel':
                this.analytics.contactDetailsHookPage.onNoChangesCancel(defaults);
                break;
            case 'onContactDetailsHookPageChangesCancel':
                this.analytics.contactDetailsHookPage.onChangesCancel(defaults);
                break;
            case 'onContactDetailsHookPageCancel':
                this.analytics.contactDetailsHookPage.onCancel(defaults);
                break;
            case 'onContactDetailsHookPageCancelAborted':
                this.analytics.contactDetailsHookPage.onCancelAborted(defaults);
                break;
            case 'onKycHookPageNoContract':
                this.analytics.kycHookPage.onNoContract(defaults);
                break;
            case 'onKycHookPageServicePhoneNumber':
                this.analytics.kycHookPage.onServicePhoneNumber(defaults);
                break;
            case 'onClickEmailLink':
                this.analytics.contactTeaser.onClickEmailLink(
                    defaults,
                    additionalValue as string,
                    secondAdditionalValue as string,
                );
                break;
            case 'onClickCallUsLink':
                this.analytics.contactTeaser.onClickCallUsLink(
                    defaults,
                    additionalValue as string,
                    secondAdditionalValue as string,
                );
                break;
            case 'onPay':
                this.analytics.dashboard.outstandingPayment.onPay(defaults);
                break;
            case 'onMotorTaxDownload':
                this.analytics.motorPropertyTax.onMotorTaxDownload(defaults);
                break;
            case 'financialDetailsEditDueDateSection':
                this.analytics.dashboardEdit.onEditDashboardSection(
                    'Financial Details',
                    'Change of due date',
                    defaults,
                );
                break;
            case 'financialDetailsEditDueDateSectionSuccess':
                this.analytics.dashboardEdit.onSuccessEditDashboardSection(
                    'Financial Details',
                    'Change of due date',
                    defaults,
                );
                break;
            case 'financialDetailsEditDueDateSectionStartTyping':
                this.analytics.dashboardEdit.onStartTypingEditDashboardSection(
                    'Financial Details',
                    'Change of due date',
                    defaults,
                );
                break;
            case 'financialDetailsEditDueDateSectionCancel':
                this.analytics.dashboardEdit.onCancelEditDashboardSection(
                    'Financial Details',
                    'Change of due date',
                    defaults,
                );
                break;
            case 'financialDetailsEditDueDateSectionValidationError':
                this.analytics.dashboardEdit.onFormValidationErrorEditDashboardSection(
                    'Financial Details',
                    'Change of due date',
                    additionalValue as string,
                    defaults,
                );
                break;
            case 'financialDetailsEditDueDateSectionSubmitFailed':
                this.analytics.dashboardEdit.onSubmitErrorEditDashboardSection(
                    'Financial Details',
                    'Change of due date',
                    defaults,
                );
                break;
            case 'financialDetailsEditDueDateSectionConfirmation':
                this.analytics.dashboardEdit.onConfirmEditDashboardSection(
                    'Financial Details',
                    'Change of due date',
                    defaults,
                );
                break;
            case 'financialDetailsEditDueDateSectionConfirmationCancel':
                this.analytics.dashboardEdit.onCancelConfirmationEditDashboardSection(
                    'Financial Details',
                    'Change of due date',
                    defaults,
                );
                break;
            case 'financialDetailsEditMileageSection':
                this.analytics.dashboardEdit.onEditDashboardSection('Financial Details', 'Change of mileage', defaults);
                break;
            case 'financialDetailsEditMileageSectionSuccess':
                this.analytics.dashboardEdit.onSuccessEditDashboardSection(
                    'Financial Details',
                    'Change of mileage',
                    defaults,
                );
                break;
            case 'financialDetailsEditMileageSectionStartTyping':
                this.analytics.dashboardEdit.onStartTypingEditDashboardSection(
                    'Financial Details',
                    'Change of mileage',
                    defaults,
                );
                break;
            case 'financialDetailsEditMileageSectionCancel':
                this.analytics.dashboardEdit.onCancelEditDashboardSection(
                    'Financial Details',
                    'Change of mileage',
                    defaults,
                );
                break;
            case 'financialDetailsEditMileageSectionValidationError':
                this.analytics.dashboardEdit.onFormValidationErrorEditDashboardSection(
                    'Financial Details',
                    'Change of mileage',
                    additionalValue as string,
                    defaults,
                );
                break;
            case 'financialDetailsEditMileageSectionSubmitFailed':
                this.analytics.dashboardEdit.onSubmitErrorEditDashboardSection(
                    'Financial Details',
                    'Change of mileage',
                    defaults,
                );
                break;
            case 'financialDetailsEditIbanSection':
                this.analytics.dashboardEdit.onEditDashboardSection(
                    'Financial Details',
                    'Payment Information',
                    defaults,
                );
                break;
            case 'financialDetailsEditIbanSectionSuccess':
                this.analytics.dashboardEdit.onSuccessEditDashboardSection(
                    'Financial Details',
                    'Payment Information',
                    defaults,
                );
                break;
            case 'financialDetailsEditIbanSectionStartTyping':
                this.analytics.dashboardEdit.onStartTypingEditDashboardSection(
                    'Financial Details',
                    'Payment Information',
                    defaults,
                );
                break;
            case 'financialDetailsEditIbanSectionCancel':
                this.analytics.dashboardEdit.onCancelEditDashboardSection(
                    'Financial Details',
                    'Payment Information',
                    defaults,
                );
                break;
            case 'financialDetailsEditIbanSectionValidationError':
                this.analytics.dashboardEdit.onFormValidationErrorEditDashboardSection(
                    'Financial Details',
                    'Payment Information',
                    additionalValue as string,
                    defaults,
                );
                break;
            case 'financialDetailsEditIbanSectionSubmitFailed':
                this.analytics.dashboardEdit.onSubmitErrorEditDashboardSection(
                    'Financial Details',
                    'Payment Information',
                    defaults,
                );
                break;
            case 'vehicleDetailsEditLicensePlate':
                this.analytics.dashboardEdit.onEditDashboardSection('Vehicle Details', 'License Plate', defaults);
                break;
            case 'vehicleDetailsEditLicensePlateSuccess':
                this.analytics.dashboardEdit.onSuccessEditDashboardSection(
                    'Vehicle Details',
                    'License Plate',
                    defaults,
                );
                break;
            case 'vehicleDetailsEditLicensePlateCancel':
                this.analytics.dashboardEdit.onCancelEditDashboardSection('Vehicle Details', 'License Plate', defaults);
                break;
            case 'vehicleDetailsEditLicensePlateSubmitFailed':
                this.analytics.dashboardEdit.onSubmitErrorEditDashboardSection(
                    'Vehicle Details',
                    'License Plate',
                    defaults,
                );
                break;
            case 'onLearnMore':
                this.analytics.dashboard.onLearnMore(additionalValue as string, defaults);
                break;
            case 'chi':
                this.analytics.dashboard.chi(defaults);
                break;
            case 'onReportDamage':
                this.analytics.dashboard.onReportDamage(defaults);
                break;
            case 'onBankAccountUpdate':
                this.analytics.dashboard.onBankAccountUpdateClick(defaults);
                break;
            case 'onContractActionEntryPointClick':
                this.analytics.dashboard.onClickContractActionEntryPoint(additionalValue as string, defaults);
                break;
            case 'onRequestSubrogationActionEntryPointClick':
                this.analytics.requestSubrogation.onClickEntryPointAction(additionalValue as string, defaults);
                break;
            case 'onEarlySettlementActionEntryPointClick':
                this.analytics.earlySettlement.onClickEntryPointAction(additionalValue as string, defaults);
                break;
            case 'contractRecalculationOnSubmitSuccess':
                this.analytics.contractRecalculation.onContractRecalculationFormSubmitSuccess(
                    additionalValue as string,
                    secondAdditionalValue as string,
                    defaults,
                );
                break;
            case 'onRequestCertificateSuccess':
                this.analytics.requestCertificate.onSuccessRequestCertificate(additionalValue as string, defaults);
                break;
            case 'onRequestCertificateSubmitFailed':
                this.analytics.requestCertificate.onRequestCertificateSubmitFailed(
                    additionalValue as string,
                    secondAdditionalValue as ErrorTracking,
                    defaults,
                );
                break;
            case 'onRequestCertificateSubmitAPIError':
                this.analytics.requestCertificate.onRequestCertificateSubmitAPIError(
                    additionalValue as string,
                    secondAdditionalValue as ErrorTracking,
                    defaults,
                );
                break;
            case 'onOpenContractAccordion':
                this.analytics.dashboardEdit.onOpenDashboardAccordion(additionalValue as string, defaults);
                break;
            case 'onStartTypingConfirmIdentity':
                this.analytics.authentication.onStartTypingConfirmIdentityPage(defaults);
                break;
            case 'onFormValidationErrorConfirmIdentity':
                this.analytics.authentication.onFormValidationErrorConfirmIdentityPage(
                    additionalValue as string,
                    defaults,
                );
                break;
            case 'onConfirmConfirmIdentiy':
                this.analytics.authentication.onConfirmConfirmIdentityPage(defaults);
                break;
            case 'onConfirmIdentiyAuthFailed':
                this.analytics.authenticationFailed.onConfirmIdentityPageAuthFailed(
                    defaults,
                    additionalValue as string,
                );
                break;
            case 'onCOVID19':
                this.analytics.earlySettlement.onCOVID19(defaults, additionalValue as ErrorTracking);
                break;
            case 'onConfirmIdentiyAuthSuccess':
                this.analytics.authentication.onConfirmIdentityPageConfirmSuccess(defaults, additionalValue as boolean);
                break;
            case 'onConfirmIdentityAuthTechnicalError':
                this.analytics.authentication.onConfirmIdentityPageTechnicalError(
                    defaults,
                    additionalValue as ErrorTracking,
                );
                break;
            case 'onConfirmIdentityAborted':
                this.analytics.authenticationFailed.onConfirmIdentityAborted(
                    additionalValue as string,
                    defaults,
                    secondAdditionalValue as boolean,
                    thirdAdditionalValue as string,
                );
                break;
            case 'onStartTypingVerificationPage':
                this.analytics.authenticationVerification.onStartTypingVerificationPage(defaults);
                break;
            case 'onFormValidationErrorVerificationPage':
                this.analytics.authenticationVerification.onFormValidationErrorVerificationPage(
                    additionalValue as string,
                    defaults,
                );
                break;
            case 'onVerificationPageConfirm':
                this.analytics.authenticationVerification.onVerificationPageConfirmButton(defaults);
                break;
            case 'onVerificationPageSuccess':
                this.analytics.authenticationVerification.onVerificationPageConfirmSuccess(
                    defaults,
                    additionalValue as boolean,
                );
                break;
            case 'onConfirmVerificationPageTechnicalError':
                this.analytics.authenticationVerification.onConfirmVerificationPageTechnicalError(
                    defaults,
                    additionalValue as ErrorTracking,
                );
                break;
            case 'onEditProfileIdentification':
                this.analytics.profile.onEditProfile('Identification', defaults);
                break;
            case 'onEditProfileAddress':
                this.analytics.profile.onEditProfile('Address', defaults);
                break;
            case 'onEditProfileContact':
                this.analytics.profile.onEditProfile('Contact', defaults);
                break;
            case 'onEditProfileDealer':
                this.analytics.profile.onEditProfile('Dealer', defaults);
                break;
            case 'onEditProfileConsentSettings':
                this.analytics.profile.onEditProfile('Consent settings', defaults, additionalValue as string);
                break;
            case 'onEditProfileIdentificationSuccess':
                this.analytics.profile.onEditProfileSuccess('Identification', defaults);
                break;
            case 'onEditProfileAddressSuccess':
                this.analytics.profile.onEditProfileSuccess('Address', defaults);
                break;
            case 'onEditProfileContactSuccess':
                this.analytics.profile.onEditProfileSuccess('Contact', defaults);
                break;
            case 'onEditProfileConsentSettingsSuccess':
                this.analytics.profile.onEditProfileSuccess(
                    'Consent settings',
                    defaults,
                    additionalValue as string,
                    secondAdditionalValue as string,
                    thirdAdditionalValue as MarketingPreference,
                );
                break;
            case 'onEditProfileIdentificationTypedIn':
                this.analytics.profile.onEditProfileTypedIn('Identification', defaults);
                break;
            case 'onEditProfileAddressTypedIn':
                this.analytics.profile.onEditProfileTypedIn('Address', defaults);
                break;
            case 'onEditProfileContactTypedIn':
                this.analytics.profile.onEditProfileTypedIn('Contact', defaults);
                break;
            case 'onEditProfileConsentSettingsTypedIn':
                this.analytics.profile.onEditProfileTypedIn('Consent settings', defaults, additionalValue as string);
                break;
            case 'onEditProfileCancel':
                this.analytics.profile.onEditProfileCancel(additionalValue as string, defaults);
                break;
            case 'onEditProfileIdentificationValidationError':
                this.analytics.profile.onEditProfileValidationError(
                    additionalValue as string,
                    'Identification',
                    defaults,
                );
                break;
            case 'onEditProfileAddressValidationError':
                this.analytics.profile.onEditProfileValidationError(additionalValue as string, 'Address', defaults);
                break;
            case 'onEditProfileContactValidationError':
                this.analytics.profile.onEditProfileValidationError(additionalValue as string, 'Contact', defaults);
                break;
            case 'onEditProfileConsentSettingsValidationError':
                this.analytics.profile.onEditProfileValidationError(
                    additionalValue as string,
                    'Consent settings',
                    defaults,
                    secondAdditionalValue as string,
                );
                break;
            case 'onEditProfileIdentificationSubmitFailed':
                this.analytics.profile.onEditProfileSubmitFailed('Identification', defaults);
                break;
            case 'onEditProfileAddressSubmitFailed':
                this.analytics.profile.onEditProfileSubmitFailed('Address', defaults);
                break;
            case 'onEditProfileContactSubmitFailed':
                this.analytics.profile.onEditProfileSubmitFailed('Contact', defaults);
                break;
            case 'onEditProfileConsentSettingsSubmitFailed':
                this.analytics.profile.onEditProfileSubmitFailed(
                    'Consent settings',
                    defaults,
                    additionalValue as string,
                );
                break;
            case 'onEditProfileConfirmation':
                this.analytics.profile.onEditProfileConfirmation(additionalValue as string, defaults);
                break;
            case 'onEditProfileConfirmationCancel':
                this.analytics.profile.onEditProfileConfirmationCancel(additionalValue as string, defaults);
                break;
            case 'onEditProfileDownload':
                this.analytics.profile.onEditProfileDownload(
                    additionalValue as string,
                    secondAdditionalValue as string,
                    defaults,
                );
                break;
            case 'onEditProfileDocumentUpload':
                this.analytics.profile.onEditProfileDocumentUpload(additionalValue as string, defaults);
                break;
            case 'onEditProfileDocumentUploadFailed':
                this.analytics.profile.onEditProfileDocumentUploadFailed(
                    additionalValue as string,
                    secondAdditionalValue as ErrorTracking,
                    defaults,
                );
                break;
            case 'onDeleteAccountSuccess':
                this.analytics.profile.onDeleteAccountSuccess(defaults);
                break;
            case 'onConfirmDeleteAccount':
                this.analytics.profile.onConfirmDeleteAccount(defaults);
                break;
            case 'onConfirmDeleteAccountSuccess':
                this.analytics.profile.onConfirmDeleteAccountSuccess(defaults, additionalValue as boolean);
                break;
            case 'onDealerCall':
                this.analytics.profile.onCallDealerClicked(defaults);
                break;
            case 'onCallDealerRequest':
                this.analytics.profile.onCallDealerRequest(defaults);
                break;
            case 'onEditEarlySettlementSummaryLayerSuccess':
                this.analytics.earlySettlement.onEarlySettlementSummaryLayerDisplayed(defaults);
                break;
            case 'onEarlySettlementSummarySubmitFailed':
                this.analytics.earlySettlement.onEarlySettlementSummarySubmitFailed(
                    defaults,
                    additionalValue as ErrorTracking,
                );
                break;
            case 'onEarlySettlementProceedToPayment':
                this.analytics.earlySettlement.onEarlySettlementProceedToPayment(defaults);
                break;
            case 'onEarlySettlementCalculate':
                this.analytics.earlySettlement.onEarlySettlementCalculate(defaults);
                break;
            case 'onEarlyTerminationContractValidationError':
                this.analytics.earlyTermination.onEarlyTerminationCreditContractValidationError(
                    additionalValue as string,
                    defaults,
                );
                break;
            case 'onOpenEarlySettlementDetails':
                this.analytics.earlySettlement.onEarlySettlementDetails(defaults);
                break;
            case 'onOpenEarlySettlementSimulation':
                this.analytics.earlySettlement.onEarlySettlementSimulation(defaults);
                break;
            case 'onOpenSuspendInstallment':
                this.analytics.dashboard.onOpenSuspendInstallment(defaults);
                break;
            case 'onDashboardChangePaymentDate':
                this.analytics.dashboard.onDashboardChangePaymentDate(defaults);
                break;
            case 'onAddContractError':
                this.analytics.dashboard.onAddContractError(defaults);
                break;
            case 'onMonthlyPaymentInfoTip':
                this.analytics.dashboard.onMonthlyPaymentInfoTip(defaults);
                break;
            case 'onCancelContract':
                this.analytics.dashboard.onCancelNonStandardContract(defaults);
                break;
            case 'onChangePaymentDateSubmit':
                this.analytics.dashboard.onChangePaymentDateSubmit(defaults);
                break;
            case 'onDealerRequestSuccess':
                this.analytics.dealerRequest.onDealerRequestSuccess(defaults, additionalValue as string);
                break;
            case 'onDealerCardChoose':
                this.analytics.dealerCard.onChooseDealer(defaults, additionalValue as string);
                break;
            case 'onDealerCardChange':
                this.analytics.dealerCard.onChangeDealer(defaults, additionalValue as string);
                break;
            case 'onDealerCardRequest':
                this.analytics.dealerCard.onRequestDealer(defaults, additionalValue as string);
                break;
            case 'onDealerRequestFailed':
                this.analytics.dealerRequest.onDealerRequestFailed(
                    defaults,
                    additionalValue as string,
                    secondAdditionalValue as ErrorTracking,
                );
                break;
            case 'onDealerSelectionSave':
                this.analytics.dealerSelection.onDealerSelectionSave(defaults);
                break;
            case 'onDealerSelectionCall':
                this.analytics.dealerSelection.onDealerSelectionCall(defaults);
                break;
            case 'onMarketingAgreementSaveYes':
                this.analytics.marketingAgreement.onMarketingAgreementSaveYes(
                    defaults,
                    additionalValue as MarketingPreference,
                );
                break;
            case 'onMarketingAgreementSaveNo':
                this.analytics.marketingAgreement.onMarketingAgreementSaveNo(defaults);
                break;
            case 'onMarketingAgreementSubmitChanges':
                this.analytics.marketingAgreement.onMarketingAgreementSubmitChanges(defaults);
                break;
            case 'onMarketingAgreementCancelWithoutChanges':
                this.analytics.marketingAgreement.onMarketingAgreementCancelWithoutChanges(defaults);
                break;
            case 'onMarketingAgreementChanged':
                this.analytics.marketingAgreement.onMarketingAgreementChanged(
                    defaults,
                    additionalValue as MarketingPreference,
                    secondAdditionalValue as MarketingPreference,
                    thirdAdditionalValue as string,
                    forthAdditionalValue as TrackingPageName,
                );
                break;
            case 'onDashboardEditBankAccountChange':
                this.analytics.dashboardEdit.onBankAccountChange(defaults);
                break;
            case 'onDashboardEditSepaSign':
                this.analytics.dashboardEdit.onSepaSign(defaults);
                break;
            case 'onDashboardEditSepaSignSuccess':
                this.analytics.dashboardEdit.onSepaSignSuccess(defaults);
                break;
            case 'onDashboardEditSepaSignError':
                this.analytics.dashboardEdit.onSepaSignError(defaults);
                break;
            case 'onPostboxDownload':
                this.analytics.postbox.onPostboxDownload(additionalValue as string, defaults);
                break;
            case 'onPostboxDownloadError':
                this.analytics.postbox.onPostboxDownloadError(
                    additionalValue as string,
                    secondAdditionalValue as ErrorTracking,
                    defaults,
                );
                break;
            case 'onPostboxInvoiceSignUpCancel':
                this.analytics.postbox.onPostboxInvoiceSignUpCancel(defaults);
                break;
            case 'onPostboxInvoiceSignUpConfirm':
                this.analytics.postbox.onPostboxInvoiceSignUpConfirm(defaults);
                break;
            case 'onPostboxDownloadErrorInvoiceDeactivated':
                this.analytics.postbox.onPostboxDownloadErrorInvoiceDeactivated(defaults);
                break;
            case 'onClickMarketingCard':
                this.analytics.landingPage.onClickMarketingCard(additionalValue as string, defaults);
                break;
            case 'onHeyCarLinkClick':
                this.analytics.earlySettlement.onHeyCarLinkClick(defaults);
                break;
            case 'onRequestsSuccess':
                this.analytics.requests.onRequestSuccess(defaults, additionalValue as string);
                break;
            case 'onRequestsError':
                this.analytics.requests.onRequestFailure(defaults, additionalValue as string);
                break;
            case 'sepaMandateDownloadError':
                this.analytics.dashboardEdit.sepaMandate.sepaMandateDownloadError(
                    defaults,
                    additionalValue as ErrorTracking,
                    secondAdditionalValue as string,
                );
                break;
            case 'onSepaMandateDownload':
                this.analytics.dashboardEdit.sepaMandate.onSepaMandateDownload(defaults, additionalValue as string);
                break;
            case 'onAmortizationTableDownload':
                this.analytics.amortizationTable.onAmortizationTableDownload(defaults);
                break;
            case 'onBundledProductsDownload':
                this.analytics.amortizationTable.onBundledProductsDownload(defaults, additionalValue as string);
                break;
            case 'onFaqQuestion':
                this.analytics.faq.onFaqQuestion(additionalValue as string, defaults);
                break;
            case 'onRequestAdditionalHelpIncomeAndExpenditurePrint':
                this.analytics.requestAdditionalHelp.onRequestAdditionalHelpIncomeAndExpenditurePrint(defaults);
                break;
            case 'onRequestAdditionalHelpSubmitSuccess':
                this.analytics.requestAdditionalHelp.onRequestAdditionalHelpSubmitSuccess(defaults);
                break;
            case 'onRequestAdditionalHelpSubmitFailed':
                this.analytics.requestAdditionalHelp.onRequestAdditionalHelpSubmitFailed(defaults);
                break;
            case 'onRequestAdditionalHelpBack':
                this.analytics.requestAdditionalHelp.onRequestAdditionalHelpBack(defaults);
                break;
            case 'onContactSubmitSuccess':
                this.analytics.contact.onContactSubmitSuccess(additionalValue as string, defaults);
                break;
            case 'onContactSubmitFailed':
                this.analytics.contact.onContactSubmitFailed(additionalValue as string, defaults);
                break;
            case 'onEarlySettlementGenerateQuote':
                this.analytics.earlySettlement.hirePurchase.onEarlySettlementGenerateQuote(
                    additionalValue as string,
                    secondAdditionalValue as string,
                    defaults,
                );
                break;
            case 'onEarlySettlementEnquiryPrint':
                this.analytics.earlySettlement.hirePurchase.onEarlySettlementEnquiryPrint(
                    additionalValue as string,
                    defaults,
                );
                break;
            case 'onEarlySettlementCompletePrint':
                this.analytics.earlySettlement.hirePurchase.onEarlySettlementCompletePrint(
                    additionalValue as string,
                    secondAdditionalValue as boolean,
                    defaults,
                );
                break;
            case 'onEarlySettlementPaymentSelectBankTransfer':
                this.analytics.earlySettlement.hirePurchase.onEarlySettlementPaymentSelectBankTransfer(
                    additionalValue as string,
                    secondAdditionalValue as boolean,
                    defaults,
                );
                break;
            case 'onEarlySettlementPaymentProceedToPayByCard':
                this.analytics.earlySettlement.hirePurchase.onEarlySettlementPaymentProceedToPayByCard(
                    additionalValue as string,
                    secondAdditionalValue as boolean,
                    defaults,
                );
                break;
            case 'onAcceptCookies':
                this.analytics.cookieWarning.onAcceptCookies(additionalValue as string, defaults);
                break;
            case 'onShowCookiePolicy':
                this.analytics.cookieWarning.onShowCookiePolicy(additionalValue as string, defaults);
                break;
            case 'onEnterSmsCodeSuccess':
                this.analytics.authenticationTan.onEnterSmsCodeSuccess(defaults, additionalValue as boolean);
                break;
            case 'onRepairApprovalSuccess':
                this.analytics.reportDamage.onRepairApprovalSuccess(defaults);
                break;
            case 'onRepairApprovalFailure':
                this.analytics.reportDamage.onRepairApprovalFailure(additionalValue as ErrorTracking, defaults);
                break;
            case 'onIrapDownload':
                this.analytics.irapPage.onIrapDownload(defaults);
                break;
            case 'onExpertReportSuccess':
                this.analytics.reportDamage.onExpertReportSuccess(defaults);
                break;
            case 'onExpertReportFailure':
                this.analytics.reportDamage.onExpertReportFailure(additionalValue as ErrorTracking, defaults);
                break;
            case 'onIrapDownload':
                this.analytics.irapPage.onIrapDownload(defaults);
                break;
            case 'onExpertReportInsuranceClaimDownload':
                this.analytics.reportDamage.onExpertReportInsuranceClaimDownload(defaults);
                break;
            case 'onTotalLossSuccess':
                this.analytics.reportDamage.onTotalLossSuccess(defaults);
                break;
            case 'onTotalLossFailure':
                this.analytics.reportDamage.onTotalLossFailure(additionalValue as ErrorTracking, defaults);
                break;
            case 'onTotalLossInsuranceClaimDownload':
                this.analytics.reportDamage.onTotalLossInsuranceClaimDownload(defaults);
                break;
            case 'onTotalLossEarlySettlementRedirect':
                this.analytics.reportDamage.onTotalLossEarlySettlementRedirect(defaults);
                break;
            case 'onTheftSuccess':
                this.analytics.reportDamage.onTheftSuccess(defaults);
                break;
            case 'onTheftFailure':
                this.analytics.reportDamage.onTheftFailure(additionalValue as ErrorTracking, defaults);
                break;
            case 'onTheftInsuranceClaimDownload':
                this.analytics.reportDamage.onTheftInsuranceClaimDownload(defaults);
                break;
            case 'onTheftRequestRegistrationCertRedirect':
                this.analytics.reportDamage.onTheftRequestRegistrationCertRedirect(defaults);
                break;
            case 'onEndOfContractPcpExchangeContactRetailer':
                this.analytics.endOfContractPcp.onEndOfContractPcpExchangeContactRetailer(defaults);
                break;
            case 'onRequestPriceSubmit':
                this.analytics.endOfContractPcp.onRequestPriceSubmit(defaults);
                break;
            case 'onEndOfContractPcpExchangeRequestCallback':
                this.analytics.endOfContractPcp.onEndOfContractPcpExchangeRequestCallback(defaults);
                break;
            case 'onEndOfContractPcpExchangeFinanceOffers':
                this.analytics.endOfContractPcp.onEndOfContractPcpExchangeFinanceOffers(defaults);
                break;
            case 'onEndOfContractPcpExchangeConfigurator':
                this.analytics.endOfContractPcp.onEndOfContractPcpExchangeConfigurator(defaults);
                break;
            case 'onEndOfContractPcpExchangeBack':
                this.analytics.endOfContractPcp.onEndOfContractPcpExchangeBack(defaults);
                break;
            case 'onEndOfContractPcpExchangeDashboard':
                this.analytics.endOfContractPcp.onEndOfContractPcpExchangeDashboard(defaults);
                break;
            case 'onEndOfContractPcpKeepBack':
                this.analytics.endOfContractPcp.onEndOfContractPcpKeepBack(defaults);
                break;
            case 'onEndOfContractPcpKeepDashboard':
                this.analytics.endOfContractPcp.onEndOfContractPcpKeepDashboard(defaults);
                break;
            case 'onEndOfContractPcpHandBackPersonalisedPlates':
                this.analytics.endOfContractPcp.onEndOfContractPcpHandBackPersonalisedPlates(
                    additionalValue as string,
                    defaults,
                );
                break;
            case 'onEndOfContractPcpHandBackBvrlaGuidelines':
                this.analytics.endOfContractPcp.onEndOfContractPcpHandBackBvrlaGuidelines(
                    additionalValue as string,
                    defaults,
                );
                break;
            case 'onEndOfContractPcpHandBackEndOfContractCharges':
                this.analytics.endOfContractPcp.onEndOfContractPcpHandBackEndOfContractCharges(
                    additionalValue as string,
                    defaults,
                );
                break;
            case 'onEndOfContractPcpHandBackBack':
                this.analytics.endOfContractPcp.onEndOfContractPcpHandBackBack(defaults);
                break;
            case 'onEndOfContractPcpHandBackDashboard':
                this.analytics.endOfContractPcp.onEndOfContractPcpHandBackDashboard(defaults);
                break;
            case 'onEndOfContractPcpRefinanceBack':
                this.analytics.endOfContractPcp.onEndOfContractPcpRefinanceBack(defaults);
                break;
            case 'onEndOfContractPcpRefinanceRequest':
                this.analytics.endOfContractPcp.onEndOfContractPcpRefinanceRequest(defaults);
                break;
            case 'onEndOfContractPcpSellBack':
                this.analytics.endOfContractPcp.onEndOfContractPcpSellBack(defaults);
                break;
            case 'onEndOfContractPcpSellDashboard':
                this.analytics.endOfContractPcp.onEndOfContractPcpSellDashboard(defaults);
                break;
            case 'onEndOfContractPcpSellInspect':
                this.analytics.endOfContractPcp.onEndOfContractPcpSellInspect(additionalValue as string, defaults);
                break;
            case 'onEndOfContractRchNewContactRetailer':
                this.analytics.endOfContractRch.onEndOfContractRchNewContactRetailer(defaults);
                break;
            case 'onEndOfContractRchNewFinanceOffers':
                this.analytics.endOfContractRch.onEndOfContractRchNewFinanceOffers(defaults);
                break;
            case 'onEndOfContractRchNewNewFromStock':
                this.analytics.endOfContractRch.onEndOfContractRchNewNewFromStock(defaults);
                break;
            case 'onEndOfContractRchNewConfigurator':
                this.analytics.endOfContractRch.onEndOfContractRchNewConfigurator(defaults);
                break;
            case 'onEndOfContractRchNewBack':
                this.analytics.endOfContractRch.onEndOfContractRchNewBack(defaults);
                break;
            case 'onEndOfContractRchNewDashboard':
                this.analytics.endOfContractRch.onEndOfContractRchNewDashboard(defaults);
                break;
            case 'onEndOfContractRchExtendShortTermExtension':
                this.analytics.endOfContractRch.onEndOfContractRchExtendShortTermExtension(defaults);
                break;
            case 'onEndOfContractRchExtendFormalExtension':
                this.analytics.endOfContractRch.onEndOfContractRchExtendFormalExtension(defaults);
                break;
            case 'onEndOfContractRchExtendBack':
                this.analytics.endOfContractRch.onEndOfContractRchExtendBack(defaults);
                break;
            case 'onEndOfContractRchExtendDashboard':
                this.analytics.endOfContractRch.onEndOfContractRchExtendDashboard(defaults);
                break;
            case 'onEndOfContractRchExtensionBack':
                this.analytics.endOfContractRch.onEndOfContractRchExtensionBack(defaults);
                break;
            case 'onEndOfContractRchExtensionRequest':
                this.analytics.endOfContractRch.onEndOfContractRchExtensionRequest(additionalValue as string, defaults);
                break;
            case 'onEndOfContractRchReturnBvrlaGuidelines':
                this.analytics.endOfContractRch.onEndOfContractRchReturnBvrlaGuidelines(
                    additionalValue as string,
                    defaults,
                );
                break;
            case 'onEndOfContractRchReturnEndOfContractCharges':
                this.analytics.endOfContractRch.onEndOfContractRchReturnEndOfContractCharges(
                    additionalValue as string,
                    defaults,
                );
                break;
            case 'onEndOfContractRchReturnBack':
                this.analytics.endOfContractRch.onEndOfContractRchReturnBack(defaults);
                break;
            case 'onEndOfContractRchReturnDashboard':
                this.analytics.endOfContractRch.onEndOfContractRchReturnDashboard(defaults);
                break;
            case 'onEndOfContractRchCallBackBack':
                this.analytics.endOfContractRch.onEndOfContractRchCallBackBack(additionalValue as string, defaults);
                break;
            case 'onEndOfContractRchCallBackRequest':
                this.analytics.endOfContractRch.onEndOfContractRchCallBackRequest(additionalValue as string, defaults);
                break;
            case 'onDigitalRenewalStart':
                this.analytics.digitalRenewal.onDigitalRenewalStart(
                    additionalValue as 'Dashboard' | 'Digital renewal invitation',
                    defaults,
                );
                break;
            case 'onDashboardDigitalRenewal':
                this.analytics.dashboard.onDashboardDigitalRenewal(defaults);
                break;
            case 'onEndOfContractPcpRenewalSelected':
                this.analytics.endOfContractPcp.onEndOfContractPcpRenewalSelected(defaults);
                break;
            case 'onEndOfContractPcpDealerData':
                this.analytics.endOfContractPcp.onEndOfContractPcpDealerData(
                    defaults,
                    additionalValue as 'Refinancing' | 'Payment' | 'Return the car',
                );
                break;
            case 'onMatchmakerLogin':
                this.analytics.matchmaker.onMatchmakerLogin(defaults);
                break;
            case 'onDashboardStartMatchmaker':
                this.analytics.dashboard.onDashboardStartMatchmaker(defaults);
                break;
            case 'onEndOfContractRchStartMatchmaker':
                this.analytics.endOfContractRch.onEndOfContractRchStartMatchmaker(defaults);
                break;
            case 'onEndOfContractPcpStartMatchmaker':
                this.analytics.endOfContractPcp.onEndOfContractPcpStartMatchmaker(defaults);
                break;
            case 'onContinueToMatchmaker':
                this.analytics.matchmaker.onContinueToMatchmaker(defaults);
                break;
            default:
                break;
        }
    };
}
