import React from 'react';
import classNames from 'classnames';
import { Button } from '../Button/Button';
/**
 * Bronson BackToTop component.
 *
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-back-to-top.html
 *
 * @constructor
 */
export function BackToTop({ children, className, onClick, testId, ...otherProps }) {
    /**
     * @type {string|(function(): void)}
     */
    const handleClick = onClick ?? (() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }));
    const divClassNameList = classNames('c-back-to-top', 'js-back-to-top', className).trim();
    return (React.createElement("div", { className: divClassNameList, "data-testid": testId, ...otherProps },
        React.createElement("div", { className: "c-back-to-top__pagewrap" },
            React.createElement(Button, { className: "js-back-to-top-trigger", iconClassName: "c-back-to-top__icon", iconViaCss: true, link: true, simple: true, small: true, onClick: handleClick }, children))));
}
