import React from 'react';
import { ContentSection, Layout } from '@vwfs-bronson/bronson-react';
import { Skeleton } from '../Skeleton';
import { allowedFractions } from 'components/contracts';

export type DealerRequestLoadingPlaceholderProps = { summaryElements?: number; numberOfButtons?: number };

export const DealerRequestLoadingPlaceholder: React.FC<DealerRequestLoadingPlaceholderProps> = ({
    summaryElements = 2,
    numberOfButtons = 2,
}) => (
    <>
        <Layout className="u-mb-large">
            {Array.from(Array(summaryElements).keys()).map(key => (
                <Layout.Item key={key} default={`1/${summaryElements}` as allowedFractions} s={'1/1'}>
                    <Skeleton height={60} />
                </Layout.Item>
            ))}
        </Layout>
        <Layout>
            <Layout.Item default={'1/3'} m={'1/2'}>
                <Skeleton height={60} className="u-mb" />
            </Layout.Item>
            <Layout.Item default={'1/1'} m={'1/1'}>
                <Skeleton height={230} />
            </Layout.Item>
            <ContentSection className={'u-text-right'}>
                {Array.from(Array(numberOfButtons).keys()).map(key => (
                    <Skeleton height={50} width={200} className="u-mt u-m-xsmall" key={key} />
                ))}
            </ContentSection>
        </Layout>
    </>
);
