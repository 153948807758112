import { ItemTeaser, Layout, Modal } from '@vwfs-bronson/bronson-react';
import React, { useState } from 'react';
import { IconFooterCMS, IconFooterItemCMS } from '@cp-shared-8/apis';
import { Override } from '@cp-shared-8/common-utilities';
import { useHistory } from 'react-router-dom';
import { allowedFractions } from 'components/contracts';

export type IconFooterItem = Override<
    IconFooterItemCMS,
    {
        text: string | React.ReactNode;
        modalContent?: string | React.ReactNode;
    }
>;
export type IconFooterProps = Override<
    IconFooterCMS,
    {
        items: IconFooterItem[];
        /**
         * allows to add additional function for single item click tracking for adobe analytics
         */
        trackingFunction?: (title: string, currentPageName?: string) => void;
        /**
         * allows to add currentPage for single item trackingFunction
         */
        currentPageName?: string;
        /**
         * allows to add multiple tracking functions for different items, should match with IconFooterItemCMS trackingId
         */
        trackingFunctions?: { [trackingId: string]: () => void };
    }
>;

const isNormalLink = (normalLink?: boolean, link?: string): boolean => normalLink || !!link?.includes('mailto:');

/**
 * The icon footer consisting of multiple icon bubbles with texts and links
 */
export const IconFooter: React.FC<IconFooterProps> = ({
    items,
    title,
    trackingFunction,
    currentPageName,
    trackingFunctions,
}) => {
    const [withModal, setWithModal] = useState(false);
    const [modalContent, setModalContent] = useState<string | React.ReactNode>('');

    const history = useHistory();

    return (
        <Layout>
            {title && (
                <Layout.Item className={'u-text-center'} default="1/1">
                    <h2>{title}</h2>
                </Layout.Item>
            )}
            <Layout.Item default="1/1">
                <Layout className="row">
                    {items.map((item: IconFooterItem) => (
                        <Layout.Item
                            key={item.title}
                            default={items.length <= 6 ? (`1/${items.length}` as allowedFractions) : '1/12'}
                            m={'1/1'}
                        >
                            <ItemTeaser
                                linkProps={{
                                    href: item.linkUrl,
                                    ...(isNormalLink(item.normalLink ? item.normalLink : false, item.linkUrl)
                                        ? { element: 'a' }
                                        : {}),
                                    target: item.target,
                                    onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
                                        if (!!item.trackItem) {
                                            if (item.trackingId && trackingFunctions?.[item.trackingId]) {
                                                trackingFunctions[item.trackingId]();
                                            } else if (!!trackingFunction) {
                                                trackingFunction(item.title, currentPageName);
                                            }
                                        }

                                        if (!!item.modalContent) {
                                            event.preventDefault();
                                            setWithModal(true);
                                            setModalContent(item.modalContent);
                                        } else if (
                                            item.linkUrl &&
                                            !isNormalLink(item.normalLink ? item.normalLink : false, item.linkUrl)
                                        ) {
                                            history.push(item.linkUrl);
                                        }
                                    },
                                }}
                                icon={item.icon}
                                title={item.title}
                                linkLabel={item.linkText}
                                className={item.className}
                                iconClassName={item.iconClassName}
                                iconDescription={item.iconDescription}
                                testId={item.testId}
                                small={item.small}
                                inline={item.inline !== undefined ? item.inline : true}
                                inlineSmallScreen={item.inlineSmallScreen}
                                block={item.block}
                                blockSmallScreen={item.blockSmallScreen}
                            >
                                {item.text}
                            </ItemTeaser>
                            {withModal && (
                                <Modal
                                    shown={withModal}
                                    testId={`icon-footer-item-modal-${item.title}`}
                                    onClose={(): void => setWithModal(false)}
                                >
                                    {modalContent}
                                </Modal>
                            )}
                        </Layout.Item>
                    ))}
                </Layout>
            </Layout.Item>
        </Layout>
    );
};
