import React, { ReactNode } from 'react';
import { InfoIcon } from '../../info-icon';
import { Table } from '@vwfs-bronson/bronson-react';

export type TableHeaderEntryWithTooltip = {
    heading: string;
    tooltip?: string;
    hiddenForMobile?: boolean;
    textAlign?: 'left' | 'center' | 'right';
};

export type TableHeaderEntry = string | TableHeaderEntryWithTooltip;

interface TableHeaderCellWithTooltipProps {
    heading: ReactNode;
    tooltip?: string;
}

const TableHeaderCellWithTooltip: React.FC<TableHeaderCellWithTooltipProps> = ({ heading, tooltip }) => {
    return (
        <span>
            {heading}&nbsp;
            <InfoIcon text={tooltip} />
        </span>
    );
};

interface TableHeaderCellProps {
    heading: TableHeaderEntry;
    sortable?: boolean;
    sortSelected?: boolean;
    sortOrder?: boolean;
    sortCallback?: () => void;
}

export const TableHeaderCell: React.FC<TableHeaderCellProps> = ({
    heading: entry,
    sortable,
    sortSelected,
    sortOrder,
    sortCallback,
}) => {
    let content: ReactNode = typeof entry === 'string' ? entry : entry.heading;
    let classNames = '';

    if (sortable) {
        content = (
            <a className={'c-link'} onClick={sortCallback}>
                <span className="c-link__effect u-ws-nowrap">
                    {content}&nbsp;
                    {sortSelected &&
                        (sortOrder ? (
                            <i className={'c-icon c-icon--[semantic-sort-desc] c-icon-–simple'} />
                        ) : (
                            <i className={'c-icon c-icon--[semantic-sort-asc] c-icon-–simple'} />
                        ))}
                </span>
            </a>
        );
    }

    if (typeof entry !== 'string') {
        const { tooltip, hiddenForMobile, textAlign } = entry as TableHeaderEntryWithTooltip;
        content = tooltip ? <TableHeaderCellWithTooltip heading={content} tooltip={tooltip} /> : content;
        classNames = [!!textAlign ? `u-text-${textAlign}` : '', !!hiddenForMobile ? 'u-hide@xs' : '']
            .filter(Boolean)
            .join(' ');
    }

    return <Table.Th className={classNames}>{content}</Table.Th>;
};
TableHeaderCell.displayName = 'TableHeaderCell';
