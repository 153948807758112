import { DataOverview, Layout } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { DefinitionListHorizontal, DefinitionListItem } from '../definition-list';

export type Tile = {
    title: string;
    icon?: string;
    data: DefinitionListItem[];
};

export type AmortizationTableTileProps = {
    tiles: Tile[];
    noWrap?: boolean;
};

export const AmortizationTableTiles: React.FC<AmortizationTableTileProps> = ({ tiles, noWrap }) => {
    return (
        <>
            {tiles.map((tile, index) => (
                <Layout.Item key={index} default="1/3" m="1/1" className={noWrap ? 'u-ws-nowrap' : ''}>
                    <DataOverview title={tile.title} icon={tile.icon}>
                        <DefinitionListHorizontal list={tile.data} />
                    </DataOverview>
                </Layout.Item>
            ))}
        </>
    );
};
