import React from 'react';
import { Skeleton } from '../Skeleton';
import { ContentSection, Layout, PageWrap } from '@vwfs-bronson/bronson-react';

export type RegistrationLoadingPlaceholderProps = {
    numberOfButtons?: number;
    summaryElements?: number;
};

export const RegistrationLoadingPlaceholder: React.FC<RegistrationLoadingPlaceholderProps> = ({
    numberOfButtons = 1,
    summaryElements = 4,
}) => {
    return (
        <>
            <ContentSection className={'u-text-center'}>
                <PageWrap size={'xsmall'}>
                    <Layout>
                        <Layout.Item className={'u-text-center'}>
                            <Skeleton height={90} width={300} />
                        </Layout.Item>
                        <Layout.Item className={'u-text-center'}>
                            <Skeleton height={30} width={450} />
                        </Layout.Item>
                        <Layout.Item className={'u-text-center'}>
                            <Skeleton height={30} width={300} />
                        </Layout.Item>
                        <Layout.Item className={'u-text-center'}>
                            <Skeleton height={30} width={500} />
                        </Layout.Item>
                        <Layout.Item>
                            {!!summaryElements && <Skeleton count={summaryElements} height={50} />}
                        </Layout.Item>
                        <Layout.Item>
                            <Skeleton height={40} count={numberOfButtons} />
                        </Layout.Item>
                    </Layout>
                </PageWrap>
            </ContentSection>
        </>
    );
};
