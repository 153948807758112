export enum VehicleImageView {
    EXTERIOR_FRONT_RIGHT = 'EXTERIOR_FRONT_RIGHT',
    EXTERIOR_SIDE_RIGHT = 'EXTERIOR_SIDE_RIGHT',
    EXTERIOR_BACK_RIGHT = 'EXTERIOR_BACK_RIGHT',
    EXTERIOR_BACK_LEFT = 'EXTERIOR_BACK_LEFT',
    EXTERIOR_SIDE_LEFT = 'EXTERIOR_SIDE_LEFT',
    EXTERIOR_FRONT_LEFT = 'EXTERIOR_FRONT_LEFT',
    EXTERIOR_FRONT_CENTER = 'EXTERIOR_FRONT_CENTER',
    EXTERIOR_BACK_CENTER = 'EXTERIOR_BACK_CENTER',
    INTERIOR_CENTER = 'INTERIOR_CENTER',
    INTERIOR_SIDE = 'INTERIOR_SIDE',
    EXTERIOR_WHEEL = 'EXTERIOR_WHEEL',
    UNKNOWN = 'UNKNOWN',
}

export type VehicleImageServiceQuery = {
    view?: VehicleImageView;
};

export type VehicleImageServiceImageType = {
    view: VehicleImageView;
    url: string;
};

export type VehicleImageServiceResponse = {
    vin: string;
    images: VehicleImageServiceImageType[];
};
