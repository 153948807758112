import React from 'react';
import { Layout, Card } from '@vwfs-bronson/bronson-react';
import { ContractHeaderLoadingPlaceholder } from '../contract-header-loading-placeholder';
import { Skeleton } from '../Skeleton';

export type ContractsLoadingPlaceholderProps = {
    summaryElements?: number;
    numberOfContracts?: number;
    firstContractExpanded?: boolean;
    contractActions?: number;
    contractDetailsRows?: number;
    expandable?: boolean;
};

export const ContractsLoadingPlaceholder: React.FC<ContractsLoadingPlaceholderProps> = ({
    summaryElements = 3,
    numberOfContracts = 1,
    firstContractExpanded,
    contractActions = 0,
    contractDetailsRows = 3,
    expandable = false,
}) => (
    <>
        {Array.from(Array(numberOfContracts).keys()).map((contractKey, index) => (
            <Card
                element="article"
                className={'u-mb'}
                expandable={expandable}
                contentLarge={
                    <>
                        {!!contractActions && (
                            <Layout>
                                {Array.from(Array(contractActions).keys()).map(key => (
                                    <Layout.Item key={key} default={'1/3'} s={'1/1'}>
                                        <Skeleton height={45} />
                                    </Layout.Item>
                                ))}
                            </Layout>
                        )}
                        {!!contractDetailsRows &&
                            Array.from(Array(contractDetailsRows).keys()).map(key => (
                                <Skeleton height={45} key={key} />
                            ))}
                    </>
                }
                defaultExpanded={firstContractExpanded && index === 0}
                key={contractKey}
            >
                <ContractHeaderLoadingPlaceholder summaryElements={summaryElements} />
                <div className="u-mb" />
            </Card>
        ))}
    </>
);
