import { hasError, isLoading, notInitiated } from '../context';
import { AuthorizationFlowHookResult } from './create-authorization-flow-hook';

export type AuthorizationDataHookResult<T> = ({ isAuthorized: true } & T) | ({ isAuthorized: false } & Partial<T>);

export function createAuthorizationDataHook<T>(useAuthorizationFlow: () => AuthorizationFlowHookResult<T>) {
    return function useAuthorizationData(): AuthorizationDataHookResult<T> {
        const { state } = useAuthorizationFlow();

        if (notInitiated(state) || isLoading(state) || hasError(state)) {
            return { isAuthorized: false };
        } else {
            return state;
        }
    };
}
