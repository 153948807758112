import { FormField, Select } from '@vwfs-bronson/bronson-react';
import { useField } from 'formik';
import React, { ChangeEvent, FocusEventHandler, MouseEventHandler } from 'react';
import { InfoIcon } from '../../info-icon';
import { ValidationErrorMessage } from '../errors';

export type ValidatedSelectItem = {
    label: string;
    value: string;
    key?: string | number;
};

export type ValidatedSelectProps = {
    name: string;
    id?: string;
    testId?: string;
    selectItems: ValidatedSelectItem[];
    placeholder?: string;
    disablePlaceholder?: boolean;
    label?: string;
    isMandatory?: boolean;
    emptyByDefault?: boolean;
    onFocus?: FocusEventHandler<HTMLSelectElement>;
    tooltip?: string | React.ReactNode;
    onChange?: (value: string) => void;
    disabled?: boolean;
    hint?: string;
    link?: string;
    onLinkClick?: MouseEventHandler;
};

export const ValidatedSelect: React.FC<ValidatedSelectProps> = ({
    name,
    label,
    id,
    testId,
    selectItems,
    placeholder,
    disablePlaceholder,
    isMandatory = false,
    emptyByDefault,
    onFocus,
    tooltip,
    onChange,
    disabled,
    hint,
    onLinkClick,
}) => {
    const [field, meta] = useField({ name, type: 'select' });
    const infoIcon = tooltip ? <InfoIcon text={tooltip} /> : null;

    const errorMessage = <ValidationErrorMessage meta={meta} testId={testId} />;
    return (
        <FormField
            type="select"
            testId={`${testId}FormField`}
            labelText={label}
            errorMessage={errorMessage}
            notion={isMandatory}
            infoIcon={infoIcon}
            hint={hint}
            onClick={onLinkClick}
        >
            <Select
                id={id}
                testId={testId}
                onFocus={onFocus}
                {...field}
                onChange={(e: ChangeEvent<HTMLSelectElement>): void => {
                    field.onChange(e);
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    onChange && onChange(e.target.value);
                }}
                disabled={disabled}
            >
                {emptyByDefault && <Select.Item hidden />}
                {placeholder && (
                    <Select.Item value={''} disabled={disablePlaceholder ?? true}>
                        {placeholder}
                    </Select.Item>
                )}
                {selectItems.map(item => {
                    return (
                        <Select.Item key={item?.key ? item.key : item.value} value={item.value}>
                            {item.label}
                        </Select.Item>
                    );
                })}
            </Select>
        </FormField>
    );
};
