import React, { useState, ChangeEvent } from 'react';
import { UploadContainer, Upload, FormField } from '@vwfs-bronson/bronson-react';
import { FileUploadItems } from './file-upload-items/FileUploadItems';
import { useField } from 'formik';

export type FileUploadProps = {
    name: string;
    descriptionText?: string | React.ReactNode;
    descriptionSupplementaryText?: string | React.ReactNode;
    buttonText?: string;
    disabled?: boolean;
    disabledText?: string;
    disabledSupplementaryText?: string;
    multiple?: boolean;
    accept?: string;
    loading?: boolean;
    processingText?: React.ReactNode;
    className?: string;
    /**
     * file size in bytes
     */
    maxFileSize?: number;
    maxFileSizePerFile?: number;
    validFileFormats?: string[];
    sizeError?: string | React.ReactNode;
    typeError?: string | React.ReactNode;
    sizeAndTypeError?: string | React.ReactNode;
    cancelLabel: string;
    fileSizeFormatter: (bytes: number) => string;
};

export const FileUpload: React.FC<FileUploadProps> = ({
    maxFileSize,
    maxFileSizePerFile,
    validFileFormats,
    sizeError,
    typeError,
    sizeAndTypeError,
    cancelLabel,
    fileSizeFormatter,
    ...rest
}) => {
    const [field, meta, helpers] = useField(rest.name);
    const [filesList, setFilesList] = useState<File[]>(field.value);

    const removeFile = (index: number): void => {
        const fileListCopy = [...filesList];
        fileListCopy.splice(index, 1);
        setFilesList(fileListCopy);

        helpers.setValue(fileListCopy);
    };

    const fileChangedHandler = (event: ChangeEvent<HTMLInputElement>): void => {
        const target = event.target as HTMLInputElement;
        const files: FileList | null = target.files;
        const array = Array.prototype.slice.call(files);
        setFilesList([...filesList, ...array]);

        helpers.setTouched(true);
        helpers.setValue([...filesList, ...array]);
        target.value = '';
    };

    return (
        <FormField type="input" testId={'upload-form-field'}>
            <UploadContainer
                upload={
                    <Upload onChange={(e: ChangeEvent<HTMLInputElement>): void => fileChangedHandler(e)} {...rest} />
                }
                testId={'upload-container'}
            >
                <FileUploadItems
                    files={filesList}
                    removeFile={removeFile}
                    validFileFormats={validFileFormats}
                    maxFileSize={maxFileSize}
                    maxFileSizePerFile={maxFileSizePerFile}
                    sizeError={sizeError}
                    typeError={typeError}
                    sizeAndTypeError={sizeAndTypeError}
                    cancelLabel={cancelLabel}
                    fileSizeFormatter={fileSizeFormatter}
                />
            </UploadContainer>
            {meta.error && meta.touched && <div className="c-error-message u-mb-small">{meta.error}</div>}
        </FormField>
    );
};
