import React, { FC, ReactElement, ReactNode } from 'react';
import { useKeycloak } from '@react-keycloak/web';

export type AuthenticationComponentFactoryOptions = {
    onMissingAuthentication: ReactNode;
};

export function createAuthenticationComponent({ onMissingAuthentication }: AuthenticationComponentFactoryOptions): FC {
    const Authentication: FC = ({ children }): ReactElement => {
        const [keycloak] = useKeycloak();

        if (keycloak.authenticated && keycloak.token && keycloak.tokenParsed) {
            return <>{children}</>;
        } else {
            return <>{onMissingAuthentication}</>;
        }
    };
    return Authentication;
}
