import { AuthorizationFlowState, Error, Loading, NotInitiated } from './types';

export function isLoading<T>(result: AuthorizationFlowState<T>): result is Loading {
    return (result as Loading).isLoading;
}

export function hasError<T>(result: AuthorizationFlowState<T>): result is Error {
    return (result as Error).hasError;
}

export function notInitiated<T>(result: AuthorizationFlowState<T>): result is NotInitiated {
    return (result as NotInitiated).notInitiated;
}
