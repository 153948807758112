import React from 'react';
import classNames from 'classnames';
import { Button } from '../../Button/Button';
import { IndicatorDot } from '../../IndicatorDot/IndicatorDot';
import { Tooltip } from '../../Tooltip/Tooltip';
/**
 * Bronson NotificationCenterItem component.
 * @constructor
 */
export function NotificationCenterItem({ children, close = true, closeLabel, datetime, markAsReadLabel, markAsUnreadLabel, onClick, onClose, onMarkReadUnread, showUnreadButton = true, statusReadLabel, statusUnreadLabel, timestamp, timestampText, title, unread, testId, ...otherProps }) {
    const articleClassNameList = classNames('c-notification-center__item ', {
        'c-notification-center__item--read ': !unread,
    }).trim();
    function renderIfTitle() {
        if (title) {
            return (React.createElement("h3", { className: "c-notification-center__item__heading" },
                React.createElement("a", { onClick: onClick, className: "c-notification-center__item__heading-link" }, title)));
        }
        return null;
    }
    const handleOnCLose = (event) => {
        event.nativeEvent.stopImmediatePropagation();
        onClose?.();
    };
    /**
     * Renders an {@link IndicatorDot} to signalize read/unread state.
     * @return {JSX.Element}
     */
    const renderNotificationCenterIndicator = () => {
        if (unread) {
            const args = {
                className: 'c-notification-center__item__status',
                status: 'unread',
                tooltip: (React.createElement(Tooltip, { className: "c-indicator-dot__dot", content: statusReadLabel }, ' ')),
            };
            return React.createElement(IndicatorDot, { ...args });
        }
        return null;
    };
    function renderIfClose() {
        if (close) {
            return (React.createElement("button", { className: "c-notification-content-center__item__close", onClick: handleOnCLose, type: "button" },
                React.createElement("span", { className: "c-notification-content-center__item__close-label" }, closeLabel)));
        }
        return null;
    }
    function renderIfText() {
        if (children) {
            return React.createElement("div", { className: "c-notification-center__item__body" }, children);
        }
        return null;
    }
    function renderIfTimestamp() {
        if (timestamp) {
            return (React.createElement("time", { dateTime: datetime, className: "c-notification-center__item__timestamp" }, timestampText));
        }
        return null;
    }
    function renderIfShowUnreadButton() {
        if (showUnreadButton) {
            return (React.createElement(Button, { small: true, link: true, className: "c-notification-center__item__mark-read-toggle", onClick: onMarkReadUnread }, unread ? markAsReadLabel : markAsUnreadLabel));
        }
        return null;
    }
    return (React.createElement("article", { ...otherProps, "data-testid": testId, className: articleClassNameList },
        renderNotificationCenterIndicator(),
        renderIfTitle(),
        renderIfClose(),
        renderIfText(),
        renderIfTimestamp(),
        renderIfShowUnreadButton()));
}
NotificationCenterItem.displayName = 'NotificationCenter.Item';
