import React from 'react';
import { useHistory } from 'react-router-dom';
import { Notification, Button } from '@vwfs-bronson/bronson-react';
import { NotificationStatus } from '../notification';

export type NotificationWithNavigationButtonProps = {
    status?: NotificationStatus;
    headline?: string;
    text?: string;
    notificationTestId?: string;
    submitButtonLabel: string;
    pathToNavigate: string;
    className?: string;
};

export const NotificationWithNavigationButton: React.FC<NotificationWithNavigationButtonProps> = ({
    status,
    headline,
    text,
    notificationTestId = 'notificationWithNavButton',
    submitButtonLabel,
    pathToNavigate,
    className,
}) => {
    const history = useHistory();
    const handleClick = () => history.push(pathToNavigate);
    return (
        <Notification
            className={className}
            visible
            showCloseButton={false}
            status={status}
            title={headline}
            text={text}
            testId={notificationTestId}
        >
            <Button secondary className="u-bg-white u-mt" testId="submitButton" onClick={handleClick}>
                {submitButtonLabel}
            </Button>
        </Notification>
    );
};
