import React from 'react';
import { Card } from '@vwfs-bronson/bronson-react';
import { Skeleton } from '../Skeleton';

export type MyProfileLoadingPlaceholderProps = { sectionCounts?: number[] };

export const MyProfileLoadingPlaceholder: React.FC<MyProfileLoadingPlaceholderProps> = ({
    sectionCounts = [3, 8, 4],
}) => (
    <>
        {sectionCounts.map((rows, index) => (
            <Card element="article" key={index} className="u-text-left">
                <Skeleton height={30} width={200} className="u-mb" />
                <Skeleton count={rows} className="u-mb-small" />
            </Card>
        ))}
    </>
);
