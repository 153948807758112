import React, { ReactElement } from 'react';
import { Layout, Card } from '@vwfs-bronson/bronson-react';
import { Skeleton } from '../Skeleton';

export type DividedContractHeaderLoadingPlaceholderProps = {
    withRightSideContent?: boolean;
    withLicensePlate?: boolean;
    withExtraInformation?: boolean;
    rightSideContentRows?: number;
    customRightSideContent?: ReactElement;
};

export const DividedContractHeaderLoadingPlaceholder: React.FC<DividedContractHeaderLoadingPlaceholderProps> = ({
    withRightSideContent = true,
    withLicensePlate = true,
    withExtraInformation,
    rightSideContentRows = 3,
    customRightSideContent,
}) => {
    const carInformationPart = (
        <div className="u-text-left@m-up u-mr-none cp-contract-identifier-slot">
            {withLicensePlate && <Skeleton height={30} />}
            <Skeleton height={40} />
            <Skeleton height={30} />
            {withExtraInformation && <Skeleton height={30} />}
        </div>
    );

    const rightSideContents = Array.from(Array(rightSideContentRows).keys()).map(key => (
        <Skeleton height={40} key={key} />
    ));

    return (
        <Card element="article">
            {withRightSideContent ? (
                <Layout equalHeight divider>
                    <Layout.Item default="1/2" s="1/1">
                        {carInformationPart}
                    </Layout.Item>
                    <Layout.Item default="1/2" s="1/1">
                        {<div>{customRightSideContent || rightSideContents}</div>}
                    </Layout.Item>
                </Layout>
            ) : (
                <Layout>
                    <Layout.Item default="1/1">{carInformationPart}</Layout.Item>
                </Layout>
            )}
        </Card>
    );
};
