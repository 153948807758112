import React from 'react';
import classNames from 'classnames';
import { Icon } from '../Icon/Icon';
/*
 * Bronson Badge component.
 */
export function Badge({ children, className, icon, label, modifier, wrapperClass, testId, ...otherProps }) {
    const wrapperClassNameList = classNames('c-badge__wrapper', {
        [`${wrapperClass} `]: wrapperClass,
    }).trim();
    const badgeClassNameList = classNames({
        'c-badge ': true,
        [`c-badge--${modifier} `]: modifier,
    }, className).trim();
    const iClassNameList = classNames({ 'c-badge__icon ': true }).trim();
    function renderIfBadgeIcon() {
        if (icon) {
            return React.createElement(Icon, { className: iClassNameList, name: icon });
        }
        return null;
    }
    function renderIfBadgeLabel() {
        if (label) {
            return React.createElement("span", { className: "c-badge__label" }, label);
        }
        return null;
    }
    return (React.createElement("span", { ...otherProps, "data-testid": testId, className: wrapperClassNameList },
        React.createElement("span", { className: badgeClassNameList }, children || React.createElement(React.Fragment, null, renderIfBadgeIcon())),
        renderIfBadgeLabel()));
}
