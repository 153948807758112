import { Notification as NotificationShared, Button } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { NotificationStatus } from './NotificationStatusEnum';

export type NotificationProps = {
    visible?: boolean;
    headline?: string;
    className?: string;
    status?: NotificationStatus;
    buttonText?: string;
    onCloseClick?: () => void;
    showCloseButton?: boolean;
    buttonCloseAriaLabel?: string;
    buttons?: React.ReactNode;
    text?: string;
    testId?: string;
    children?: React.ReactNode;
};

export const Notification: React.FC<NotificationProps> = ({
    visible = true,
    headline,
    className,
    status,
    buttonText,
    buttons,
    onCloseClick,
    showCloseButton = false,
    buttonCloseAriaLabel,
    text,
    testId,
    children,
}) => {
    const defaultButton = (
        <Button className={'c-btn  u-text-brand'} iconReversed={false} onClick={onCloseClick}>
            {buttonText}
        </Button>
    );

    const getButtons = () => {
        return buttons ? buttons : buttonText ? defaultButton : undefined;
    };

    return (
        <NotificationShared
            visible={visible}
            title={headline}
            className={className}
            status={status}
            buttons={getButtons()}
            onCloseClick={onCloseClick}
            showCloseButton={showCloseButton}
            closeLabel={buttonCloseAriaLabel}
            text={text}
            testId={testId}
        >
            {children}
        </NotificationShared>
    );
};
