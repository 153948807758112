import { FormField, RadioButton, RadioButtonGroup } from '@vwfs-bronson/bronson-react';
import { useField } from 'formik';
import React, { FormEventHandler, MouseEventHandler } from 'react';
import { InfoIcon } from '../../info-icon';
import { hasError, ValidationErrorMessage } from '../errors';

type RadioButton = { label: string; value: string | number | readonly string[] | undefined; tooltip?: string };

type ValidatedRadioButtonGroupProps = {
    radioButtons: RadioButton[];
    name: string;
    label?: string;
    tooltip?: string;
    defaultChecked?: boolean;
    disabled?: boolean;
    testId?: string;
    handleChange?: FormEventHandler<HTMLElement>;
    className?: string;
    isMandatory?: boolean;
    withHtmlValidationWarning?: boolean;
    formFieldClassName?: string;
    hint?: string;
    id?: string;
    onLinkClick?: MouseEventHandler;
};

export const ValidatedRadioButtonGroup: React.FC<ValidatedRadioButtonGroupProps> = ({
    radioButtons,
    name,
    tooltip,
    defaultChecked,
    label,
    disabled,
    testId,
    handleChange,
    className,
    isMandatory = false,
    withHtmlValidationWarning = false,
    formFieldClassName,
    hint,
    id,
    onLinkClick,
}) => {
    const [field, meta] = useField(name);

    const infoIcon = tooltip ? <InfoIcon text={tooltip} /> : null;
    const errorMessage = (
        <ValidationErrorMessage meta={meta} testId={testId} withHtmlValidationWarning={withHtmlValidationWarning} />
    );

    return (
        <FormField
            type="other"
            id={id}
            testId={`${testId}FormField`}
            errorMessage={errorMessage}
            labelText={label}
            infoIcon={infoIcon}
            onChange={handleChange}
            notion={isMandatory}
            onClick={onLinkClick}
            className={formFieldClassName}
            hint={hint}
        >
            <RadioButtonGroup className={className} {...field}>
                {radioButtons.map(({ label, value, tooltip }, index: number) => (
                    <RadioButton
                        key={index}
                        testId={`${testId}.${value}`}
                        name={name}
                        value={value}
                        error={hasError(meta)}
                        disabled={disabled}
                        defaultChecked={defaultChecked || value === field.value}
                    >
                        {label}
                        {tooltip && <InfoIcon text={tooltip} />}
                    </RadioButton>
                ))}
            </RadioButtonGroup>
        </FormField>
    );
};
