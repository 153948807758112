import { Context, useContext, useState } from 'react';
import { AuthorizationState } from '../context/create-authorization-context';
import { AuthorizationFlowState } from '../context';

export function useAuthorizationState<T>(
    AuthorizationContext: Context<AuthorizationState<T>>,
): [AuthorizationFlowState<T>, (newState: AuthorizationFlowState<T>) => void] {
    const context = useContext(AuthorizationContext);
    const localState = useState<AuthorizationFlowState<T>>({ notInitiated: true });

    if (context) {
        return context;
    } else {
        return localState;
    }
}
