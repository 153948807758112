import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';

export type ScrollToTopOnNavigationProps = {
    exceptions?: Array<{
        from: string | RegExp;
        to: string | RegExp;
    }>;
    children?: React.ReactNode;
};

/**
 * Restores the scrolling to the top of the page after page navigation
 * Based on https://reacttraining.com/react-router/web/guides/scroll-restoration
 */
export const ScrollToTopOnNavigation: React.FC<ScrollToTopOnNavigationProps> = ({ exceptions, children }) => {
    const lastLocation = useLastLocation();
    const lastPathname = lastLocation?.pathname;
    const { pathname } = useLocation();

    useEffect(() => {
        const shouldScroll = !(
            exceptions &&
            exceptions.some(({ from, to }) => {
                return (!lastPathname || (lastPathname && lastPathname.match(from))) && pathname.match(to);
            })
        );
        if (shouldScroll) {
            setTimeout(() => {
                window.scrollTo(0, 0);
            });
        }
    }, [pathname, lastPathname, exceptions]);

    return <> {children} </>;
};
