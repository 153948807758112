import { useKeycloak } from '@react-keycloak/web';
import { KeycloakTokenParsed } from 'keycloak-js';

export type LoginOptions = {
    redirectUri?: string;
    locale?: string;
    idpHint?: string;
    loginHint?: string;
    prompt?: 'login' | 'none';
};

export type LogoutOptions = {
    redirectUri?: string;
};

// type Promise<void> remains only for backward compatibility
type AuthenticationMethods = {
    logout: (options: LogoutOptions) => Promise<void>;
    login: (options: LoginOptions) => Promise<void>;
};

type AuthenticationData =
    | {
          isAuthenticated: true;
          token: string;
          tokenParsed: KeycloakTokenParsed;
          idToken?: string;
          idTokenParsed?: KeycloakTokenParsed;
      }
    | {
          isAuthenticated: false;
      };

export type AuthenticationHookResult = AuthenticationData & AuthenticationMethods;

export function useAuthentication(): AuthenticationHookResult {
    const [keycloak] = useKeycloak();
    const redirectWithHistoryEntry = (url: string) => {
        window.location.assign(url);
        return Promise.resolve();
    };

    if (keycloak?.authenticated && keycloak?.token && keycloak?.tokenParsed) {
        return {
            isAuthenticated: keycloak.authenticated,
            token: keycloak.token,
            tokenParsed: keycloak.tokenParsed,
            idToken: keycloak.idToken,
            idTokenParsed: keycloak.idTokenParsed,
            login: (options: LoginOptions): Promise<void> => redirectWithHistoryEntry(keycloak.createLoginUrl(options)),
            logout: (options: LogoutOptions): Promise<void> =>
                redirectWithHistoryEntry(keycloak.createLogoutUrl(options)),
        };
    } else {
        return {
            isAuthenticated: false,
            login: (options: LoginOptions): Promise<void> => {
                if (!keycloak) return Promise.resolve();
                return redirectWithHistoryEntry(keycloak.createLoginUrl(options));
            },
            logout: (options: LogoutOptions): Promise<void> => {
                if (!keycloak) return Promise.resolve();
                return redirectWithHistoryEntry(keycloak.createLogoutUrl(options));
            },
        };
    }
}
