import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import { useLazyRender } from '../../features/useLazyRender';
import { Icon } from '../Icon/Icon';
const STATE_ID = 'expandable-content';
/*
 * Bronson ExpandableContent component.
 */
export function ExpandableContent({ children, className, defaultOpen = false, lazyRender = false, noBackground, noPageWrap = false, pageWrapClassName, triggerLabel, triggerLabelClose, testId, ...otherProps }) {
    const [open, setOpen] = useState(defaultOpen);
    const { isRendered, setRendered } = useLazyRender(lazyRender);
    const updateOpen = useCallback((newOpen) => {
        setOpen(newOpen);
        if (newOpen) {
            setRendered(STATE_ID);
        }
    }, [setRendered]);
    const toggleOpen = () => {
        updateOpen(!open);
    };
    const divClassNameList = classNames({
        'c-expandable-content ': true,
        'js-is-fx ': false,
        // main class modifier convenience prop extension
        'c-expandable-content--no-background ': noBackground,
        'is-closed-within': !open,
        'js-is-ready': true,
    }, className).trim();
    const divClassNameList2 = noPageWrap ? '' : classNames('o-page-wrap', pageWrapClassName).trim();
    return (React.createElement("div", { ...otherProps, "data-testid": testId, className: divClassNameList },
        React.createElement("div", { className: divClassNameList2 },
            React.createElement("button", { className: "c-expandable-content__trigger c-expandable-content__trigger-open", "aria-expanded": open, onClick: toggleOpen, type: "button" },
                React.createElement("span", { className: "c-expandable-content__trigger-text c-expandable-content__trigger-text-open" }, triggerLabel),
                React.createElement(Icon, { className: "c-expandable-content__icon", viaCss: true })),
            isRendered(STATE_ID) && (
            /**
             * Need to pass `[inert=""]` as React currently does not support HTMLElement.inert mapping.
             * @see https://github.com/facebook/react/pull/24730
             * @TODO: Remove once React supports direct `[inert]` usage.
             * @see BRON-11871
             */
            // @ts-ignore @TODO: Remove once React supports inerts, @see BRON-11871.
            React.createElement("div", { className: "c-expandable-content__collapse js-is-ready", "aria-hidden": !open, inert: open ? null : '' },
                React.createElement("section", { className: "c-expandable-content__content" }, children),
                React.createElement("button", { className: "c-expandable-content__trigger c-expandable-content__trigger-close", "aria-expanded": open, onClick: toggleOpen, type: "button" },
                    React.createElement("span", { className: "c-expandable-content__trigger-text c-expandable-content__trigger-text-close" }, triggerLabelClose),
                    React.createElement(Icon, { className: "c-expandable-content__icon", viaCss: true })))))));
}
