import { AuthorizationFlowState, AuthorizationResult, notInitiated } from '../context';

export function createInitiateAuthorizationFunction<T>(
    authorizationDataProvider: () => Promise<AuthorizationResult<T>>,
    state: AuthorizationFlowState<T>,
    setResult: (newState: AuthorizationFlowState<T>) => void,
): () => Promise<void> {
    return async (): Promise<void> => {
        if (notInitiated(state)) {
            setResult({ isLoading: true });
            await authorizationDataProvider()
                .then(authorizationData => setResult(authorizationData))
                .catch(() => setResult({ hasError: true }));
        }
    };
}
