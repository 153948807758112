import React from 'react';
import { Layout } from '@vwfs-bronson/bronson-react';
import { Skeleton } from '../Skeleton';

export type ContractHeaderLoadingPlaceholderProps = {
    summaryElements?: number;
};

export const ContractHeaderLoadingPlaceholder: React.FC<ContractHeaderLoadingPlaceholderProps> = ({
    summaryElements = 3,
}) => {
    return (
        <>
            <Skeleton height={40} />
            <Skeleton height={30} />
            {!!summaryElements && (
                <Layout>
                    {Array.from(Array(summaryElements).keys()).map(key => (
                        <Layout.Item key={key} default={'1/3'} s={'1/1'}>
                            <Skeleton height={50} className="u-mt" />
                        </Layout.Item>
                    ))}
                </Layout>
            )}
        </>
    );
};
