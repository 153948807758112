import React, { useState, useEffect, useRef, isValidElement } from 'react';
import classNames from 'classnames';
import { Badge } from '../Badge/Badge';
import { Button } from '../Button/Button';
import { NotificationCenterItem } from './NotificationCenterItem/NotificationCenterItem';
const notClosed = (item) => !item.closed;
const mouseEvent = 'click';
/**
 * Bronson NotificationCenter component.
 * @constructor
 */
export function NotificationCenter({ toggleLabel, panelHeading, defaultOpen = false, testId, children, notifications, closeButtonLabel = 'CLOSE', ...otherProps }) {
    const ncPanelRef = useRef(null);
    const [open, setOpen] = useState(defaultOpen);
    const [itemsToShow, setItemsToShow] = useState([]);
    useEffect(() => {
        setItemsToShow(notifications?.filter(notClosed) ?? []);
    }, [notifications]);
    const countUnread = itemsToShow && itemsToShow.filter((item) => !item.read).length;
    useEffect(() => {
        const handleClick = (event) => {
            if (ncPanelRef.current?.contains(event.target)) {
                return;
            }
            setOpen(false);
        };
        document.addEventListener(mouseEvent, handleClick, { capture: true });
        return () => {
            document.removeEventListener(mouseEvent, handleClick, { capture: true });
        };
    }, []);
    const onToggleOpen = (event) => {
        event.nativeEvent.stopImmediatePropagation();
        setOpen((newOpen) => !newOpen);
    };
    const updateItem = (item, items) => items.map((i) => (i.id === item.id ? { ...item } : i));
    const notificationCenterClassNameList = classNames('c-notification-center', 'js-notification-center', {
        'is-active': open,
    }).trim();
    // generated main result
    return (React.createElement("div", { ...otherProps, "data-testid": testId, className: notificationCenterClassNameList },
        React.createElement("button", { onClick: onToggleOpen, className: "c-notification-center__toggle js-notification-center__toggle", type: "button", "aria-expanded": open },
            React.createElement("span", { className: "c-notification-center__toggle__label" }, toggleLabel),
            countUnread > 0 && (React.createElement(Badge, { modifier: "notification", wrapperClass: "c-notification-center__toggle__badge" }, countUnread))),
        itemsToShow?.length > 0 && (React.createElement("section", { className: "c-notification-center__panel js-notification-center__panel", "aria-hidden": !open, ref: ncPanelRef },
            React.createElement("header", { className: "c-notification-center__panel__header" },
                React.createElement("h2", { className: "c-notification-center__panel__heading" }, panelHeading),
                React.createElement(Button, { onClick: onToggleOpen, className: "c-notification-center__panel__close js-notification-center__toggle", link: true, small: true, simple: true, iconViaCss: true }, closeButtonLabel)),
            itemsToShow.map((n) => {
                const notificationCenterItem = children?.(n);
                /**
                 * Overload NotificationCenterItemProps with custom callbacks due to
                 * ITS implementation of the callbacks in first place :(
                 */
                const notificationCenterItemProps = notificationCenterItem?.props;
                if (isValidElement(notificationCenterItem)) {
                    return React.cloneElement(notificationCenterItem, {
                        key: n.id,
                        unread: !n.read,
                        title: n.title,
                        onClose: () => {
                            notificationCenterItemProps?.onClose?.(n);
                            // eslint-disable-next-line no-param-reassign
                            n.closed = true;
                            setItemsToShow((items) => items.filter(notClosed));
                        },
                        onClick: () => {
                            notificationCenterItemProps?.onClick?.(n);
                            setOpen(false);
                        },
                        onMarkReadUnread: () => {
                            notificationCenterItemProps?.onMarkReadUnread?.(n);
                            // eslint-disable-next-line no-param-reassign
                            n.read = !n.read;
                            setItemsToShow((items) => updateItem(n, items));
                        },
                    });
                }
                return null;
            })))));
}
NotificationCenter.Item = NotificationCenterItem;
