import { InfoIcon as InfoIconShared } from '@vwfs-bronson/bronson-react';
import React from 'react';

export type InfoIconProps = {
    text?: string | React.ReactNode;
    onMouseOver?: () => void;
};

export const InfoIcon: React.FC<InfoIconProps> = ({ text, onMouseOver }) => {
    return (
        <InfoIconShared icon={'semantic-info'} onMouseOver={onMouseOver} onClick={e => e.preventDefault()}>
            {text}
        </InfoIconShared>
    );
};
