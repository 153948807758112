import { Button } from '@vwfs-bronson/bronson-react';
import React from 'react';

type CookieWarningUiProps = {
    warningText: string | React.ReactNode;
    secondLineText?: string | React.ReactNode;
    linkText: string | React.ReactNode;
    acceptLabel: string | React.ReactNode;
    accepted?: boolean;
    setCookiesAccepted: () => void;
    cookiePolicyLinkClickHandler?: () => void;
    cookiePolicyLink: string;
};

export const CookieWarningUi: React.FC<CookieWarningUiProps> = ({
    warningText,
    secondLineText,
    acceptLabel,
    accepted,
    setCookiesAccepted,
    cookiePolicyLink,
    linkText,
    cookiePolicyLinkClickHandler,
}) => {
    if (accepted) {
        return null;
    }

    return (
        <div className="c-sticky-container  js-sticky-container">
            <div className="c-sticky-container__inner cookie-warning">
                <p>
                    {warningText}
                    <Button
                        element="a"
                        href={cookiePolicyLink}
                        onClick={() => !!cookiePolicyLinkClickHandler && cookiePolicyLinkClickHandler()}
                    >
                        {linkText}
                    </Button>
                    {'.'}
                </p>
                {secondLineText && <p>{secondLineText}</p>}
                <Button onClick={setCookiesAccepted}>{acceptLabel}</Button>
            </div>
        </div>
    );
};
