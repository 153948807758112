import { ActionCreatorWithPayload, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Reducer } from 'redux';

import { Error, WithDefaultCpIntegrationErrors } from '@cp-shared-8/common-utilities';

import { AppThunk } from './sliceTypeDefinitions';
import { AxiosRequestConfig } from 'axios';
import { AbstractDataState } from './AbstractDataState';
import { parseErrorResponse } from './parseErrorResponse';

export type ContractIndex = {
    contractId: string;
};

export type AbstractContractBasedDataState<TBusinessObject, TAppErrorCode extends string> = {
    [contractId: string]: AbstractDataState<TBusinessObject, TAppErrorCode>;
};
type FetchDataType = (contractId: string, link?: string, requestConfig?: AxiosRequestConfig) => AppThunk;
type UpdateDataType<TBusinessObject> = (contractId: string, data: TBusinessObject) => AppThunk;
type UpdateStateType<TBusinessObject, TAppErrorCode extends string> = (
    state: AbstractContractBasedDataState<TBusinessObject, TAppErrorCode>,
) => AppThunk;

type ApiDataSlice<TBusinessObject, TAppErrorCode extends string> = {
    reducer: Reducer<AbstractContractBasedDataState<TBusinessObject, TAppErrorCode>>;
    fetchData: FetchDataType;
    updateData: UpdateDataType<TBusinessObject>;
    updateState: UpdateStateType<TBusinessObject, TAppErrorCode>;
};

type ContractBasedDataFetchParams<TBusinessObject> = {
    dataName: string;
    initialData?: TBusinessObject;
    fetchCallback: (link: string, requestConfig?: AxiosRequestConfig) => Promise<TBusinessObject>;
};

interface State {
    isLoading: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadingError?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any;
    failedLoadingAttempts: number;
    hasReceivedResponse: boolean;
}

interface IObjectKeys {
    [key: string]: State;
}

export function createGetContractBasedDataFetchSlice<TBusinessObject, TAppErrorCode extends string>({
    dataName,
    fetchCallback,
}: ContractBasedDataFetchParams<TBusinessObject>): ApiDataSlice<TBusinessObject, TAppErrorCode> {
    type SuccessAction = { data: TBusinessObject; contractId: string };
    type ErrorAction = {
        error: Error<WithDefaultCpIntegrationErrors<TAppErrorCode>>;
    } & ContractIndex;
    type UpdateDataAction = { data: TBusinessObject; contractId: string };

    const fetchSlice = createSlice({
        name: dataName,
        initialState: {},
        reducers: {
            startFetching(state, action: PayloadAction<ContractIndex>): void {
                (state as IObjectKeys)[action.payload.contractId] = {
                    isLoading: true,
                    loadingError: undefined,
                    data: undefined,
                    failedLoadingAttempts:
                        (state as IObjectKeys)[action.payload.contractId]?.failedLoadingAttempts ?? 0,
                    hasReceivedResponse: false,
                };
            },
            fetchDataSuccess(state, action: PayloadAction<SuccessAction>): void {
                (state as IObjectKeys)[action.payload.contractId] = {
                    data: action.payload.data,
                    loadingError: undefined,
                    isLoading: false,
                    failedLoadingAttempts: 0,
                    hasReceivedResponse: true,
                };
            },
            fetchDataFailure(state, action: PayloadAction<ErrorAction>): void {
                (state as IObjectKeys)[action.payload.contractId] = {
                    data: undefined,
                    loadingError: action.payload.error,
                    isLoading: false,
                    failedLoadingAttempts: (state as IObjectKeys)[action.payload.contractId]?.failedLoadingAttempts
                        ? (state as IObjectKeys)[action.payload.contractId].failedLoadingAttempts + 1
                        : 1,
                    hasReceivedResponse: true,
                };
            },
            updateData(state, action: PayloadAction<UpdateDataAction>): void {
                (state as IObjectKeys)[action.payload.contractId] = {
                    ...(state as IObjectKeys)[action.payload.contractId],
                    data: action.payload.data,
                };
            },
            updateState(
                state,
                action: PayloadAction<AbstractContractBasedDataState<TBusinessObject, TAppErrorCode>>,
            ): void {
                Object.entries(action.payload).forEach(([key, value]) => {
                    (state as IObjectKeys)[key] = { ...(state as IObjectKeys)[key], ...value };
                });
            },
        },
    });

    const {
        fetchDataSuccess,
        fetchDataFailure,
        startFetching,
        updateData: updateDataActionCreator,
        updateState: updateStateActionCreator,
    } = fetchSlice.actions;

    const fetchData = (contractId: string, link?: string, requestConfig?: AxiosRequestConfig): AppThunk => async (
        dispatch,
    ): Promise<void> => {
        if (!link) {
            return;
        }

        try {
            dispatch(startFetching({ contractId }));
            const data = await fetchCallback(link, requestConfig);
            dispatch((fetchDataSuccess as ActionCreatorWithPayload<SuccessAction>)({ data, contractId }));
        } catch (fetchError) {
            const errorDescription = parseErrorResponse<TAppErrorCode>(fetchError);
            dispatch(
                (fetchDataFailure as ActionCreatorWithPayload<ErrorAction>)({ error: errorDescription, contractId }),
            );
        }
    };

    const updateData = (contractId: string, data: TBusinessObject): AppThunk => (dispatch): void => {
        dispatch(updateDataActionCreator({ contractId, data }));
    };

    const updateState = (state: AbstractContractBasedDataState<TBusinessObject, TAppErrorCode>): AppThunk => (
        dispatch,
    ): void => {
        dispatch(updateStateActionCreator(state));
    };

    return {
        reducer: fetchSlice.reducer,
        fetchData,
        updateData,
        updateState,
    };
}
