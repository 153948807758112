import React from 'react';
import { DataOverview as DataOverviewBronson } from '@vwfs-bronson/bronson-react';

export type DataOverviewProps = {
    className?: string;
    icon?: string;
    title?: string;
    buttonLabel?: string;
    buttonProps?: object;
    testId?: string;
    withoutCardEffect?: boolean;
    headerLanguage?: string;
};

/**
 * Wraps the Bronson React DataOverview to introduce a short-term workaround aligned with the Bronson & UX teams
 * Should be removed as soon this becomes part of the Bronson React DataOverview or another solution is found
 */
export const DataOverview: React.FC<DataOverviewProps> = ({ withoutCardEffect, ...props }) =>
    withoutCardEffect ? (
        <DataOverviewBronson style={{ boxShadow: 'none', border: 'none', paddingLeft: '0px' }} {...props} />
    ) : (
        <DataOverviewBronson {...props} />
    );
