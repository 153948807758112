import { Spinner as SpinnerShared } from '@vwfs-bronson/bronson-react';
import React from 'react';

export type SpinnerProps = {
    /**
     * Set className of the spinner
     */
    className?: string;
    /**
     * Added for data-testid attribute
     */
    testId?: string;
    /**
     * Set this to true to render a smaller version of the spinner
     */
    small?: boolean;
    /**
     * Set this to true to render the spinner in the center of the component it is wrapped in
     */
    center?: boolean;
    /**
     * Set this to true to render the spinner in the center of the whole page
     */
    fullPage?: boolean;
    /**
     * Set this to true to render the spinner only on a specific component you assign it to
     */
    section?: boolean;
};

export const Spinner: React.FC<SpinnerProps> = ({ className, testId, small, center, fullPage, section }) => {
    return (
        <SpinnerShared
            className={className}
            testId={testId}
            small={small}
            center={center}
            fullPage={fullPage}
            section={section}
            style={{ pointerEvents: 'auto' }}
        />
    );
};
