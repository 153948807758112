import React, { ComponentType, FC, ReactElement } from 'react';
import { Route, RouteProps } from 'react-router-dom';

export function createDecoratedRoute(displayName: string, ...decorators: ComponentType[]): FC<RouteProps> {
    const Component: FC<RouteProps> = ({ children, ...rest }): ReactElement =>
        decorators.reduceRight((acc, Decorator) => <Decorator>{acc}</Decorator>, <Route {...rest}>{children}</Route>);

    Component.displayName = displayName;

    return Component;
}
