import { Layout } from '@vwfs-bronson/bronson-react';
import * as React from 'react';

import { ContractSummaryItem, ContractSummaryItemProps } from './ContractSummaryItem';

export type ContractSummaryProps = {
    items: Array<ContractSummaryItemProps>;
};

/**
 * A contract summary usually consisting up to three items
 */
export const ContractSummary: React.FC<ContractSummaryProps> = ({ items }) => {
    if (items.length === 0) {
        return null;
    }
    return (
        <Layout>
            {items.map(summaryItem => (
                <Layout.Item key={summaryItem.label} default={'1/3'} s={'1/1'}>
                    <ContractSummaryItem
                        key={summaryItem.label}
                        label={summaryItem.label}
                        value={summaryItem.value}
                        tooltip={summaryItem.tooltip}
                        testClass={summaryItem.testClass}
                        testId={summaryItem.testId}
                        onTooltip={summaryItem.onTooltip}
                    />
                </Layout.Item>
            ))}
        </Layout>
    );
};
