import React, { MouseEventHandler } from 'react';
import { Moment } from 'moment-timezone';
import { Inbox } from '@vwfs-bronson/bronson-react';
import { Notification, NotificationStatus } from '../../notification';
import { Spinner } from '../../spinner';
import './PostboxTable.css';

export type PostboxDocumentDate = Moment;

export type PostboxDocument = {
    documentId: string | number;
    documentType: string | number;
    contractIdentifier?: string | number;
    date: PostboxDocumentDate;
    subject: React.ReactNode;
    extraInfo?: string;
    onClick: MouseEventHandler;
    onDeleteClick?: MouseEventHandler;
    read?: boolean;
    loading?: boolean;
    publisher?: string;
    expiryDate?: string;
    showDelete?: boolean;
    tooltipContent?: string;
};

export type PostboxTableLocalization = {
    'month.01.name': string;
    'month.01.name.short': string;
    'month.02.name': string;
    'month.02.name.short': string;
    'month.03.name': string;
    'month.03.name.short': string;
    'month.04.name': string;
    'month.04.name.short': string;
    'month.05.name': string;
    'month.05.name.short': string;
    'month.06.name': string;
    'month.06.name.short': string;
    'month.07.name': string;
    'month.07.name.short': string;
    'month.08.name': string;
    'month.08.name.short': string;
    'month.09.name': string;
    'month.09.name.short': string;
    'month.10.name': string;
    'month.10.name.short': string;
    'month.11.name': string;
    'month.11.name.short': string;
    'month.12.name': string;
    'month.12.name.short': string;
};

export type PostboxColumnType =
    | 'colDate'
    | 'colDownload'
    | 'colSubject'
    | 'colDelete'
    | 'col-publisher'
    | 'col-expiry-date';

type TableHeaderClassNames = {
    [key: string]: string;
};

type TableColumnClassNames = {
    [key: string]: string;
};

export type PostboxTableProps = {
    filterTitle?: React.ReactNode;
    filterElements?: Array<React.ReactNode>;
    documents: PostboxDocument[];
    tableHeaderColumnLabels?: {
        date: string;
        action?: string;
        subject: string;
        delete?: string;
    };
    /**
     * Locale for month translations
     * Bronson react currently only hase locale for de. When you need other date translations you need to provide id within localization
     */
    locale?: 'de';
    localization?: PostboxTableLocalization;
    dateFormatter?: (day: string, month: string, year: string) => string;
    overridedColumns?: Array<PostboxColumnType>;
    overridedHeaders?: Array<string | React.ReactNode>;
    tableHeaderClassNames?: TableHeaderClassNames;
    tableColumnClassNames?: TableColumnClassNames;
    noDocumentsSelectedErrorText: string;
    downloadButtonLabel?: string;
    downloadShowLabel?: boolean;
    deleteButtonLabel?: string;
    deleteShowLabel?: boolean;
    sortingOrder?: 'ASC' | 'DESC';
};

export const PostboxTable: React.FC<PostboxTableProps> = ({
    filterTitle,
    filterElements,
    documents,
    tableHeaderColumnLabels,
    locale,
    localization,
    noDocumentsSelectedErrorText,
    overridedColumns,
    overridedHeaders,
    tableHeaderClassNames,
    tableColumnClassNames,
    dateFormatter,
    downloadButtonLabel,
    downloadShowLabel = false,
    deleteButtonLabel,
    deleteShowLabel = false,
    sortingOrder = 'ASC',
}) => {
    function removeDuplicatesFromArray<T>(inputArray: T[]) {
        return Array.from(new Set(inputArray)) as T[];
    }

    const allYearsInAllDocuments = documents.map(document => document.date.format('YYYY'));

    const availableYears = removeDuplicatesFromArray(allYearsInAllDocuments);

    return (
        <Inbox
            filterTitle={filterTitle}
            filterElements={filterElements}
            testId={'InboxTable'}
            columns={overridedColumns}
            locale={locale}
            dateFormatter={dateFormatter}
            localization={localization}
            tableHeaderClassNames={tableHeaderClassNames}
            tableColumnClassNames={tableColumnClassNames}
            tableHeader={
                overridedHeaders
                    ? overridedHeaders
                    : [
                          <>{tableHeaderColumnLabels?.date}</>,
                          <>{tableHeaderColumnLabels?.action ? tableHeaderColumnLabels.action : '\u00A0'}</>,
                          <>{tableHeaderColumnLabels?.subject}</>,
                          <>{tableHeaderColumnLabels?.delete ? tableHeaderColumnLabels?.delete : '\u00A0'}</>,
                      ]
            }
            downloadButtonLabel={downloadButtonLabel}
            downloadShowLabel={downloadShowLabel}
            deleteButtonLabel={deleteButtonLabel}
            deleteShowLabel={deleteShowLabel}
            sortingOrder={sortingOrder}
        >
            {documents.length === 0 ? (
                <Inbox.Section key={'none'} year={''} showTableHeader>
                    <tr>
                        <td colSpan={overridedHeaders?.length || 4}>
                            <Notification status={NotificationStatus.info} text={noDocumentsSelectedErrorText} />
                        </td>
                    </tr>
                </Inbox.Section>
            ) : (
                availableYears.map(sectionYear => (
                    <Inbox.Section year={sectionYear} showYear showTableHeader key={'InboxSection-' + sectionYear}>
                        {documents
                            .filter(document => document.date.format('YYYY') === sectionYear)
                            .map(document =>
                                document.loading ? (
                                    <SpinnerMessage
                                        key={'InboxRow-' + document.documentId}
                                        colSpan={overridedHeaders?.length || 4}
                                        year={document.date.format('YYYY')}
                                        month={document.date.format('MM')}
                                        day={document.date.format('DD')}
                                    />
                                ) : (
                                    <Inbox.Message
                                        testId={'InboxRow-' + document.documentId}
                                        key={'InboxRow-' + document.documentId}
                                        year={document.date.format('YYYY')}
                                        day={document.date.format('DD')}
                                        month={document.date.format('MM')}
                                        extraInfo={document.extraInfo}
                                        download={!document.showDelete}
                                        read={document.read}
                                        onItemClick={document.onClick}
                                        onDownloadClick={document.onClick}
                                        onDeleteClick={document.onDeleteClick}
                                        col-publisher={document.publisher || ' '}
                                        col-expiry-date={document.expiryDate || ' '}
                                        messageKey={`document-${document.documentId}`}
                                        tooltipContent={document.tooltipContent}
                                    >
                                        {document.subject}
                                    </Inbox.Message>
                                ),
                            )}
                    </Inbox.Section>
                ))
            )}
        </Inbox>
    );
};

type SpinnerMessageProps = {
    colSpan: number;
    // These three fields are needed to ensure correct ordering of the spinner by react-bronson
    year: string;
    day: string;
    month: string;
};

const SpinnerMessage: React.FC<SpinnerMessageProps> = ({ colSpan }) => (
    <tr>
        <td colSpan={colSpan}>
            <Spinner small />
        </td>
    </tr>
);
