import React, { useLayoutEffect, useRef } from 'react';

import { getDisplayName } from '../hoc-utilities';

export const withAutoScroll = <TProps extends object>(
    WrappedComponent: React.ComponentType<TProps>,
    /**
     * Should be used to prevent anonymous components that are defined directly inside withAutoScroll
     */
    wrappedComponentDisplayName?: string,
): React.FC<TProps> => {
    const Wrapper: React.FC<TProps> = (props: TProps) => {
        const scrollRef = useRef<HTMLDivElement>(null);

        useLayoutEffect(() => {
            if (scrollRef && scrollRef.current) {
                window.scrollTo(0, scrollRef.current.offsetTop);
            }
        }, [scrollRef]);

        return (
            <div ref={scrollRef}>
                <WrappedComponent {...props} />
            </div>
        );
    };

    if (wrappedComponentDisplayName) {
        WrappedComponent.displayName = wrappedComponentDisplayName;
    }

    Wrapper.displayName = `withAutoScroll(${getDisplayName(WrappedComponent)})`;

    return Wrapper;
};
