import * as H from 'history';
import { FormEventHandler, MouseEventHandler } from 'react';

export function isRelativeUrl(url: string): boolean {
    return (url.startsWith('/') && !url.startsWith('://')) || url.startsWith('#');
}

export function navigateTo(history: H.History, url?: string): void {
    if (url) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        isRelativeUrl(url) ? history.push(url) : (document.location.href = url);
    }
}

export const openInNewWindow = function(url?: string): MouseEventHandler {
    return (): Window | null => window.open(url, '_blank');
};

export const openInSameWindow = function(url?: string): MouseEventHandler {
    return (): string => (window.location.href = url || '#');
};

export const preventSubmit: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
};

export const preventMouseEvent: MouseEventHandler = e => {
    e.preventDefault();
};
