import { CustomerIndexIdentifierType } from '../types';

export function sendCustomerIndexEndpoint(): string {
    return '/customer-index/send';
}

export function searchCustomerIndexEndpoint(): string {
    return '/customer-index/search';
}

export function deleteCustomerIndexItemEndpoint(identifierType: CustomerIndexIdentifierType): string {
    return `/customer-index/delete-item/${identifierType}`;
}
