import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Button } from '../Button/Button';
/**
 * Bronson FormSection component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-form-section.html
 * @constructor
 */
export function FormSection({ 
// disableJsFx,
children, className, defaultOpen = false, disabled, error, linkLabel, noTruncate, onChange, onClick, onLinkClick, subtitle, success, title, testId, ...otherProps }) {
    const [open, setOpen] = useState(defaultOpen);
    useEffect(() => {
        setOpen(defaultOpen);
    }, [defaultOpen]);
    function toggleOpen() {
        setOpen(!open);
        if (onChange) {
            onChange(!open);
        }
    }
    const divClassNameList = classNames('c-form-section', 'js-form-section', 'js-is-ready', {
        'js-is-fx': false,
        'is-open': open,
        // Convenience prop from Bronson variants.
        'c-form-section--error': error,
        'c-form-section--success': success,
    }, className).trim();
    const sectionHeaderClassNameList = classNames('c-form-section__header', 'js-form-section__header', {
        'c-form-section__header--no-truncate': noTruncate,
    }, className).trim();
    const sectionPanelClassNameList = classNames('c-form-section__panel', 'js-form-section__panel', {
        'is-open': open,
    }, className).trim();
    function renderHeaderContent() {
        return (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "c-form-section__title js-form-section__trigger" },
                React.createElement("span", { className: "c-form-section__title-text", role: "heading" }, title),
                renderIfFormSectionShowLink()),
            renderIfFormSectionSubtitle()));
    }
    function renderIfFormSectionShowLink() {
        if (linkLabel) {
            return (React.createElement(Button, { element: "span", className: "c-form-section__title-link", link: true, small: true, onClick: onLinkClick }, linkLabel));
        }
        return null;
    }
    function renderIfFormSectionSubtitle() {
        if (subtitle) {
            return React.createElement("span", { className: "c-form-section__subtitle" }, subtitle);
        }
        return null;
    }
    const panelClosedStyle = {
        height: '0px',
        overflowY: 'hidden',
        visibility: 'hidden',
    };
    return (
    /**
     * Need to pass `[inert=""]` as React currently does not support HTMLElement.inert mapping.
     * @see https://github.com/facebook/react/pull/24730
     * @TODO: Remove once React supports direct `[inert]` usage.
     * @see BRON-11871
     */
    // eslint-disable-next-line jsx-a11y/role-supports-aria-props
    React.createElement("section", { ...otherProps, "data-testid": testId, className: divClassNameList, "aria-disabled": disabled, 
        // @ts-ignore @TODO: Remove once React supports inerts, @see BRON-11871.
        inert: disabled ? '' : null },
        React.createElement("button", { className: sectionHeaderClassNameList, "aria-expanded": open, onClick: () => !disabled && [toggleOpen(), onClick && onClick()], type: "button" },
            React.createElement("span", { className: "c-form-section__header-content" }, renderHeaderContent()),
            React.createElement("span", { className: "c-form-section__header-icon" })),
        React.createElement("div", { className: sectionPanelClassNameList, style: open ? {} : panelClosedStyle, "aria-hidden": !open, 
            // @ts-ignore @TODO: Remove once React supports inerts, @see BRON-11871.
            inert: open ? null : '' },
            React.createElement("div", { className: "c-form-section__content" }, children))));
}
