import React, { useState } from 'react';
import classNames from 'classnames';
import { ButtonContainer } from '../ButtonContainer/ButtonContainer';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import uuidv4 from '../../utils/uuidv4';
/**
 * Bronson Card component.
 *
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-cards.html
 *
 * @constructor
 */
export function Card({ additionalInfo, buttons, children, className, contentLarge, contentShort, defaultExpanded, disabled, element, error, expandable, expandableTestId, footer, footerContent, icon, iconList, imageAlt, imageProps, imageSrc, inactive, mediaElement, responsiveCollapse, scrollable, small, subtitle, subtitleClassName, success, tags, title, titleClassName, warning, testId, ...otherProps }) {
    /**
     * Track the expanded state of the {@link Card}.
     */
    const [expanded, setExpanded] = useState(defaultExpanded);
    const [responsiveCollapsed, setResponsiveCollapsed] = useState(true);
    const CustomTagCardElement = `${element}`;
    const bodyId = uuidv4('bron-card-body-');
    const expandablePanelId = uuidv4('bron-card-expandable-panel-');
    const customTagClassNameList = classNames('c-card', {
        'c-card--image': imageSrc || imageProps,
        'c-card--button-group': buttons && buttons.length > 0,
        'c-card--states': success || warning || error,
        'c-card--states-success': success,
        'c-card--states-warning': warning,
        'c-card--states-error': error,
        'c-card--inactive': inactive,
        'c-card--scrollable': scrollable,
        'c-card--expandable': expandable,
        'c-card--responsive-collapse': responsiveCollapse,
        'c-card--small': small,
        'js-card-responsive-collapse': responsiveCollapse,
        'js-is-ready': responsiveCollapse,
    }, className).trim();
    const expandableClassNameList = classNames('c-card__expandable', 'js-card-expandable', 'js-is-fx', 'js-is-ready', {
        'is-open': expanded,
    }).trim();
    const expandablePanelClassNameList = classNames('c-card__expandable-panel', 'js-card-expandable__panel', 'js-is-fx', 'js-is-ready', {
        'is-closed': !expanded,
    }).trim();
    const bodyClassNameList = classNames('c-card__body', {
        'js-card__body': responsiveCollapse,
        'js-is-ready': responsiveCollapse,
        'is-closed': responsiveCollapse && responsiveCollapsed,
    }).trim();
    const expandablePanelStyle = expanded
        ? {}
        : {
            height: '0px',
            overflowY: 'hidden',
            visibility: 'hidden',
        };
    function renderIfIcon() {
        if (icon) {
            return (React.createElement("div", { className: "c-card__icon" },
                React.createElement(Icon, { name: icon })));
        }
        return null;
    }
    function renderIfImage() {
        if (imageSrc || imageProps) {
            return (React.createElement("div", { className: "c-card__image" },
                React.createElement("img", { src: imageSrc, alt: imageAlt, ...imageProps })));
        }
        return null;
    }
    const renderIfMedia = () => {
        if (mediaElement) {
            return React.createElement("div", { className: "c-card__image" }, mediaElement);
        }
        return null;
    };
    const subtitleClassNameList = classNames('c-card__subtitle', subtitleClassName).trim();
    function renderIfSubtitle() {
        if (subtitle) {
            return React.createElement("p", { className: subtitleClassNameList }, subtitle);
        }
        return null;
    }
    function renderIfResponsiveCollapseButton() {
        if (responsiveCollapse) {
            return (React.createElement("button", { className: "js-card-responsive-collapse__toggle c-card--responsive-collapse__toggle", type: "button", "aria-label": "Toggle Card", "aria-expanded": responsiveCollapsed ? 'false' : 'true', "aria-controls": bodyId, disabled: inactive ? true : undefined, onClick: () => {
                    setResponsiveCollapsed(!responsiveCollapsed);
                } }));
        }
        return null;
    }
    const titleClassNameList = classNames('c-card__title', titleClassName).trim();
    function renderIfTitle() {
        if (title) {
            return (React.createElement("header", { className: "c-card__header" },
                expandable || responsiveCollapse ? (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "c-card__header-items" },
                        React.createElement("h4", { className: titleClassNameList }, title),
                        expandable && contentShort,
                        renderIfResponsiveCollapseButton()))) : (React.createElement(React.Fragment, null,
                    React.createElement("h4", { className: titleClassNameList }, title))),
                renderIfSubtitle()));
        }
        return null;
    }
    function renderIfButtons() {
        if (buttons) {
            return Array.isArray(buttons) ? React.createElement(ButtonContainer, { center: true }, buttons) : buttons;
        }
        return null;
    }
    function renderIfContentLarge() {
        if (contentLarge) {
            return React.createElement(React.Fragment, null, contentLarge);
        }
        return null;
    }
    function renderIfIsExpandable() {
        if (expandable) {
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: expandableClassNameList, "aria-disabled": disabled && 'true' },
                    React.createElement(Button, { "aria-controls": expandablePanelId, "aria-expanded": expanded ? 'true' : 'false', "aria-label": "Toggle Card", className: "c-btn--card c-card__expandable-btn js-card-expandable__trigger", "data-testid": expandableTestId, icon: "semantic-expand", iconClassName: "c-btn--card-icon", type: "button", disabled: inactive ? true : undefined, onClick: () => {
                            setExpanded(!expanded);
                        } }),
                    React.createElement("div", { className: expandablePanelClassNameList, style: expandablePanelStyle, "aria-hidden": expanded ? 'false' : 'true', 
                        // @ts-ignore @TODO: Remove once React supports inerts, @see BRON-11871.
                        inert: expanded ? null : '', id: expandablePanelId }, renderIfContentLarge()))));
        }
        return null;
    }
    function renderIfAdditionalInfo() {
        if (additionalInfo) {
            return React.createElement("div", { className: "u-text-center" }, additionalInfo);
        }
        return null;
    }
    function renderIfFooter() {
        if (footer) {
            return (React.createElement("footer", { className: "c-card__footer" },
                renderIfButtons(),
                footerContent));
        }
        return null;
    }
    return (React.createElement(CustomTagCardElement, { ...otherProps, "data-testid": testId, className: customTagClassNameList },
        renderIfIcon(),
        renderIfImage(),
        renderIfMedia(),
        renderIfTitle(),
        React.createElement("div", { className: bodyClassNameList, id: responsiveCollapse ? bodyId : undefined },
            children,
            renderIfIsExpandable()),
        renderIfAdditionalInfo(),
        renderIfFooter()));
}
