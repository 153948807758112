import { Notification, Heading, Button, ContentSection } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { NotificationStatus } from '../notification';

export type ErrorPageProps = {
    headline?: string;
    headlineLevel?: string | 2 | 1 | 3 | 4 | 5 | 6;
    className?: string;
    testId?: string;
    pageWrapSize?: 'medium' | 'small' | 'xsmall' | 'xxsmall';
    notificationHeadline?: string;
    notificationText?: string;
    buttonLabel?: string | React.ReactNode;
    onButtonClick?: () => void;
};

export const ErrorPage: React.FC<ErrorPageProps> = ({
    headline,
    headlineLevel = 2,
    className,
    testId,
    pageWrapSize,
    notificationHeadline,
    notificationText,
    buttonLabel,
    onButtonClick,
}) => (
    <ContentSection pageWrapSize={pageWrapSize} className={`${className || ''} u-text-center`} testId={testId}>
        <Heading level={headlineLevel} testId={`${testId}-headline`} className={'u-mb-large'}>
            {headline}
        </Heading>
        <Notification
            visible
            title={notificationHeadline}
            status={NotificationStatus.error}
            showCloseButton={false}
            text={notificationText}
            testId={`${testId}-notification`}
        />
        {!!buttonLabel && (
            <Button onClick={onButtonClick} testId={`${testId}-button`} className={'u-mt-large'}>
                {buttonLabel}
            </Button>
        )}
    </ContentSection>
);
