import { Context } from 'react';
import { AuthorizationState } from '../context/create-authorization-context';
import { AuthorizationFlowState, AuthorizationResult } from '../context';
import { createInitiateAuthorizationFunction } from './create-initiate-authorization-function';
import { useAuthorizationState } from './use-authorization-state';

export type AuthorizationFlowHookResult<T> = {
    state: AuthorizationFlowState<T>;
    initiate: () => void;
    confirm: (newState: AuthorizationFlowState<T>) => void;
};

export function createAuthorizationFlowHook<T>(
    AuthorizationContext: Context<AuthorizationState<T>>,
    authorizationDataProvider: () => Promise<AuthorizationResult<T>>,
): () => AuthorizationFlowHookResult<T> {
    return (): AuthorizationFlowHookResult<T> => {
        const [state, setState] = useAuthorizationState<T>(AuthorizationContext);
        return {
            state,
            initiate: createInitiateAuthorizationFunction(authorizationDataProvider, state, setState),
            confirm: setState,
        };
    };
}
