import * as React from 'react';
import { Layout, Card } from '@vwfs-bronson/bronson-react';

export type DividedContractHeaderProps = {
    /**
     * The complete car information (e.g. "VW CALIFORNIA 2.0BITDI SE 180 4DR 4MTN DSG")
     */
    carInformation: string;
    /**
     * The contract category to be shown to the customer (e.g. "Operating Lease")
     */
    contractCategory: string;
    /**
     * The contract number
     */
    contractNumber: string;
    /**
     * If the contract was terminated by VWFS, add here a label (e.g. "Terminated")
     */
    terminationRemark?: string;
    /**
     * A license plate or any comparable contract identifier (@see LicensePlate)
     */
    contractIdentifier?: React.ReactNode;
    /**
     * Any extra information about the contract
     */
    extraInformation?: string;
    /**
     * Component to be shown on the right side of divider
     */
    rightSideContent?: React.ReactNode;
};

export const DividedContractHeader: React.FC<DividedContractHeaderProps> = ({
    carInformation,
    contractCategory,
    contractNumber,
    terminationRemark,
    contractIdentifier,
    extraInformation,
    rightSideContent,
}) => {
    const terminationComponent = terminationRemark ? <span className="u-text-alert"> {terminationRemark}</span> : '';

    const carInformationPart = (
        <div className="u-text-left@m-up u-mr-none cp-contract-identifier-slot">
            {contractIdentifier}
            <div className="c-form-section__title u-mt-small">
                <span className="c-form-section__title-text">{carInformation}</span>
            </div>

            <div className="c-form-section__subtitle cp-summary-contract-information">
                <span className="cp-contract-category">{contractCategory}</span>
                <span className="cp-contract-id"> {contractNumber}</span>
                {terminationComponent}
            </div>

            <div className="u-text-left">{extraInformation}</div>
        </div>
    );

    return (
        <Card element="article">
            {rightSideContent ? (
                <Layout equalHeight divider>
                    <Layout.Item default="1/2" s="1/1">
                        {carInformationPart}
                    </Layout.Item>
                    <Layout.Item default="1/2" s="1/1">
                        {rightSideContent}
                    </Layout.Item>
                </Layout>
            ) : (
                <Layout>
                    <Layout.Item default="1/1">{carInformationPart}</Layout.Item>
                </Layout>
            )}
        </Card>
    );
};
