/**
 * Handles the adding, updating and deletion of filters.
 */
export const productFilterReducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE_FILTER': {
            return {
                ...state,
                filters: action.payload, // @TODO: just if update always holds the complete filter state
            };
        }
        case 'DELETE_FILTER': {
            const filterToReset = state.defaultFilters.find((f) => f.field === action.payload);
            return {
                ...state,
            };
        }
        case 'DELETE_ALL_FILTER': {
            return { ...state, filters: state.defaultFilters };
        }
        case 'HIDE_FILTERS':
            return { ...state, isInteractionLayerHidden: true };
        case 'SHOW_FILTERS':
            return { ...state, isInteractionLayerHidden: false };
        default:
            throw new Error('ProductSearchReducer → Unknown action.');
    }
};
export const productFilterReducerActions = {
    updateFilter: 'UPDATE_FILTER',
    deleteFilter: 'DELETE_FILTER',
    deleteAllFilter: 'DELETE_ALL_FILTER',
    hideFilters: 'HIDE_FILTERS',
    showFilters: 'SHOW_FILTERS',
};
