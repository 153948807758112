import React from 'react';
import { ContractHeaderLoadingPlaceholder } from '../contract-header-loading-placeholder';
import { Skeleton } from '../Skeleton';
import { Card, ContentSection, Layout } from '@vwfs-bronson/bronson-react';
import { DividedContractHeaderLoadingPlaceholder } from '../divided-contract-header-loading-placeholder';

export type EarlySettlementLoadingPlaceholderProps = {
    withContractHeader?: boolean;
    withDividedContractHeader?: boolean;
    numberOfButtons?: number;
    withDocumentUpload?: boolean;
    summaryElements?: number;
};

export const EarlySettlementLoadingPlaceholder: React.FC<EarlySettlementLoadingPlaceholderProps> = ({
    withContractHeader = true,
    withDividedContractHeader = false,
    numberOfButtons = 2,
    withDocumentUpload = false,
    summaryElements = 0,
}) => {
    return (
        <>
            {withContractHeader && !withDividedContractHeader && (
                <Card element="article">
                    <ContractHeaderLoadingPlaceholder summaryElements={0} />
                </Card>
            )}
            {withDividedContractHeader && <DividedContractHeaderLoadingPlaceholder withExtraInformation={true} />}
            <ContentSection className={'u-text-center'}>
                {!!summaryElements && <Skeleton count={summaryElements} height={50} />}
                <Layout>
                    <Layout.Item>
                        <Skeleton height={200} />
                    </Layout.Item>
                    {withDocumentUpload && (
                        <Layout.Item>
                            <Skeleton height={170} />
                        </Layout.Item>
                    )}
                </Layout>
                {Array.from(Array(numberOfButtons).keys()).map(key => (
                    <Skeleton height={40} width={200} className="u-mt u-m-xsmall" key={key} />
                ))}
            </ContentSection>
        </>
    );
};
