import React from 'react';
import { Table } from '@vwfs-bronson/bronson-react';
import { Cell } from '../DynamicTableOptions';

export type TableSummaryProps = {
    summaryRow: Cell[];
};

export const TableSummary: React.FC<TableSummaryProps> = ({ summaryRow }) => {
    return (
        <Table.Tfoot>
            <Table.Tr>
                {summaryRow.map((cellData, cellIndex) => {
                    if (typeof cellData === 'string') {
                        return (
                            <Table.Th className="u-bg-brand u-text-white" key={cellIndex}>
                                {cellData}
                            </Table.Th>
                        );
                    }
                    return (
                        <Table.Th
                            key={cellIndex}
                            className={[
                                'u-bg-brand u-text-white',
                                !!cellData.hiddenForMobile ? 'u-hide@xs' : '',
                                !!cellData.textAlign ? `u-text-${cellData.textAlign}` : '',
                            ]
                                .filter(Boolean)
                                .join(' ')}
                        >
                            {cellData?.value}
                        </Table.Th>
                    );
                })}
            </Table.Tr>
        </Table.Tfoot>
    );
};
