// Inspired by https://reacttraining.com/react-router/web/example/auth-workflow
import React from 'react';
import { RouteProps } from 'react-router';
import { Route, Redirect } from 'react-router-dom';

export type PrivateRouteProps = {
    isAuthorized: () => boolean;
    notAuthorizedUrl: string;
} & RouteProps;

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
    isAuthorized,
    notAuthorizedUrl,
    children,
    ...otherProps
}) => (
    <Route
        {...otherProps}
        render={({ location }) =>
            isAuthorized() ? (
                children
            ) : (
                <Redirect
                    to={{
                        pathname: notAuthorizedUrl,
                        state: { from: location },
                    }}
                />
            )
        }
    />
);
