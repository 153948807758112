import { useKeycloak } from '@react-keycloak/web';
import { useEffect, useState } from 'react';

export function useAuthUserInfo() {
    const [keycloak] = useKeycloak();
    const [userInfo, setUserInfo] = useState<unknown>();

    useEffect(() => {
        keycloak.loadUserInfo().then(value => {
            setUserInfo(value);
        });
    }, [keycloak]);

    return userInfo;
}
