import { DatePicker, FormField } from '@vwfs-bronson/bronson-react';
import { useField, useFormikContext } from 'formik';
import React, { MouseEventHandler } from 'react';
import { InfoIcon } from '../../info-icon';
import { ValidationErrorMessage } from '../errors';
import { CustomLocale, key } from 'flatpickr/dist/types/locale';

export type ValidatedDateProps = {
    name: string;
    label: string;
    onLinkClick?: MouseEventHandler;
    type?: 'text';
    testId?: string;
    language: string;
    minDate?: string;
    maxDate?: string;
    outputDateFormat: string;
    excludeWeekend?: boolean;
    disableMobile?: boolean;
    tooltip?: string | React.ReactNode;
    value?: string;
    isMandatory?: boolean;
    placeholder?: string;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
    onFocus?: Function;
    customDisableDateConditions?: ((date: Date) => boolean)[];
    disabled?: boolean;
    hint?: string;
    maskedInput?: boolean;
};

export const ValidatedDate: React.FC<ValidatedDateProps & React.HTMLAttributes<HTMLInputElement>> = ({
    name,
    language,
    minDate,
    maxDate,
    outputDateFormat,
    excludeWeekend,
    disableMobile,
    label,
    onLinkClick,
    testId,
    tooltip,
    value,
    isMandatory = false,
    placeholder,
    onFocus,
    customDisableDateConditions = [],
    disabled,
    hint,
    maskedInput = false,
}) => {
    const { setFieldTouched, setFieldValue, getFieldMeta } = useFormikContext();
    const [, meta] = useField({ name, type: 'input' });

    const infoIcon = tooltip ? <InfoIcon text={tooltip} /> : null;

    const errorMessage = <ValidationErrorMessage meta={meta} testId={testId} />;

    const disabledDateConditions = [...customDisableDateConditions];
    if (excludeWeekend) disabledDateConditions.push((date: Date) => date.getDay() === 0 || date.getDay() === 6);

    return (
        <FormField
            type="input"
            onClick={onLinkClick}
            labelText={label}
            testId={`${testId}FormField`}
            errorMessage={errorMessage}
            infoIcon={infoIcon}
            notion={isMandatory}
            hint={hint}
        >
            <DatePicker
                name={name}
                placeholder={placeholder}
                dateFormat={outputDateFormat}
                flatpickrOptions={{
                    disable: disabledDateConditions,
                    locale: language as key | Partial<CustomLocale>,
                    disableMobile: !!disableMobile,
                    errorHandler: (e: unknown) => {
                        console.log(`Flatpickr error: ${e}`);
                    },
                }}
                maxDate={maxDate}
                minDate={minDate}
                onBlur={(): void => {
                    setFieldTouched(name, true, true);
                }}
                onChange={(value): void => {
                    setFieldValue(name, value, getFieldMeta(name).touched);
                }}
                value={value}
                onFocus={onFocus}
                disabled={disabled}
                maskedInput={maskedInput}
            />
        </FormField>
    );
};
