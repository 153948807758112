import { useContext, useEffect, useMemo, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { useLastLocation } from 'react-router-last-location';
import { MarketingPreferences } from './Analytics';
import { AnalyticsContext } from './AnalyticsProvider';
import { ActionType, ErrorTrackingId, TrackingId } from './AnalyticsTracker';

// Track page views
export const useAnalyticsPageViewTracker = (
    trackingId: TrackingId,
    dataLoaded = true,
    additionalValue?: string | boolean,
    secondAdditionalValue?: string | boolean,
    thirdAdditionalValue?: string | boolean,
): void => {
    const { trackPageView, userGroup, privacyStatement, isAuthenticated, brand, language, productId } = useContext(
        AnalyticsContext,
    );
    const viewTracked = useRef(false);
    useEffect(() => {
        // Wait until data are loaded to avoid multiple trackings due to several component mounts/unmounts
        if (trackPageView && !viewTracked.current && dataLoaded) {
            trackPageView(
                trackingId,
                {
                    currentLanguage: language,
                    privacyStatement,
                    userGroup,
                    loginStatus: isAuthenticated,
                    currentBrand: brand,
                    productId: productId,
                },
                additionalValue,
                secondAdditionalValue,
                thirdAdditionalValue,
            );
        }
    }, [dataLoaded, language, privacyStatement, userGroup, isAuthenticated, trackingId, additionalValue, brand]);
};

// Track error page views
export const useAnalyticsErrorPageTracker = (
    trackingId: ErrorTrackingId,
    dataLoaded = true,
    secondAdditionalValue?: string,
): void => {
    const { trackErrorPageView, userGroup, privacyStatement, isAuthenticated, brand, language, productId } = useContext(
        AnalyticsContext,
    );
    const viewTracked = useRef(false);
    const lastLocation = useLastLocation();
    const origin = window.location?.origin || '';
    const lastPathname = origin + (lastLocation?.pathname || '');
    useEffect(() => {
        if (trackErrorPageView && !viewTracked.current && dataLoaded) {
            trackErrorPageView(
                trackingId,
                {
                    currentLanguage: language,
                    privacyStatement,
                    userGroup,
                    loginStatus: isAuthenticated,
                    currentBrand: brand,
                    productId: productId,
                },
                lastPathname,
                secondAdditionalValue,
            );
            viewTracked.current = true;
        }
    }, [dataLoaded, language, privacyStatement, userGroup, isAuthenticated, trackingId, lastPathname, brand]);
};

type AnalyticsActionTracker = {
    onAction: (
        additionalValue?: unknown,
        secondAdditionalValue?: unknown,
        thirdAdditionalValue?: unknown,
        forthAdditionalValue?: unknown,
    ) => void;
};

// Track actions (e.g. button click)
export const useAnalyticsActionTracker = (action: ActionType): AnalyticsActionTracker => {
    const { trackAction, userGroup, privacyStatement, isAuthenticated, brand, language, productId } = useContext(
        AnalyticsContext,
    );
    const onAction = useMemo(
        () => (
            additionalValue?: unknown,
            secondAdditionalValue?: unknown,
            thirdAdditionalValue?: unknown,
            forthAdditionalValue?: unknown,
        ): void => {
            if (trackAction) {
                trackAction(
                    action,
                    {
                        currentLanguage: language,
                        privacyStatement,
                        userGroup,
                        loginStatus: isAuthenticated,
                        currentBrand: brand,
                        productId: productId,
                    },
                    additionalValue,
                    secondAdditionalValue,
                    thirdAdditionalValue,
                    forthAdditionalValue,
                );
            }
        },
        [action, language, privacyStatement, userGroup, isAuthenticated, brand],
    );
    return { onAction };
};

// Track if element enters view (e.g. open accordion element)
// isActive: set to false if it is needed to block this action for inactive contracts
export const useAnalyticsEnterViewportTracker = (trackActionId: ActionType, elementName: string, isActive = true) => {
    const [ref, inView] = useInView();
    const actionPerformed = useRef(false);
    const { trackAction, userGroup, privacyStatement, isAuthenticated, brand, language, productId } = useContext(
        AnalyticsContext,
    );
    useEffect(() => {
        if (trackAction && inView && !actionPerformed.current && isActive) {
            trackAction(
                trackActionId,
                {
                    currentLanguage: language,
                    privacyStatement,
                    userGroup,
                    loginStatus: isAuthenticated,
                    currentBrand: brand,
                    productId: productId,
                },
                elementName,
            );
            actionPerformed.current = true;
        }
        if (!inView && actionPerformed.current) {
            actionPerformed.current = false;
        }
    }, [inView, language, privacyStatement, userGroup, elementName, isAuthenticated, brand]);
    return { ref };
};

type AnalyticsFormTrackerProps = {
    onTyping: (errors?: Record<string, unknown>, touched?: Record<string, unknown>, additionalValue?: unknown) => void;
    onConfirmError: (
        errors: Record<string, unknown>,
        touched: Record<string, unknown>,
        additionalValue?: unknown,
    ) => void;
    onSubmit: (additionalValue?: unknown) => void;
    onError: (additionalValue?: unknown) => void;
    onSuccess: (additionalValue?: unknown, secondAdditionalValue?: unknown) => void;
};

const getErrors = (errors: Record<string, unknown>, touched: Record<string, unknown>): Record<string, unknown> => {
    const errorVals: Record<string, unknown> = {};
    for (const key of Object.keys(errors)) {
        if (touched[key]) {
            errorVals[key] = errors[key];
        }
    }
    return errorVals;
};

export type FormTrackingIds = {
    startTyping?: ActionType;
    formValidationError?: ActionType;
    confirm?: ActionType;
    confirmError?: ActionType;
    confirmSuccess?: ActionType;
};

// Track form actions (typing, submitting, error)
export const useAnalyticsFormTracker = (trackingIds?: FormTrackingIds): AnalyticsFormTrackerProps => {
    const { trackAction, userGroup, privacyStatement, isAuthenticated, brand, language, productId } = useContext(
        AnalyticsContext,
    );
    const typingStarted = useRef(false);
    const errorValues = useRef('');
    const onTyping = useMemo(
        () => (
            errors?: Record<string, unknown>,
            touched?: Record<string, unknown>,
            additionalValue?: unknown,
        ): void => {
            if (trackAction && trackingIds?.startTyping && !typingStarted.current) {
                trackAction(
                    trackingIds?.startTyping,
                    {
                        currentLanguage: language,
                        privacyStatement,
                        userGroup,
                        loginStatus: isAuthenticated,
                        currentBrand: brand,
                        productId: productId,
                    },
                    additionalValue,
                );
                typingStarted.current = true;
            }
            if (errors && touched && trackAction && trackingIds?.formValidationError) {
                const errorVals = getErrors(errors, touched);
                const stringifiedErrorVals = Object.keys(errorVals)
                    .map(k => {
                        return `${k}: ${errorVals[k]}`;
                    })
                    .join('|')
                    .toString();
                if (stringifiedErrorVals && stringifiedErrorVals !== errorValues.current) {
                    errorValues.current = stringifiedErrorVals;
                    trackAction(
                        trackingIds.formValidationError,
                        {
                            currentLanguage: language,
                            privacyStatement,
                            userGroup,
                            loginStatus: isAuthenticated,
                            currentBrand: brand,
                            productId: productId,
                        },
                        stringifiedErrorVals,
                        additionalValue,
                    );
                }
            }
        },
        [language, privacyStatement, userGroup, isAuthenticated, brand],
    );

    const onConfirmError = useMemo(
        () => (errors: Record<string, unknown>, touched: Record<string, unknown>, additionalValue?: unknown): void => {
            const errorVals = getErrors(errors, touched);
            const stringifiedErrorVals = Object.keys(errorVals)
                .map(k => {
                    return `${k}: ${errorVals[k]}`;
                })
                .join('|')
                .toString();
            if (trackAction && trackingIds?.formValidationError && stringifiedErrorVals) {
                errorValues.current = stringifiedErrorVals;
                trackAction(
                    trackingIds?.formValidationError,
                    {
                        currentLanguage: language,
                        privacyStatement,
                        userGroup,
                        loginStatus: isAuthenticated,
                        currentBrand: brand,
                        productId: productId,
                    },
                    stringifiedErrorVals,
                    additionalValue,
                );
            }
        },
        [language, privacyStatement, userGroup, isAuthenticated, brand],
    );

    const onSubmit = useMemo(
        () => (additionalValue?: unknown): void => {
            if (trackAction && trackingIds?.confirm) {
                trackAction(
                    trackingIds.confirm,
                    {
                        currentLanguage: language,
                        privacyStatement,
                        userGroup,
                        loginStatus: isAuthenticated,
                        currentBrand: brand,
                        productId: productId,
                    },
                    additionalValue,
                );
            }
        },
        [language, privacyStatement, userGroup, isAuthenticated, brand],
    );
    const onError = useMemo(
        () => (additionalValue?: unknown): void => {
            if (trackAction && trackingIds?.confirmError) {
                trackAction(
                    trackingIds.confirmError,
                    {
                        currentLanguage: language,
                        privacyStatement,
                        userGroup,
                        loginStatus: isAuthenticated,
                        currentBrand: brand,
                        productId: productId,
                    },
                    additionalValue,
                );
            }
        },
        [language, privacyStatement, userGroup, isAuthenticated, brand],
    );
    const onSuccess = useMemo(
        () => (additionalValue?: unknown, secondAdditionalValue?: unknown): void => {
            if (trackAction && trackingIds?.confirmSuccess) {
                trackAction(
                    trackingIds.confirmSuccess,
                    {
                        currentLanguage: language,
                        privacyStatement,
                        userGroup,
                        loginStatus: isAuthenticated,
                        currentBrand: brand,
                        productId: productId,
                    },
                    additionalValue,
                    '',
                    secondAdditionalValue,
                );
            }
        },
        [language, privacyStatement, userGroup, isAuthenticated, brand],
    );
    return { onTyping, onSubmit, onError, onSuccess, onConfirmError };
};

// Tracks dashboard page views depending on previous location and availability of unpaid details
export const useAnalyticsDashboardPageTracker = (
    dataLoaded = true,
    unpaid: boolean,
    lastPathName?: string,
    isDigitalRenewal?: boolean,
): void => {
    const viewTracked = useRef(false);
    const lastLocation = useLastLocation();
    let defaultLastPathName = lastLocation?.pathname;
    const {
        trackPageView,
        userGroup,
        privacyStatement,
        isAuthenticated,
        brand,
        language,
        registrationPath,
        loginPath,
        productId,
    } = useContext(AnalyticsContext);
    useEffect(() => {
        if (dataLoaded && !viewTracked.current) {
            if (lastPathName) {
                defaultLastPathName = lastPathName;
            }
            const login = loginPath && defaultLastPathName === loginPath;
            const register = registrationPath && defaultLastPathName === registrationPath;
            const trackingId = login ? 'dashboardAfterLogin' : register ? 'dashboardAfterRegistration' : 'dashboard';
            if (trackPageView) {
                trackPageView(
                    trackingId,
                    {
                        currentLanguage: language,
                        privacyStatement,
                        loginStatus: isAuthenticated,
                        userGroup,
                        currentBrand: brand,
                        productId: productId,
                    },
                    unpaid,
                    isDigitalRenewal,
                );
            }
            viewTracked.current = true;
        }
    }, [
        dataLoaded,
        unpaid,
        language,
        privacyStatement,
        userGroup,
        defaultLastPathName,
        isAuthenticated,
        brand,
        isDigitalRenewal,
    ]);
};

// Tracks dashboard page views depending on previous location without availability of unpaid details
export const useAnalyticsDashboardPageTrackerWithoutUnpaid = (
    dataLoaded = true,
    lastPathName: string,
    isDigitalRenewal?: boolean,
    bankAccountUpdate?: boolean,
): void => {
    const viewTracked = useRef(false);
    const {
        trackPageView,
        userGroup,
        privacyStatement,
        isAuthenticated,
        brand,
        language,
        registrationPath,
        loginPath,
        productId,
    } = useContext(AnalyticsContext);
    useEffect(() => {
        if (dataLoaded && !viewTracked.current) {
            const login = loginPath && lastPathName === loginPath;
            const register = registrationPath && lastPathName === registrationPath;
            const fromPath = login ? 'login' : register ? 'registration' : '';
            if (trackPageView) {
                trackPageView(
                    'dashboardWithoutUnpaid',
                    {
                        currentLanguage: language,
                        privacyStatement,
                        loginStatus: isAuthenticated,
                        userGroup,
                        currentBrand: brand,
                        productId: productId,
                    },
                    fromPath,
                    isDigitalRenewal,
                    bankAccountUpdate,
                );
            }
            viewTracked.current = true;
        }
    }, [
        dataLoaded,
        language,
        privacyStatement,
        userGroup,
        lastPathName,
        isAuthenticated,
        brand,
        isDigitalRenewal,
        bankAccountUpdate,
    ]);
};

export const useAnalyticsHookPageViewTracker = (
    trackingId: TrackingId,
    dataLoaded = true,
    lastPathName: string,
    marketingPreferences?: MarketingPreferences,
): void => {
    const {
        trackPageView,
        userGroup,
        privacyStatement,
        isAuthenticated,
        brand,
        language,
        registrationPath,
        loginPath,
        productId,
    } = useContext(AnalyticsContext);

    const viewTracked = useRef(false);

    useEffect(() => {
        // Wait until data are loaded to avoid multiple trackings due to several component mounts/unmounts
        if (trackPageView && !viewTracked.current && dataLoaded) {
            const login = loginPath && lastPathName === loginPath;
            const register = registrationPath && lastPathName === registrationPath;
            const fromPath = login ? 'login' : register ? 'registration' : '';

            trackPageView(
                trackingId,
                {
                    currentLanguage: language,
                    privacyStatement,
                    userGroup,
                    loginStatus: isAuthenticated,
                    currentBrand: brand,
                    productId: productId,
                },
                fromPath,
                marketingPreferences?.vwfs,
            );
        }
    }, [dataLoaded, language, privacyStatement, userGroup, isAuthenticated, trackingId, lastPathName, brand]);
};

export const useAnalyticsProfilePageViewTracker = (
    trackingId: TrackingId,
    dataLoaded = true,
    marketingPreferences?: MarketingPreferences,
): void => {
    const { trackPageView, userGroup, privacyStatement, isAuthenticated, brand, language, productId } = useContext(
        AnalyticsContext,
    );

    const viewTracked = useRef(false);

    useEffect(() => {
        // Wait until data are loaded to avoid multiple trackings due to several component mounts/unmounts
        if (trackPageView && !viewTracked.current && dataLoaded) {
            trackPageView(
                trackingId,
                {
                    currentLanguage: language,
                    privacyStatement,
                    userGroup,
                    loginStatus: isAuthenticated,
                    currentBrand: brand,
                    productId: productId,
                },
                '',
                marketingPreferences,
            );
        }
    }, [dataLoaded, language, privacyStatement, userGroup, isAuthenticated, trackingId, brand]);
};
