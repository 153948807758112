import React, { useEffect, useState } from 'react';
import { PostboxFilterProps, PostboxFilters } from './filters';
import { PostboxDocument, PostboxTable, PostboxTableProps } from './table';
import { Button, Layout } from '@vwfs-bronson/bronson-react';
import { testIds } from './testIds';
import { Paging } from '../paging/Paging';

export type PostboxProps = {
    filters: PostboxFilterProps;
    table: PostboxTableProps;
    resetButtonText: string;
    withPaging?: boolean;
    itemsPerPage?: number;
    defaultDocumentType?: string;
    defaultContractIdentifier?: string;
    defaultTimeFrameFilterKey?: string;
};

export const Postbox: React.FC<PostboxProps> = ({
    filters,
    table,
    resetButtonText,
    withPaging,
    itemsPerPage,
    defaultDocumentType,
    defaultContractIdentifier,
    defaultTimeFrameFilterKey,
}) => {
    const allOptionKey = 'ALL';
    const generalOptionKey = 'GENERAL';

    const [resetKey, setResetKey] = useState<number>(0);

    const [currentDocumentTypeFilter, setCurrentDocumentTypeFilter] = useState<string | number>(
        defaultDocumentType ? defaultDocumentType : allOptionKey,
    );
    const [resetDocumentTypeFilter, setResetDocumentTypeFilter] = useState<string | number>(
        defaultDocumentType ? defaultDocumentType : allOptionKey,
    );
    const [currentContractIdentifier, setContractIdentifier] = useState<string | number>(
        defaultContractIdentifier ? defaultContractIdentifier : allOptionKey,
    );
    const [resetContractIdentifier, setResetContractIdentifier] = useState<string | number>(
        defaultContractIdentifier ? defaultContractIdentifier : allOptionKey,
    );

    const [currentTimeFrameFilterKey, setTimeFrameFilterKey] = useState<string>(
        defaultTimeFrameFilterKey ? defaultTimeFrameFilterKey : allOptionKey,
    );
    const [resetTimeFrameFilterKey, setResetTimeFrameFilterKey] = useState<string>(
        defaultTimeFrameFilterKey ? defaultTimeFrameFilterKey : allOptionKey,
    );

    const [selectedDocuments, setSelectedDocuments] = useState<typeof table.documents>(table.documents);

    const [page, setPage] = useState<number>(1);

    const getDocumentsForPage = (documents: PostboxDocument[]): PostboxDocument[] => {
        if (!itemsPerPage || !withPaging) return documents;
        const from = (page - 1) * itemsPerPage;
        return documents.slice(from, from + itemsPerPage);
    };

    useEffect(() => {
        setSelectedDocuments(
            table.documents
                .filter(document => {
                    if (currentDocumentTypeFilter === allOptionKey) {
                        return true;
                    }
                    return document.documentType.toString() === currentDocumentTypeFilter.toString();
                })
                .filter(document => {
                    if (currentContractIdentifier === allOptionKey) {
                        return true;
                    }
                    if (currentContractIdentifier === generalOptionKey) {
                        return !document.contractIdentifier;
                    }

                    return document.contractIdentifier?.toString() === currentContractIdentifier.toString();
                })
                .filter(document => {
                    if (currentTimeFrameFilterKey === allOptionKey || !filters.timeFrameFilter) {
                        return true;
                    }

                    const currentTimeFrameFilterItems = filters.timeFrameFilter.filterItems.filter(
                        filterItem => filterItem.key.toString() === currentTimeFrameFilterKey.toString(),
                    )[0];

                    const currentSelectedTimeFrom = currentTimeFrameFilterItems.from;
                    const currentSelectedTimeTo = currentTimeFrameFilterItems.to;

                    if (currentSelectedTimeFrom <= document.date && currentSelectedTimeTo >= document.date) {
                        return true;
                    }

                    return false;
                }),
        );
    }, [currentDocumentTypeFilter, currentContractIdentifier, currentTimeFrameFilterKey, filters, table]);

    function handleResetKey(value: string | number, resetKey?: boolean): void {
        if (value === allOptionKey && resetKey) {
            setResetKey(0);
        } else if (value === allOptionKey) {
            setResetKey(prevState => prevState + 1);
        }
        if (getDocumentsForPage(selectedDocuments).length === 0 && withPaging) {
            setPage(1);
        }
    }

    const onContractIdentifierChange = (value: string | number): void => {
        setContractIdentifier(value);
        handleResetKey(value, true);
    };

    const onDocumentTypeChange = (value: string | number): void => {
        setCurrentDocumentTypeFilter(value);
        handleResetKey(value, true);
    };

    const onTimeFrameChange = (value: string): void => {
        setTimeFrameFilterKey(value);
        handleResetKey(value, true);
    };

    const resetAllFilters = (): void => {
        setResetDocumentTypeFilter(allOptionKey);
        setResetContractIdentifier(allOptionKey);
        setResetTimeFrameFilterKey(allOptionKey);
        handleResetKey(allOptionKey);

        setCurrentDocumentTypeFilter(allOptionKey);
        setContractIdentifier(allOptionKey);
        setTimeFrameFilterKey(allOptionKey);
    };

    const hideResetButton =
        currentContractIdentifier === allOptionKey &&
        currentDocumentTypeFilter === allOptionKey &&
        currentTimeFrameFilterKey === allOptionKey;

    return (
        <div key={resetKey}>
            <PostboxFilters
                documentTypeFilter={filters.documentTypeFilter}
                contractIdentifierFilter={filters.contractIdentifierFilter}
                timeFrameFilter={filters.timeFrameFilter}
                onContractIdentifierChange={onContractIdentifierChange}
                onDocumentTypeChange={onDocumentTypeChange}
                onTimeFrameChange={onTimeFrameChange}
                defaultDocumentTypeKey={resetDocumentTypeFilter}
                defaultContractIdentifierKey={resetContractIdentifier}
                defaultTimeFrameFilterKey={resetTimeFrameFilterKey}
            />
            <Layout className={'u-text-right u-mt-small'}>
                <Layout.Item className={'u-mt-none'}>
                    <Button
                        onClick={resetAllFilters}
                        testId={testIds.resetButton}
                        icon={'semantic-reload'}
                        iconReversed
                        style={hideResetButton ? { visibility: 'hidden' } : undefined}
                    >
                        {resetButtonText}
                    </Button>
                </Layout.Item>
            </Layout>
            <PostboxTable
                documents={getDocumentsForPage(selectedDocuments)}
                tableHeaderColumnLabels={table.tableHeaderColumnLabels}
                locale={table.locale}
                tableHeaderClassNames={table.tableHeaderClassNames}
                tableColumnClassNames={table.tableColumnClassNames}
                localization={table.localization}
                noDocumentsSelectedErrorText={table.noDocumentsSelectedErrorText}
                dateFormatter={table.dateFormatter}
                overridedColumns={table.overridedColumns}
                overridedHeaders={table.overridedHeaders}
                sortingOrder={table.sortingOrder}
            />
            {withPaging && itemsPerPage && (
                <Paging
                    items={selectedDocuments.length}
                    itemsPerPage={itemsPerPage}
                    activePage={page}
                    onPageClick={setPage}
                />
            )}
        </div>
    );
};
