import React from 'react';
import { Layout, Card } from '@vwfs-bronson/bronson-react';
import { ContractHeaderLoadingPlaceholder } from '../contract-header-loading-placeholder';
import { Skeleton } from '../Skeleton';
import { allowedFractions } from 'components/contracts';

export type AmortizationTableLoadingPlaceholderProps = { tileElements?: number };

export const AmortizationTableLoadingPlaceholder: React.FC<AmortizationTableLoadingPlaceholderProps> = ({
    tileElements = 3,
}) => (
    <>
        <Card className="u-mb" element="article">
            <ContractHeaderLoadingPlaceholder summaryElements={0} />
        </Card>
        <Layout className="u-mb-small">
            {Array.from(Array(tileElements).keys()).map(key => (
                <Layout.Item key={key} default={`1/${tileElements}` as allowedFractions} s={'1/1'}>
                    <Card element="article">
                        <Skeleton height={50} />
                        <Skeleton height={35} count={2} />
                    </Card>
                </Layout.Item>
            ))}
        </Layout>
        <Skeleton height={50} />
        <Skeleton count={4} height={40} />
    </>
);
