import { Layout } from '@vwfs-bronson/bronson-react';
import * as React from 'react';

export type allowedFractions =
    | '1/1'
    | '1/3'
    | '2/3'
    | '3/3'
    | '1/2'
    | '2/2'
    | '1/4'
    | '2/4'
    | '3/4'
    | '4/4'
    | '1/5'
    | '2/5'
    | '3/5'
    | '4/5'
    | '5/5'
    | '1/6'
    | '2/6'
    | '3/6'
    | '4/6'
    | '5/6'
    | '6/6'
    | '1/12'
    | '2/12'
    | '3/12'
    | '4/12'
    | '5/12'
    | '6/12'
    | '7/12'
    | '8/12'
    | '9/12'
    | '10/12'
    | '11/12'
    | '12/12';

export type ContractHeaderProps = {
    /**
     * The complete car information (e.g. "VW CALIFORNIA 2.0BITDI SE 180 4DR 4MTN DSG")
     */
    carInformation: string;
    /**
     * The contract category to be shown to the customer (e.g. "Operating Lease")
     */
    contractCategory: string;
    /**
     * The contract number
     */
    contractNumber: string;
    /**
     * Additional information displayed under contractCategory/contractNumber
     */
    thirdLineData?: string | React.ReactNode;
    /**
     * If the contract was terminated by VWFS, add here a label (e.g. "Terminated")
     */
    terminationRemark?: string;
    /**
     * A license plate or any comparable contract identifier (@see LicensePlate)
     */
    contractIdentifier?: React.ReactNode;
    /**
     * The summary of the contract (@see ContractSummary)
     */
    contractSummary?: React.ReactNode;
    /**
     * Props to overwrite properties default in Layout
     */
    displayFractionTitle?: allowedFractions;
    displayFractionLicensePlate?: allowedFractions;
};

/**
 * A contract header applicable in the most markets
 */
export const ContractHeader: React.FC<ContractHeaderProps> = ({
    carInformation,
    contractCategory,
    contractNumber,
    thirdLineData,
    terminationRemark,
    contractIdentifier,
    contractSummary,
    displayFractionLicensePlate,
    displayFractionTitle,
}) => {
    const terminationComponent = terminationRemark ? <span className="u-text-alert"> {terminationRemark}</span> : '';

    return (
        <>
            <Layout>
                <Layout.Item default={displayFractionTitle || '8/12'} m="1/1">
                    <span className="c-form-section__title">
                        <span className="c-form-section__title-text">{carInformation}</span>
                    </span>

                    <p className="c-form-section__subtitle cp-summary-contract-information">
                        <span className="cp-contract-category">{contractCategory}</span>
                        <span className="cp-contract-id"> {contractNumber}</span>
                        {thirdLineData && (
                            <>
                                <br />
                                <span className="cp-contract-id">{thirdLineData}</span>
                            </>
                        )}
                        {terminationComponent}
                    </p>
                </Layout.Item>
                <Layout.Item default={displayFractionLicensePlate || '4/12'} m="1/1" className="u-mt-none@m">
                    <div className="u-text-right@m-up  u-mr-none cp-contract-identifier-slot">{contractIdentifier}</div>
                </Layout.Item>
            </Layout>
            <div className="cp-contract-summary-slot">{contractSummary}</div>
        </>
    );
};
