import { Table } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { CookieDescriptionCMS } from '@cp-shared-8/apis';

export type CookieTableProps = {
    /**
     * The titles of the columns corresponding to the cookie description fields
     */
    columnTitles: CookieDescriptionCMS;
    cookies: Array<CookieDescriptionCMS>;
};

export const CookieTable: React.FC<CookieTableProps> = ({ columnTitles, cookies, ...props }) => {
    const {
        type: typeTitle,
        name: nameTitle,
        description: descriptionTitle,
        expirationDuration: expirationDurationTitle,
        service,
        creationTime,
        source,
    } = columnTitles;

    return (
        <Table bordered={true} colored={true} wide={true} {...props}>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>{typeTitle}</Table.Th>
                    <Table.Th>{nameTitle}</Table.Th>
                    <Table.Th>{descriptionTitle}</Table.Th>
                    <Table.Th>{expirationDurationTitle}</Table.Th>
                    {service && <Table.Th>{service}</Table.Th>}
                    {creationTime && <Table.Th>{creationTime}</Table.Th>}
                    {source && <Table.Th>{source}</Table.Th>}
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {cookies.map(({ type, name, description, expirationDuration, service, creationTime, source }) => (
                    <Table.Tr key={name}>
                        <Table.Td>{type}</Table.Td>
                        <Table.Td>{name}</Table.Td>
                        <Table.Td>
                            <span dangerouslySetInnerHTML={{ __html: description }} />
                        </Table.Td>
                        <Table.Td>{expirationDuration}</Table.Td>
                        {service && <Table.Td>{service}</Table.Td>}
                        {creationTime && <Table.Td>{creationTime}</Table.Td>}
                        {source && <Table.Td>{source}</Table.Td>}
                    </Table.Tr>
                ))}
            </Table.Tbody>
        </Table>
    );
};

CookieTable.displayName = 'CookieTable';
