import React from 'react';
import { Moment } from 'moment-timezone';
import './PostboxFilters.css';
import { Combobox, Layout } from '@vwfs-bronson/bronson-react';
import { allowedFractions } from 'components/contracts';
import { ComboboxOption } from 'components/validation-inputs/validated-combobox';

export interface ComboboxDefaultState {
    value: ComboboxOption[];
    isMulti?: boolean;
    isOpen?: boolean;
    currentIndex?: number | null;
    focusedOption?: ComboboxOption;
    filterQuery?: string;
    hasOptions?: boolean;
}

export type DocumentTypeFilterItem = {
    documentType: string | number;
    /**
     * Value which should be displayed for selection
     */
    value: string;
    /**
     * Optional ReactNode which can be displayed on the filter item.
     * When not specified, value will be used
     */
    reactNode?: React.ReactNode;
};

export type ContractIdentifierFilterItem = {
    contractIdentifier: string | number;
    /**
     * Value which should be displayed for selection
     */
    value: string;
    /**
     * Optional ReactNode which can be displayed on the filter item.
     * When not specified, value will be used
     */
    reactNode?: React.ReactNode;
};

export type TimeFrameFilterItem = {
    /**
     * From date including itself
     */
    from: Moment;
    /**
     * To date including itself
     */
    to: Moment;
    /**
     * Optional ReactNode which can be displayed on the filter item.
     * When not specified, value will be used
     */
    reactNode?: React.ReactNode;
    /**
     * Value which should be displayed for selection
     */
    value: string;
    /**
     * Unique key for that filter
     */
    key: string;
};

export type PostboxFilterProps = {
    documentTypeFilter?: {
        label: string;
        filterItems: DocumentTypeFilterItem[];
        allLabel: string;
    };
    defaultDocumentTypeKey?: string | number;
    contractIdentifierFilter?: {
        label: string;
        filterItems: ContractIdentifierFilterItem[];
        allLabel: string;
        withGeneralOption?: boolean;
        generalLabel?: string;
    };
    defaultContractIdentifierKey?: string | number;
    timeFrameFilter?: {
        label: string;
        filterItems: TimeFrameFilterItem[];
        allLabel: string;
    };
    defaultTimeFrameFilterKey?: string;
    onDocumentTypeChange?: (selectedDocumentType: string | number) => void;
    onContractIdentifierChange?: (selectedContractIdentifier: string | number) => void;
    onTimeFrameChange?: (selectedTimeFrameIdentifier: string | number) => void;
};

const defaultOptionKey = 'ALL';
const defaultOptionValue = 'All';
const generalOptionKey = 'GENERAL';

export const PostboxFilters: React.FC<PostboxFilterProps> = ({
    documentTypeFilter,
    contractIdentifierFilter,
    timeFrameFilter,
    onDocumentTypeChange,
    onContractIdentifierChange,
    onTimeFrameChange,
    defaultContractIdentifierKey,
    defaultDocumentTypeKey,
    defaultTimeFrameFilterKey,
}) => {
    const filtersLength = [documentTypeFilter, contractIdentifierFilter, timeFrameFilter].filter(f => f).length;
    const documentTypeFilterItemsWithAll = documentTypeFilter && [
        {
            documentType: defaultOptionKey,
            value: documentTypeFilter.allLabel,
        },
        ...documentTypeFilter.filterItems,
    ];

    const contractIdentifierFilterItemsWithAll = contractIdentifierFilter && [
        {
            contractIdentifier: defaultOptionKey,
            value: contractIdentifierFilter.allLabel,
        },
        ...(contractIdentifierFilter.withGeneralOption
            ? [
                  {
                      contractIdentifier: generalOptionKey,
                      value: contractIdentifierFilter.generalLabel || '',
                  },
              ]
            : []),
        ...contractIdentifierFilter.filterItems,
    ];

    const timeFrameItemsWithAll = timeFrameFilter && [
        {
            value: timeFrameFilter.allLabel,
            key: defaultOptionKey,
            reactNode: undefined,
        },
        ...timeFrameFilter.filterItems,
    ];
    const getDefaultDocumentTypeItems = (documentTypeFilterItems: DocumentTypeFilterItem[]) => {
        return {
            value: [
                defaultDocumentTypeKey
                    ? {
                          key: defaultDocumentTypeKey,
                          value: documentTypeFilterItems.find(
                              el => el.documentType.toString() === defaultDocumentTypeKey,
                          )?.value,
                      }
                    : {
                          key: defaultOptionKey,
                          value: defaultOptionValue,
                      },
            ],
        };
    };
    const getDefaultContractIdentifierFilterItems = (contractIdentifierItems: ContractIdentifierFilterItem[]) => {
        return {
            value: [
                defaultContractIdentifierKey
                    ? {
                          key: defaultContractIdentifierKey,
                          value: contractIdentifierItems.find(
                              el => el.contractIdentifier.toString() === defaultContractIdentifierKey.toString(),
                          )?.value,
                      }
                    : {
                          key: defaultOptionKey,
                          value: defaultOptionValue,
                      },
            ],
        };
    };

    const getDefaultTimeFrameItems = (
        timeFrameItems: (
            | TimeFrameFilterItem
            | {
                  value: string;
                  key: string;
                  reactNode: undefined;
              }
        )[],
    ) => {
        return {
            value: [
                defaultTimeFrameFilterKey
                    ? {
                          key: defaultTimeFrameFilterKey,
                          value: timeFrameItems.find(el => el.key === defaultTimeFrameFilterKey)?.value,
                      }
                    : {
                          key: defaultOptionKey,
                          value: defaultOptionValue,
                      },
            ],
        };
    };

    const defaultFraktion: allowedFractions = `1/${filtersLength}` as allowedFractions;

    return (
        <>
            <Layout>
                {!!documentTypeFilter && !!documentTypeFilterItemsWithAll && (
                    <Layout.Item default={defaultFraktion} s={'1/1'}>
                        {documentTypeFilter.label && (
                            <>
                                <label>{documentTypeFilter.label}</label>
                            </>
                        )}
                        <Combobox
                            testId={'DocumentTypeFilter'}
                            ariaLabelledBy={documentTypeFilter.label}
                            onChange={(value: { key: string; value: string }[]) => {
                                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                onDocumentTypeChange && onDocumentTypeChange(value[0].key);
                            }}
                            defaultState={
                                getDefaultDocumentTypeItems(documentTypeFilterItemsWithAll) as ComboboxDefaultState
                            }
                        >
                            {documentTypeFilterItemsWithAll.map(filterItem => (
                                <Combobox.Item
                                    key={filterItem.documentType.toString()}
                                    testId={`DocumentTypeFilterItem-${filterItem.documentType}`}
                                    optionValue={filterItem.value}
                                    optionKey={filterItem.documentType.toString()}
                                >
                                    {filterItem.reactNode || <span>{filterItem.value}</span>}
                                </Combobox.Item>
                            ))}
                        </Combobox>
                    </Layout.Item>
                )}
                {!!contractIdentifierFilter && !!contractIdentifierFilterItemsWithAll && (
                    <Layout.Item default={defaultFraktion} s={'1/1'}>
                        {contractIdentifierFilter.label && (
                            <>
                                <label>{contractIdentifierFilter.label}</label>
                            </>
                        )}
                        <Combobox
                            testId={'ContractIdentifierFilter'}
                            onChange={(value: { key: string; value: string }[]) => {
                                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                onContractIdentifierChange && onContractIdentifierChange(value[0].key);
                            }}
                            defaultState={
                                getDefaultContractIdentifierFilterItems(
                                    contractIdentifierFilterItemsWithAll,
                                ) as ComboboxDefaultState
                            }
                        >
                            {contractIdentifierFilterItemsWithAll.map(filterItem => (
                                <Combobox.Item
                                    key={filterItem.contractIdentifier.toString()}
                                    testId={`ContractIdentifierFilter-${filterItem.contractIdentifier}`}
                                    optionValue={filterItem.value}
                                    optionKey={filterItem.contractIdentifier.toString()}
                                >
                                    {filterItem.reactNode || <span>{filterItem.value}</span>}
                                </Combobox.Item>
                            ))}
                        </Combobox>
                    </Layout.Item>
                )}
                {!!timeFrameFilter && !!timeFrameItemsWithAll && (
                    <Layout.Item default={defaultFraktion} s={'1/1'}>
                        {timeFrameFilter.label && (
                            <>
                                <label>{timeFrameFilter.label}</label>
                            </>
                        )}
                        <Combobox
                            testId={'TimeFrameFilter'}
                            onChange={(value: { key: string; value: string }[]) => {
                                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                onTimeFrameChange && onTimeFrameChange(value[0].key);
                            }}
                            defaultState={getDefaultTimeFrameItems(timeFrameItemsWithAll) as ComboboxDefaultState}
                        >
                            {timeFrameItemsWithAll.map(filterItem => (
                                <Combobox.Item
                                    key={filterItem.key.toString()}
                                    testId={`TimeFrameFilter-${filterItem.key}`}
                                    optionValue={filterItem.value}
                                    optionKey={filterItem.key.toString()}
                                >
                                    {filterItem.reactNode || <span>{filterItem.value}</span>}
                                </Combobox.Item>
                            ))}
                        </Combobox>
                    </Layout.Item>
                )}
            </Layout>
        </>
    );
};
