import { Table } from '@vwfs-bronson/bronson-react';
import React, { useState } from 'react';
import { TableHeaderCell, TableHeaderEntry } from './table-header-entry/TableHeaderEntry';
import {
    Cell,
    DynamicTableFormatOptions,
    DynamicTableGroupByOptions,
    DynamicTableSortOptions,
} from './DynamicTableOptions';
import { createGroupedTableRows } from './grouped-table-rows/GroupedTableRows';
import { createOrderedTableRows } from './ordered-table-rows/OrderedTableRows';
import { TableSummary } from './table-summary/TableSummary';

export type DynamicTableProps<TDataRow> = {
    rows: TDataRow[];
    columnHeadings: TableHeaderEntry[];
    /**
     * Maps each data entry to an array of values to render
     */
    toColumnValues: (row: TDataRow) => Cell[];
    groupByOptions?: DynamicTableGroupByOptions<TDataRow>;
    /**
     * Initially rows are ordered using sorter defined be this property
     */
    sortOptions?: DynamicTableSortOptions<TDataRow>;
    /**
     * Rows can be dynamically sorted by selected column using a sorter defined for each column.
     * Providing columnSortOptions overrides sortOptions property.
     */
    columnSortOptions?: DynamicTableSortOptions<TDataRow>[];
    formatOptions?: DynamicTableFormatOptions;
    hasSummary?: boolean;
    summaryRow?: Cell[];
};

interface SelectedSorting<TDataRow> {
    columnIndex: number;
    options?: DynamicTableSortOptions<TDataRow>;
    alternativeOrder: boolean;
}

export function createDynamicTable<TDataRow extends object>(): React.FC<DynamicTableProps<TDataRow>> {
    const DynamicTable: React.FC<DynamicTableProps<TDataRow>> = ({
        rows,
        columnHeadings,
        groupByOptions,
        sortOptions: defaultSortOptions,
        columnSortOptions,
        toColumnValues,
        formatOptions,
        hasSummary = false,
        summaryRow = [],
    }) => {
        const [selectedSortOptions, setSelectedSortOptions] = useState<SelectedSorting<TDataRow>>(() => ({
            columnIndex: 0,
            options: columnSortOptions && columnSortOptions.length > 0 ? columnSortOptions[0] : defaultSortOptions,
            alternativeOrder: false,
        }));

        if (!columnHeadings) {
            return null;
        }

        const sortCallback = (columnIndex: number) => () => {
            if (columnSortOptions && columnSortOptions.length > columnIndex) {
                let newValue: SelectedSorting<TDataRow> | undefined;
                if (selectedSortOptions && selectedSortOptions.columnIndex === columnIndex) {
                    const currentOrder = selectedSortOptions.alternativeOrder;
                    newValue = { ...selectedSortOptions, alternativeOrder: !currentOrder };
                } else {
                    newValue = {
                        columnIndex,
                        options: columnSortOptions[columnIndex],
                        alternativeOrder: false,
                    };
                }

                setSelectedSortOptions(newValue);
            }
        };

        const GroupedTableRows = createGroupedTableRows<TDataRow>();
        const OrderedTableRows = createOrderedTableRows<TDataRow>();
        const initialSortOptions = selectedSortOptions.options;
        let modifiedSortOptions: DynamicTableSortOptions<TDataRow> | undefined = undefined;
        if (initialSortOptions) {
            modifiedSortOptions = {
                ...initialSortOptions,
                reversed: !!initialSortOptions?.reversed != selectedSortOptions?.alternativeOrder,
            };
        }

        return (
            <Table {...formatOptions}>
                <Table.Thead>
                    <Table.Tr>
                        {columnHeadings.map((heading, index) => (
                            <TableHeaderCell
                                key={typeof heading === 'string' ? heading : heading.heading}
                                heading={heading}
                                sortable={columnSortOptions && columnSortOptions.length > index}
                                sortSelected={selectedSortOptions?.columnIndex === index}
                                sortOrder={modifiedSortOptions?.reversed}
                                sortCallback={sortCallback(index)}
                            />
                        ))}
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {groupByOptions ? (
                        <GroupedTableRows
                            rows={rows}
                            toColumnValues={toColumnValues}
                            sortOptions={modifiedSortOptions}
                            groupByOptions={groupByOptions}
                        />
                    ) : (
                        <OrderedTableRows
                            rows={rows}
                            toColumnValues={toColumnValues}
                            sortOptions={modifiedSortOptions}
                        />
                    )}
                </Table.Tbody>
                {hasSummary && <TableSummary summaryRow={summaryRow} />}
            </Table>
        );
    };
    DynamicTable.displayName = 'DynamicTable';
    return DynamicTable;
}
