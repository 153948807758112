import React from 'react';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { HeroTeaserLoadingPlaceholder, HeroTeaserLoadingPlaceholderProps } from '../hero-teaser-loading-placeholder';
import { Skeleton } from '../Skeleton';

export type StaticPageLoadingPlaceholderProps = HeroTeaserLoadingPlaceholderProps & {
    withHeroTeaser?: boolean;
};

export const DefaultStaticPageContentLoadingPlaceholder: React.FC = () => {
    return (
        <>
            <Skeleton height={60} />
            <Skeleton height={30} count={4} />
            <Skeleton height={60} className="u-mt" />
            <Skeleton height={30} count={3} />
        </>
    );
};

export const StaticPageLoadingPlaceholder: React.FC<StaticPageLoadingPlaceholderProps> = ({
    withHeroTeaser = true,
    children,
    ...rest
}) => {
    return (
        <ContentSection pageWrapSize="medium">
            {withHeroTeaser && <HeroTeaserLoadingPlaceholder {...rest} />}
            {children || <DefaultStaticPageContentLoadingPlaceholder />}
        </ContentSection>
    );
};
