import React from 'react';
import classNames from 'classnames';
import { Icon } from '../Icon/Icon';
/**
 * Bronson TileSelect component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-tile-select.html
 * @constructor
 */
export function TileSelect({ checkboxGroupName, checked, children, defaultChecked, disabled, error, footer, icon, id, inputType = 'checkbox', onChange, onClick, radioGroupName, title, value, testId, ...otherProps }) {
    const classNameList = classNames('c-tile-select', {
        'is-error': error,
    }).trim();
    const iconClassNameList = classNames('c-tile-select__icon');
    /**
     * Set the `[name]` value depending on the {@link inputType}.
     * If the type is `radio`, then use `radioGroupName` to ensure that all
     * input elements have the same value to be included in the radio group.
     * In the case of `checkbox`, use the {@link checkboxGroupName} if set to
     * group the TileSelects, otherwise the {@link id} is used.
     * @type {string}
     */
    const inputName = inputType === 'radio' ? radioGroupName : checkboxGroupName ?? id;
    /**
     * Callback when user clicks on the {@link TileSelect} element.
     * @type {function}
     */
    const handleClick = (e) => {
        if (onClick) {
            onClick(e);
        }
    };
    /**
     * Callback when state of the {@link TileSelect} element has changed.
     * @type {function}
     */
    const handleChange = (e) => {
        if (onChange) {
            /**
             * Prevents React from resetting its properties.
             * Deprecated: Only relevant for React 16 and earlier. As of React 17,
             * `e.persist()` doesn’t do anything because the `SyntheticEvent` is no longer pooled.
             * @see https://reactjs.org/docs/events.html
             */
            e.persist();
            onChange(e);
        }
    };
    function renderIfFooter() {
        if (footer) {
            return React.createElement("span", { className: "c-tile-select__body-bottom" }, footer);
        }
        return null;
    }
    return (
    /**
     * Need to pass `[inert=""]` as React currently does not support HTMLElement.inert mapping.
     * @see https://github.com/facebook/react/pull/24730
     * @TODO: Remove once React supports direct `[inert]` usage.
     * @see BRON-11871
     */
    // @ts-ignore @TODO: Remove once React supports inerts, @see BRON-11871.
    React.createElement("div", { ...otherProps, "data-testid": testId, className: classNameList, inert: disabled ? '' : null },
        React.createElement("label", { className: "c-tile-select__label", htmlFor: id },
            React.createElement("input", { className: "c-tile-select__input", type: inputType, id: id, value: value, name: inputName, checked: checked, defaultChecked: defaultChecked, disabled: disabled, "aria-disabled": disabled, onClick: handleClick, onChange: handleChange }),
            React.createElement("span", { className: "c-tile-select__body" },
                React.createElement("span", { className: "c-tile-select__body-top" },
                    icon && React.createElement(Icon, { className: iconClassNameList, name: icon }),
                    title && React.createElement("span", { className: "c-tile-select__title" }, title),
                    children && React.createElement("span", { className: "c-tile-select__text" }, children)),
                renderIfFooter()))));
}
