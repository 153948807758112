import React from 'react';
import { Pagination } from '@vwfs-bronson/bronson-react';

type PagingProps = {
    ariaLabel?: string;
    items: number;
    itemsPerPage: number;
    activePage: number;
    onPageClick: (page: number) => void;
    prevText?: string;
    nextText?: string;
    testId?: string;
};

export const Paging: React.FC<PagingProps> = ({
    ariaLabel,
    items,
    itemsPerPage,
    activePage,
    onPageClick,
    prevText,
    nextText,
    testId,
}) => {
    if (items <= itemsPerPage) return null;
    const pages = Math.ceil(items / itemsPerPage);
    const onPrevClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
        onPageClick(activePage - 1);
        e.preventDefault();
    };
    const onNextClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
        onPageClick(activePage + 1);
        e.preventDefault();
    };
    const paginationItems = new Array(pages).fill(null).map((_, index) => {
        const pageNumber = ++index;
        return (
            <Pagination.Item
                key={pageNumber}
                active={pageNumber === activePage}
                onClick={(e: React.MouseEvent<HTMLAnchorElement>): void => {
                    onPageClick(pageNumber);
                    e.preventDefault();
                }}
            >
                {pageNumber}
            </Pagination.Item>
        );
    });

    return (
        <div className={'u-mt-small'}>
            <Pagination
                title={ariaLabel}
                prevDisabled={activePage === 1}
                nextDisabled={activePage === pages}
                onPrevClick={onPrevClick}
                onNextClick={onNextClick}
                prevText={prevText}
                nextText={nextText}
                testId={testId}
            >
                {paginationItems}
            </Pagination>
        </div>
    );
};
