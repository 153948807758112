import React from 'react';
import { useHistory } from 'react-router-dom';
import { Header, Logo } from '@vwfs-bronson/bronson-react';
import { SiteNav, SiteNavProps } from './SiteNav';
import './NavigationBar.css';

export type NavigationBarProps = {
    /**
     * Alternative text for the logo (e.g. Home or Dashboard)
     */
    logoAltText: string;
    /**
     * Enables onClick for logo
     */
    onLogoClick?: () => void;
    /**
     * Path to page for on logo click (e.g. Dashboard)
     */
    onLogoClickPath?: string | undefined;
    /**
     * The navigation description
     */
    navigation: SiteNavProps;
    /**
     * The language switcher definition (@see LanguageSwitcher)
     */
    languageSwitcher?: React.ReactNode;
    /**
     * Enables the static header on the navbar
     */
    withStaticHeader?: boolean;

    /**
     * Hides Navbar completely
     */
    hidden?: boolean;
    /**
     * Custom logo source
     */
    logoSource?: string;
    logoClassName?: string;

    /**
     * Notifications Center component
     */
    notificationsCenter?: React.ReactNode;

    /**
     * Optional close menu text for VWFS brand ("Close Menu" when omitted)
     */
    closeMenuText?: string;
    /**
     * Optional custom title for open button ("Show navigation" when omitted)
     */
    openButtonTitle?: string;

    /**
     * Optional open menu text for VW6 brand ("Menu" when omitted)
     */
    openMenuText?: string;
};

/**
 * A simple navigation bar including logo definition and an optional language switcher
 */
export const NavigationBar: React.FC<NavigationBarProps> = ({
    logoAltText,
    navigation,
    onLogoClickPath,
    languageSwitcher,
    withStaticHeader,
    hidden,
    logoSource,
    logoClassName,
    notificationsCenter,
    closeMenuText,
    openButtonTitle,
    openMenuText,
}) => {
    if (hidden) {
        return null;
    }
    const history = useHistory();
    const onLogoClick = (): void => {
        if (onLogoClickPath) {
            history.push(onLogoClickPath);
        }
    };
    const logo = logoSource ? (
        <Logo onClick={onLogoClick} title={logoAltText} src={logoSource} className={logoClassName} />
    ) : (
        <Logo onClick={onLogoClick} title={logoAltText} />
    );

    return (
        <Header logo={logo} staticHeader={withStaticHeader} notificationCenter={notificationsCenter}>
            <SiteNav
                navigationItems={navigation.navigationItems}
                languageSwitcher={languageSwitcher}
                closeMenuText={closeMenuText}
                openButtonTitle={openButtonTitle}
                openMenuText={openMenuText}
            />
        </Header>
    );
};
