import React from 'react';
import { Layout } from '@vwfs-bronson/bronson-react';
import { Skeleton } from '../Skeleton';
import { allowedFractions } from 'components/contracts';

export type HeroTeaserLoadingPlaceholderProps = {
    withPretitle?: boolean;
    withTitle?: boolean;
    withSubTitle?: boolean;
    withButton?: boolean;
};

export const HeroTeaserLoadingPlaceholder: React.FC<HeroTeaserLoadingPlaceholderProps> = ({
    withPretitle = false,
    withTitle = true,
    withSubTitle = false,
    withButton = false,
}) => {
    const getTeaserItem = (layoutItemWidth: string) => (
        <Layout>
            <Layout.Item default={layoutItemWidth as allowedFractions} m="1/1">
                <Skeleton height={60} />
            </Layout.Item>
        </Layout>
    );

    return (
        <div className="c-hero-teaser c-hero-teaser--shallow">
            <div className="c-hero-teaser__image" />
            <div className="c-hero-teaser__content">
                <div className="c-hero-teaser__content-inner">
                    {withPretitle && getTeaserItem('1/5')}
                    {withTitle && getTeaserItem('1/3')}
                    {withSubTitle && getTeaserItem('1/2')}
                    {withButton && getTeaserItem('1/4')}
                </div>
            </div>
        </div>
    );
};
