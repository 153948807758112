import React from 'react';
import { Heading } from '@vwfs-bronson/bronson-react';

export type PageHeaderProps = {
    title: string;
    testId?: string;
    /**
     * <h1> to <h6>
     */
    level?: 1 | 2 | 3 | 4 | 5 | 6 | string;
};

export const PageHeader: React.FC<PageHeaderProps> = ({ title, testId, level = 1 }) => (
    <Heading level={level} testId={testId}>
        {title}
    </Heading>
);
