import { BackToTop as BackToTopBronson } from '@vwfs-bronson/bronson-react';
import React from 'react';

export type BackToTopProps = {
    /**
     * The label that is shown in the most brands besides the scroll icon/button (e.g. "Back to top")
     */
    label: string;
};

/**
 * The pre-defined "back to top" button
 */
export const BackToTop: React.FC<BackToTopProps> = ({ label, ...props }) => (
    <BackToTopBronson {...props}>{label}</BackToTopBronson>
);
